import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import {Row, Col,
  Card, CardBody,
  CardTitle,Button,Form,Label,Input } from 'reactstrap';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';

//import html2pdf from 'html2pdf.js';//per estrazione pdf

import Parametri from '../../parametri';
import FormattaImportoVideo from '../FormattaImportoVideo.js';


import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';

import Modale from '../Modale.js';
let larghColonnaBig =4;
let larghColonna = 2;
let larghColonnaStretta = 1;

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_RICERCA_DATI_DETTAGLIO= 5;
//const STATO_VAI_IN_INSERISCI= 6;
//const STATO_OPERAZIONE_CORRETTA2= 7;

const STATO_SCARICA_PDF = 9;

function TabellaReportDistribuzioneRisposteMezzi(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
   
    const [risultatoDistribuzione,setRisultatoDistribuzione]=useState('');
    const [totaliDistribuzione,setTotaliDistribuzione]=useState('');
  
    const [error,setError]=useState('');

    const  dataPartenza = useRef();
    const  dataArrivo = useRef();
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState('');
    const [dataArrivoDefault,setDataArrivoDefault]=useState('');

    const [loading,setLoading]= useState(false);

    const [calcola,setCalcola]=useState(false);
    const [visualizza,setVisualizza]=useState(false);
    const [visualizzaInPdf,setVisualizzaInPdf]=useState(false);

    //per il logo:
    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    //var MyDocument = '';
    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      scaricaLogo();
    },[]);
 
    useEffect(() => {
      setVisualizza(false);
      setCalcola(false);
    },[dataPartenzaDefault,dataArrivoDefault]);

    useEffect(() => {
      if(calcola === true){
        ricercaDatiCRM();
      }
    },[calcola]);

    useEffect(() => {
      if(visualizzaInPdf === true) {
        //handleEsportaPDF();
        setStatoPagina(STATO_SCARICA_PDF);
      }
    },[visualizzaInPdf]);

    useEffect(() => {
      if(ricercaLogo === 1){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      }
    },[ricercaLogo]);

    //funzioni:
    function ricercaDatiCRM(){
      //ricerco la distribuzione sul periodo in esame:
      let controlli = 0;
      let dataP = '';
      let dataA = '';
      if(dataPartenza.current) dataP = dataPartenza.current.value;
      if(dataArrivo.current) dataA = dataArrivo.current.value;

      if(controlli===0){ 
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"reportDistribuzioneRisposteMezziCrm",
            dataInizio:dataP,
            dataFine:dataA,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                      setRisultatoDistribuzione(valoreDiRitorno.risultatoRitorno);
                      setTotaliDistribuzione(valoreDiRitorno.totali);
                      setVisualizza(true);
                  }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
      }
  }
  function scaricaLogo() {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {     
        setLoading(false);     
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }
  //fine funzioni
  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}><Clessidra loading={loading}/>
     
  {statoPagina === STATO_SCARICA_PDF ? 
      <div>
      {/*<PDFViewer style={styles.viewer}>*/}
      <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
      <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
        <Document>
          {/*<Page orientation="landscape" size="A4" style={styles.page}>*/}
          <Page size="A4" style={styles.page}>
            <View style={{width:"100%"}} >
              <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                          </View> 
                        </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                            capSocieta !== "" ?
                              " "+capSocieta+" "+cittaSocieta
                            :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                                
                          <Text style={styles.titolo}>Report distribuzione risposte per mezzo di comunicazione</Text>
                         
                      </View> 
                    </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>Report del <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano>
                    
                    </Text>
                  </View>
                  {dataPartenza.current && dataPartenza.current.value!==''?
                        <View>
                          <Text style={styles.titoloPiccolo}>Inizio Periodo di Analisi: <TrasformaDateAUmano data={dataPartenza.current.value}></TrasformaDateAUmano></Text>
                        </View>
                      :""}
                      {dataArrivo.current && dataArrivo.current.value!=='' ?
                        <View>
                          <Text style={styles.titoloPiccolo}>Fine Periodo di Analisi: <TrasformaDateAUmano data={dataArrivo.current.value}></TrasformaDateAUmano></Text>
                        </View>
                      :""} 

                <View style={styles.table}>
                  <View wrap={false} style={styles.tableRowBlu}>
                          <View style={styles.tableCol5Di100}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text> 
                          </View>  
                          <View style={styles.tableCol25Di100}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Mezzo di Comunicazione</Text> 
                          </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Perc. risposte Positive (%)</Text> 
                            </View>
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Perc. risposte Neutre (%)</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Perc. risposte Negative (%)</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero risposte Positive</Text> 
                            </View>
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero risposte Neutre</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero risposte Negative</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero totale risposte</Text> 
                            </View> 

                            <Col md={larghColonna} className="titoloTabellaReport"></Col>
                      </View>

                  {risultatoDistribuzione.map((elemento,index) => <View style={styles.tableRow} wrap={false} key={index}>

                    <View style={styles.tableCol5Di100}>
                    <Text style={styles.tableCell}> {index+1}</Text>
                  </View>                 
                  <View style={styles.tableCol25Di100}>
                    <Text style={styles.tableCellAllineatoSinistra}> {elemento.MEZZO}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(elemento.PERC_POS)}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(elemento.PERC_NEU)}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(elemento.PERC_NEG)}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {elemento.NUM_POS}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {elemento.NUM_NEU}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {elemento.NUM_NEG}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {elemento.TOTALI_RISP}</Text>
                  </View>
                  </View>)}

                  {/*riga dei totali*/}
                  <View style={styles.tableRow} wrap={false}>

                  <View style={styles.tableCol60Di100}>
                  <Text style={styles.tableCell}>Totali</Text>
                  </View>                 
                  {/*<View style={styles.tableCol55Di100}>
                    <Text style={styles.tableCellAllineatoSinistra}></Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}></Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}></Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}></Text>
                  </View>*/}
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {totaliDistribuzione[0].TOTALI_POS}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {totaliDistribuzione[0].TOTALI_POS}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {totaliDistribuzione[0].TOTALI_POS}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {totaliDistribuzione[0].TOTALI}</Text>
                  </View>
                  </View>

                  </View>
              </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </Page>
          </Document>
      </PDFViewer>
    </div>

    :""}


      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_R_CRM_dis</div> :""}  
      {statoPagina === STATO_RICERCA_DATI || statoPagina === STATO_RICERCA_DATI_DETTAGLIO ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?

        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

            <Row>
              <Col lg="12">
                  <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                      {visualizzaInPdf === true ?
                        <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>
                      :""}

                            <CardTitle id="titolo">Report distribuzione risposte per mezzo di comunicazione</CardTitle>
                           
                                <Row form>  
                                <Col md={5}>
                                <Label>Inizio Periodo di Analisi</Label>
                                <Input type="date" name="dataPartenza" id="dataPartenza"  defaultValue={dataPartenzaDefault} innerRef={dataPartenza}
                                    placeholder="" onInput={(e)=>{setDataPartenzaDefault(dataPartenza.current.value)}}/> 
                                </Col>
                                <Col md={5}>
                                <Label>Fine Periodo di Analisi</Label>
                                <Input type="date" name="dataArrivo" id="dataArrivo"  defaultValue={dataArrivoDefault} innerRef={dataArrivo}
                                placeholder="" onInput={(e)=>{setDataArrivoDefault(dataArrivo.current.value)}}/> 
                                </Col>
                                {visualizza !== true ?
                                  <Col md={2}>
                                    <div style={{paddingTop:24}}> 
                                      <Button color="success" className="mt-2" onClick={() => {setCalcola(true);setVisualizzaInPdf(false)}}>Calcola</Button>
                                    </div> 
                                  </Col>
                                : 
                                <Col md={2}>
                                    <div style={{paddingTop:24}}> 
                                      <Button color="success" className="mt-2" onClick={(e)=>{setVisualizzaInPdf(true)}}>Esporta il PDF</Button>
                                    </div> 
                                </Col>
                                }
                                </Row>
                              

                            <div id="dettaglioReport">
                            {visualizza === true ?
                              risultatoDistribuzione.length === 0 ?
                                <Fragment >
                                  <Row form className="border">
                                  <Col md='12'><b>Non risultano record che soddisfino le condizioni impostate</b></Col>
                                  </Row>
                                </Fragment>
                              :
                              
                              <Fragment>
                              
                              <Row form style={{marginTop:10, marginBottom:10}}> 
                                <Col>  
                                  <b>Report del <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano>
                                  
                                  </b>
                                </Col> 
                              </Row>  
                              
                              <Row form className="rigaTitoloTabellaReport">
                                <Col md={larghColonnaStretta} className="titoloTabellaReport"><b></b></Col>
                                <Col md={larghColonnaBig} className="titoloTabellaReport">Mezzo di Comunicazione</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">Perc. risposte Positive (%)</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">Perc. risposte Neutre (%)</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">Perc.risposte Negative (%)</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">N. risposte Positive</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">N. risposte Neutre</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">N. risposte Negative</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">N. totale risposte</Col>
                              </Row>

                              {risultatoDistribuzione.map((elemento,index) => <Row className="rigaTabellaReport" key={index}>
                                  <Col md={larghColonnaStretta}><center>{index+1}</center></Col>
                                  <Col md={larghColonnaBig}>{elemento.MEZZO}</Col>
                                  <Col md={larghColonnaStretta}>{FormattaImportoVideo(elemento.PERC_POS)}</Col>
                                  <Col md={larghColonnaStretta}>{FormattaImportoVideo(elemento.PERC_NEU)}</Col>
                                  <Col md={larghColonnaStretta}>{FormattaImportoVideo(elemento.PERC_NEG)}</Col>
                                  <Col md={larghColonnaStretta}>{elemento.NUM_POS}</Col>
                                  <Col md={larghColonnaStretta}>{elemento.NUM_NEU}</Col>
                                  <Col md={larghColonnaStretta}>{elemento.NUM_NEG}</Col>
                                  <Col md={larghColonnaStretta}>{elemento.TOTALI_RISP}</Col>
                              </Row>)}
                              
                              <Row className="rigaTabellaReport">
                              <Col md={larghColonnaStretta}><center><b>Totali</b></center></Col>
                              <Col md={larghColonnaBig}></Col>
                              <Col md={larghColonnaStretta}></Col>
                              <Col md={larghColonnaStretta}></Col>
                              <Col md={larghColonnaStretta}></Col>
                              <Col md={larghColonnaStretta}><b>{totaliDistribuzione[0].TOTALI_POS}</b></Col>
                              <Col md={larghColonnaStretta}><b>{totaliDistribuzione[0].TOTALI_NEU}</b></Col>
                              <Col md={larghColonnaStretta}><b>{totaliDistribuzione[0].TOTALI_NEG}</b></Col>
                              <Col md={larghColonnaStretta}><b>{totaliDistribuzione[0].TOTALI}</b></Col>
                              </Row>

                              </Fragment>
                            :""}
                        </div>                            
                      
                    </div>
                </Col>
            </Row>
        </div>
      :""}
    </div>
   );
}
export default TabellaReportDistribuzioneRisposteMezzi;