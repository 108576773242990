import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleVoceNonCancellabile, setFlagAttivaModaleVoceNonCancellabile] = useState(0);
    const [flagAttivaModaleVoceNonModificabile, setFlagAttivaModaleVoceNonModificabile] = useState(0);
    const [flagAttivaModaleVoceNonInseribile, setFlagAttivaModaleVoceNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleVoceAlternativaErrata, setFlagAttivaModaleVoceAlternativaErrata] = useState(0);
    const [flagAttivaModaleSpiegaAttivita, setFlagAttivaModaleSpiegaAttivita] = useState(0);

    const descrizione   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    //let descrizioneDefault = "";
    const [descrizioneDefault,setDescrizioneDefault] = useState('');

    const [risultatoServerDatiVocidiBilancio,setRisultatoServerDatiVocidiBilancio]=useState('');
    const [statoTendinaVocidiBilancio,setStatoTendinaVocidiBilancio]=useState(false);
    const [insVociDiBilancioEdi,setInsVociDiBilancioEdi]=useState('');
    //const [defaultVoceDiBilancio,setDefaultVoceDiBilancio]=useState('');
    const [variazioneCampiTendinaVoci,setVariazioneCampiTendinaVoci]=useState(0);

    const [flagAttivitaDefault,setFlagAttivitaDefault]=useState('1');
    const flagAttivita  = useRef();

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaVocidiBilancio(false);
    },[]);

    useEffect(() => {
        if(statoTendinaVocidiBilancio === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaVocidiBilancio]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("vociDiBilancioIns");
        if(props.ope==='modifica') setOperazione("vociDiBilancioMod");
        if(props.ope==='cancella') setOperazione("vociDiBilancioCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"vociDiBilancioRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    console.log("ecco"+valoreDiRitorno.risultatoRitorno[0].TIPO_ATTIVITA)
                    setFlagAttivitaDefault(valoreDiRitorno.risultatoRitorno[0].TIPO_ATTIVITA);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda(valore) {

       //setFlagAttivaModaleVoceNonCancellabile(0);
        setFlagAttivaModaleVoceNonModificabile(0);
        setFlagAttivaModaleVoceNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleVoceAlternativaErrata(0);
        setFlagAttivaModaleSpiegaAttivita(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?
                    JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        tipoAttivita:flagAttivita.current.checked,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        voceDiBilancio:insVociDiBilancioEdi,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Voce non cancellabile: presente in prima nota") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setRisultatoServerDatiVocidiBilancio(valoreDiRitorno.vociDiBilancio);
                                    setFlagAttivaModaleVoceNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Voce non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleVoceNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Voce non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleVoceNonInseribile(1);
                                }
                                
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }

                                if(valoreDiRitorno.mess.indexOf("Voce alternativa non presente nel db") !== -1){
                                    setFlagAttivaModaleVoceAlternativaErrata(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
           
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1VocBil {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                        
                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleVoceNonCancellabile=== 1 ?
                        <Modale 
                        titolo="Informativa voce non cancellabile"
                        flagErrore={true}
                        contenuto={<div><Row><Col>
                            <center>La voce di bilancio risulta associata ad uno o più movimenti di prima nota. 
                                Per eliminarla è necessario selezionare una delle altre voci attualmente esistenti alla quale associare i movimenti di prima nota attualmente associati alla voce che si intende eliminare</center>
                            </Col></Row>
                            <Row><Col><center><TendinaEditabile
                                    //titolo={"Voce di Bilancio"}
                                    nomeCampo="DESCRIZIONE"
                                    valoreDefault={insVociDiBilancioEdi}
                                    bloccoInserimento="1"
                                    arrayValori={risultatoServerDatiVocidiBilancio}
                                    callbackSetVariazioneCampi={setVariazioneCampiTendinaVoci}
                                    callbackSetValoreTendEditabile={setInsVociDiBilancioEdi}
                                ></TendinaEditabile></center>
                            </Col></Row>
                            </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer()}
                            }, 
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaElenco()}
                            }   
                        ]}
                    />
                    :""}

                    {flagAttivaModaleVoceAlternativaErrata=== 1 ?
                        <Modale 
                        titolo="Informativa voce alternativa errata"
                        flagErrore={true}
                        contenuto={<div>La voce alternativa fornita non risulta presente nel database</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleSpiegaAttivita=== 1 ?
                        <Modale 
                        titolo="Informativa Attività Principali / Secondarie"
                        flagErrore={false}
                        larghezza="lg"
                        contenuto={<div><p>La classificazione di una voce di bilancio come attività principale dipende dalla sua natura.</p> 
                            <p>Per attività principale si intende tutto ciò che è connesso direttamente alle attività sportive ed alle responsabilità istituzionali.
                            L'attivita principale coinvolge i ruoli fondamentali per la pratica sportiva stessa, come istruttori, allenatori, arbitri, tecnici e atleti.
                            Inoltre riguarda tutto ciò che è indispensabile allo svolgimento delle attività sportive: attrezzature, materiali sportivi e strutture adeguate ecc..</p>
                            <p>Sono invece da includere nelle attività secondarie tutto ciò che non è da considerarsi indispensabile per la pratica sportiva diretta.
                            Si tratta spesso di attività legate all’organizzazione e al funzionamento delle strutture sportive.
                            Ad esempio: addetti alla manutenzione degli impianti, addetti alla reception, gestione dell'area ristoro o del merchandising sportivo ecc.. </p>
                            <p>La distinzione tra le attività è utile per adempiere alle norme relative alla Riforma dello Sport.</p>
                            Il <b>report "Attività Principali / Secondarie"</b>, disponibile nella sezione Report, può essere un utile strumento per confrontare il volume di attività 
                            principali e secondarie. 
                            <p>Fornisce inoltre un'analisi dettagliata del rapporto tra attività secondarie e ricavi totali, nonché tra attività secondarie e costi totali.</p>
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}



                    {flagAttivaModaleVoceNonModificabile=== 1 ?
                        <Modale 
                        titolo="Informativa voce non modificabile"
                        flagErrore={true}
                        contenuto={<div>La descrizione della voce di bilancio non è modificabile in {descrizione.current.value} poichè nel database è già presente una voce di bilancio con la stessa descrizione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleVoceNonInseribile=== 1 ?
                        <Modale 
                        titolo="Informativa voce non inseribile"
                        flagErrore={true}
                        contenuto={<div>La descrizione della voce di bilancio non è inseribile in {descrizione.current.value} poichè nel database è già presente una voce di bilancio con la stessa descrizione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Voce di Bilancio</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Voce di Bilancio</CardTitle> :""}
                        
                            
                            <Clessidra loading={loading}/>


                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della voce di bilancio <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                            :""}
                                            {props.ope === "cancella" ? <Label>: {descrizioneDefault}</Label> : ""}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Input className="ml-2 mt-2" type="checkbox" name="flagAttivita" id="flagAttivita"
                                                    defaultChecked = {flagAttivitaDefault === "1" ?  'checked' :""}
                                                    innerRef={flagAttivita}                                 
                                                    placeholder=""
                                                    onInput={(e)=>{handleCambiamentoMod(e);setFlagAttivitaDefault(e.target.value);}}/>  
                                                    <Label className="ml-4 mt-2" for="flagattivita">Attività principale</Label>
                                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                </>
                            }
                                
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                        
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
