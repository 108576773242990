import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';

import Parametri from '../../parametri';

import TendinaEditabile from '../TendinaEditabile.js';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [statoTendinaPosizioni,setStatoTendinaPosizioni]=useState(false);

    const [flagAttivaModaleInserisciPosizione, setFlagAttivaModaleInserisciPosizione]  = useState(0);
    const [flagAttivaModaleRichiediPermessoInserirePosizione, setFlagAttivaModaleRichiediPermessoInserirePosizione] = useState(0);
    const [flagAttivaModaleImponibileErrato, setFlagAttivaModaleImponibileErrato] = useState(0);
    const [flagAttivaModaleIvaErrata, setFlagAttivaModaleIvaErrata] = useState(0);
    const [flagAttivaModaleDataErrata, setFlagAttivaModaleDataErrata] = useState(0);
    const [flagAttivaModaleDoppione, setFlagAttivaModaleDoppione] = useState(0);

    const prezzo   = useRef();
    //const iva   = useRef();
    const note   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');
    const [risultatoServerDatiPosizioni,setRisultatoServerDatiPosizioni]=useState('');

    //const [defaultPosizione,setDefaultPosizione]=useState('');
    const [prezzoDefault,setPrezzoDefault]=useState('');
    //const [ivaDefault,setIvaDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');

    const [insPosizioneEdi,setInsPosizioneEdi]=useState('');
    const [permessoInserirePosizione,setPermessoInserirePosizione]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');
    //const override = {position: "absolute",left: "50%",top: "50%",zIndex: 1000};

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaPosizioni(false);
        setPermessoInserirePosizione(0);
    },[]);

    useEffect(() => {
        if(statoTendinaPosizioni === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaPosizioni]);

    useEffect(() => {
        if(permessoInserirePosizione===1){
            fetchOperazioneServer(1);
        } 
    },[permessoInserirePosizione]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("quoteAssociativeIns");
        if(props.ope==='modifica') setOperazione("quoteAssociativeMod");
        if(props.ope==='cancella') setOperazione("quoteAssociativeCanc");

        //if(props.ope==="modifica" || props.ope==="cancella" || props.ope==="inserisci"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"quoteAssociativeRecuperaDati",
                    id:props.id,
                    dataPartenza:props.dataPartenza,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        if(valoreDiRitorno.risultatoRitorno[0]){
                            //entro qui solo se il backend mi ha restituito qualcosa nel risultatoRitorno ovvero se sono in modifica o cancellazione
                            //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                            setInsPosizioneEdi(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_POSIZIONE);
                            setPrezzoDefault(valoreDiRitorno.risultatoRitorno[0].PREZZO);
                            //setIvaDefault(valoreDiRitorno.risultatoRitorno[0].IVA);
                            setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                        }
                    setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);
                    setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);
                    ricercaDatiPosizioni();
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        //}else{
        //    setStatoPagina(STATO_RICERCA_DATI);
        //    ricercaDatiPosizioni();
        //}
    },[props.ope]);

    function ricercaDatiPosizioni() {
        //ricerco tutte le posizioni in società disponibili che mi servono per popolare le tendine:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPosizioniSocieta",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPosizioni(valoreDiRitorno.risultatoRitorno);        
                setStatoTendinaPosizioni(true);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function annullaInserimentoDatiNuovi(){
        setFlagAttivaModaleInserisciPosizione(0);
        setFlagAttivaModaleRichiediPermessoInserirePosizione(0);
        setFlagAttivaModaleImponibileErrato(0);
        //setFlagAttivaModaleIvaErrata(0);
        setFlagAttivaModaleDataErrata(0);
        setFlagAttivaModaleDoppione(0);
        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoDatiNuovi(){
        setPermessoInserirePosizione(1);
        //setStatoPagina(STATO_INSERISCI);
    }

    function fetchOperazioneServer(permessoIns=0,bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            if(insPosizioneEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciPosizione(1);
            }     
            if(isNaN(FormattaImportoDb(prezzo.current.value))){
                controlli = 1;
                setFlagAttivaModaleImponibileErrato(1);
            }
            /*if(isNaN(FormattaImportoDb(iva.current.value))){
                controlli = 1;
                setFlagAttivaModaleIvaErrata(1);
            }  */
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);
                
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  
                        JSON.stringify({op:operazione,
                        posizione:insPosizioneEdi,
                        permessoInserirePosizione:permessoIns,
                        prezzo:FormattaImportoDb(prezzo.current.value),
                        note:note.current.value,
                        data:inizioPeriodo,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                    JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                //if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire iscritto") !== -1) setStatoPagina(STATO_RICHIEDI_PERMESSO_INSERIRE);
                                if(valoreDiRitorno.mess==="Richiedi permesso per inserire posizione"){
                                    setFlagAttivaModaleRichiediPermessoInserirePosizione(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indicare la posizione da tendina") !== -1){
                                    setFlagAttivaModaleInserisciPosizione(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                    setFlagAttivaModaleImponibileErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data errata") !== -1){
                                    setFlagAttivaModaleDataErrata(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("doppione") !== -1){
                                    setFlagAttivaModaleDoppione(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
               
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1QAss {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciPosizione===1 ?
                        <Modale 
                            titolo="Richiesta posizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la posizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleRichiediPermessoInserirePosizione===1 ?
                        <Modale 
                            titolo="Richiesta permesso inserimento posizione"
                            flagErrore={true}
                            contenuto={<div>
                                Vuoi davvero inserire {insPosizioneEdi} nell'elenco delle posizioni in società?<br/> 
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                },  
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                },   
                            ]}
                        />
                    :""}

                    {flagAttivaModaleImponibileErrato===1 ?
                        <Modale 
                            titolo="Informativa importo errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'importo non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleDataErrata===1 ?
                        <Modale 
                            titolo="Informativa data errata"
                            flagErrore={true}
                            contenuto={<div>La data dalla quota associativa non è corretta</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleDoppione===1 ?
                        <Modale 
                            titolo="Informativa doppione"
                            flagErrore={true}
                            contenuto={<div>La quota è legata ad una posizione per la quale è già stata espressa una quota associativa</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale">
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Quota Associativa anno {props.dataPartenza} &nbsp;Dal: <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al: <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano></CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Quota Associativa anno {props.dataPartenza} &nbsp;Dal: <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al: <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano></CardTitle> :""}
                            
                           
                            <Clessidra loading={loading}/>
                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della quota associativa legata alla posizione in società <b>{insPosizioneEdi}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :


          

                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                        <Label>Posizione in Società*</Label>
                                        <TendinaEditabile
                                            //titolo={"Posizione in Società*"}
                                            nomeCampo="DESCRIZIONE"
                                            valoreDefault={insPosizioneEdi}
                                            arrayValori={risultatoServerDatiPosizioni}
                                            callbackSetVariazioneCampi={setVariazioneCampi}
                                            callbackSetValoreTendEditabile={setInsPosizioneEdi}
                                        ></TendinaEditabile>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="prezzo">Importo Annuale*</Label>
                                                <Input maxLength={12} type="text" name="prezzo" id="prezzo"
                                                innerRef={prezzo} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(prezzoDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);setPrezzoDefault(prezzo.current.value)}}/>  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note</Label>
                                                <Input type="text" name="note" id="note"
                                                innerRef={note} 
                                                placeholder=""
                                                defaultValue={noteDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                                
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                             
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
