
function Parametri(parametro, identitaSelezionata=[]) {
const versione=275

const urlbackendPrimario = 'https://ema1.gestionesportiva.it/link_back_front.php?v=275';
    const urlWebSocket = 'wss://emabackend.massbroken.it/ws';

    switch (parametro) {       
        case 'serverURL': 
            if (identitaSelezionata.urlbackend != undefined) {
                if (identitaSelezionata.urlbackend != "primario") return identitaSelezionata.urlbackend;
            }           
            return urlbackendPrimario;
        break;

        case 'serverURLPrimario': 
            return urlbackendPrimario;
        break;

        case 'urlWebSocket': 
            return urlWebSocket;
        break;

case 'release': return "P-"+versione; break;
    }
    return "";
}

export default Parametri;
