import React, {useState, useEffect, useRef, Fragment,Suspense} from 'react';

import {
    Table, Col, Row, Card, CardBody,
    CardTitle, Button, Label, Input,FormGroup
} from 'reactstrap';

import Parametri from '../parametri';
import Clessidra from './Clessidra.js';
import TrasformaDateAUmano from './TrasformaDateAUmano.js';
import LinearProgressWithLabel, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Modale from './Modale.js';
//import TabellaFaq from './TabellaFaq/Tabella';

const STATO_INDEFINITO = 0;
const STATO_MEMORIZZAZIONE = 1;
const STATO_ERRORE_CONNESSIONE = 2;
const STATO_OPERAZIONE_FALLITA = 3;
const STATO_RICERCA_DATI_CORRETTA = 4;
//const STATO_VAI_IN_FAQ = 5;

function Home(props) {

    const altezzaWidget = (props.dimensioniApp.yMain-100)/3;
    const [flagDatiDocumenti, setFlagDatiDocumenti] = useState(false);
    const [flagDatiAdempimenti, setFlagDatiAdempimenti] = useState(false);
    const [flagDatiAzioniCRM, setFlagDatiAzioniCRM] = useState(false);
    const [flagDatiPagamenti, setFlagDatiPagamenti] = useState(false);
    const [dimensioneDatabaseMB, setDimensioneDatabaseMB] = useState(0);
    const [dimensioneAllegatiMB, setDimensioneAllegatiMB] = useState(0);
    const [numeroIscritti, setNumeroIscritti] = useState(-1);
    const [documentiCertScaduti, setDocumentiCertScaduti] = useState(0);
    const [documentiCertScadenza, setDocumentiCertScadenza] = useState(0);
    const [documentiDocIdScaduti, setDocumentiDocIdScaduti] = useState(0);
    const [documentiDocIdScadenza, setDocumentiDocIdScadenza] = useState(0);
    const [documentiPrivacyScaduti, setDocumentiPrivacyScaduti] = useState(0);
    const [documentiPrivacyScadenza, setDocumentiPrivacyScadenza] = useState(0);
    const [adempimentiScaduti, setAdempimentiScaduti] = useState(0);
    const [adempimentiScadenza, setAdempimentiScadenza] = useState(0);
    const [azioniCRMScaduti, setAzioniCRMScaduti] = useState(0);
    const [azioniCRMScadenza, setAzioniCRMScadenza] = useState(0);
    const [numSpesePagare, setNumSpesePagare] = useState(0);
    const [numRimborsiPagare, setNumRimborsiPagare] = useState(0);
    const [giorniValiditaAbbonamento, setGiorniValiditaAbbonamento] = useState(0);
    const [limiteMB, setLimiteMB] = useState(0);
    const [limiteIscritti, setLimiteIscritti] = useState(0);
    const [formAiuto, setFormAiuto] = useState(false);
    const [problemaNonSpecificato, setProblemaNonSpecificato] = useState(false);
    const [richiestaAiutoFallita, setRichiestaAiutoFallita] = useState(false);
    const [richiestaAiutoInviata, setRichiestaAiutoInviata] = useState(false);
    const [mailRichiedenteAiuto,setMailRichiedenteAiuto]=useState('');
    const [suggerimenti, setSuggerimenti] = useState([]);
    const [loading,setLoading]= useState(false);
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const problema   = useRef();

    useEffect(() => {
        scaricaDati();
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[]);

    
    function Elenco(props) {
        return (
            <>
                {props.elementi.map((elemento,indice) => 
                    <div key={indice} style={{border:"0.5px solid #d0d0d0", backgroundColor:indice%2===0?"#fcfcfc":"f0f0f0"}}>{elemento}</div>
                )}
            </>
        ) 
    }

    function Benvenuto(props) {
        const normaliseIscritti = (value) => ((value - 0) * 100) / (limiteIscritti - 0);
        const normaliseSpazio = (value) => ((value - 0) * 100) / (limiteMB - 0);

        let percentualeAlertIscritti = 5;//quando manca questa percentuale la scritta si evidenzia di giallo indicando che ci si sta avvicinando al termine degli iscritti che è possibile introdurre
        let percentualeAlertSpazio = 5;//quando manca questa percentuale la scritta si evidenzia di giallo indicando che ci si sta avvicinando al termine dello spazio utilizzabile

        let percentualeIscrittiUtilizzato = numeroIscritti * 100 / limiteIscritti;
        let percentualeSpazioUtilizzato = (dimensioneDatabaseMB+dimensioneAllegatiMB) * 100 / limiteMB;

        return (
            <Card className='homeWidget' style={{height: altezzaWidget}}>
            <CardTitle>Buongiorno</CardTitle>
            <CardBody style={{overflow:'auto'}}>
                Buongiorno, {props.identitaSelezionata.nome}
                {props.identitaSelezionata.flag_staff ?
                    props.identitaSelezionata.flag_amministrazione ?
                        <span> (accesso: amministrazione e staff)</span>
                    :
                        <span> (accesso: staff)</span>
                :
                props.identitaSelezionata.flag_amministrazione ?
                    <span> (accesso: amministrazione)</span>
                :
                    <span></span>
                }
                <br/><br/>
                {numeroIscritti>0 ? 
                    numeroIscritti >= limiteIscritti ?
                        <div className="evidenziatoAllarme"><b>Numero di iscritti</b> : {numeroIscritti}. E'stato raggiunto il limite massimo consentito (limite massimo {limiteIscritti} iscritti)</div>
                    :   
                        numeroIscritti >= limiteIscritti-(limiteIscritti*percentualeAlertIscritti/100) ?
                        <div className="evidenziatoNormale"><b>Numero di iscritti</b> : {numeroIscritti}. Il limite massimo consentito stà per essere raggiunto (limite massimo {limiteIscritti} iscritti)</div>
                        :
                        <div className="evidenziatoNormale"><b>Numero di iscritti</b>: {numeroIscritti} (limite massimo {limiteIscritti} iscritti)</div>
                :""}

                {numeroIscritti>0 ? 
                    numeroIscritti < limiteIscritti ?
                    <div style={{display: 'flex', alignItems: 'center' }}>
                        <div style={{flex: 1}}>
                            {numeroIscritti >= limiteIscritti-(limiteIscritti*percentualeAlertIscritti/100) ?
                                <LinearProgressWithLabel style={{height: 14}} className="LinearProgressWithLabelGialloGrigio" variant="determinate" value={normaliseIscritti(numeroIscritti)} />
                            :
                                <LinearProgressWithLabel style={{height: 14}} className="LinearProgressWithLabelVerdeGrigio" variant="determinate" value={normaliseIscritti(numeroIscritti)} />
                            }
                        </div>
                        <div style={{paddingLeft:5, minWidth: 35 }}><b>{`${Math.round(percentualeIscrittiUtilizzato,)}%`}</b></div>
                    </div>
                    :""
                :""}

                {dimensioneDatabaseMB+dimensioneAllegatiMB>0 ? 
                    dimensioneDatabaseMB+dimensioneAllegatiMB >= limiteMB ?
                        <div className="evidenziatoAllarme" style={{marginTop:10}}><b>Spazio utilizzato</b>: {dimensioneDatabaseMB+dimensioneAllegatiMB} MB. E'stato raggiunto il limite massimo consentito (limite massimo {limiteMB} MB)</div>
                    :   
                        dimensioneDatabaseMB+dimensioneAllegatiMB >= limiteMB-(limiteMB*percentualeAlertSpazio/100) ?
                        <div className="evidenziatoNormale"><b>Spazio utilizzato</b>: {dimensioneDatabaseMB+dimensioneAllegatiMB} MB. Il limite massimo consentito stà per essere raggiunto (limite massimo {limiteMB} MB)</div>
                        :
                        <div className="evidenziatoNormale"><b>Spazio utilizzato</b>: {dimensioneDatabaseMB+dimensioneAllegatiMB} MB (limite massimo {limiteMB} MB)</div>
                :""}
                
                {dimensioneDatabaseMB+dimensioneAllegatiMB>0 ? 
                    dimensioneDatabaseMB+dimensioneAllegatiMB < limiteMB ?
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                                {dimensioneDatabaseMB+dimensioneAllegatiMB >= limiteMB-(limiteMB*percentualeAlertSpazio/100) ?
                                    <LinearProgressWithLabel style={{height: 14}} className="LinearProgressWithLabelGialloGrigio" variant="determinate" value={normaliseSpazio(dimensioneDatabaseMB+dimensioneAllegatiMB)} />
                                :
                                    <LinearProgressWithLabel style={{height: 14}} className="LinearProgressWithLabelVerdeGrigio" variant="determinate" value={normaliseSpazio(dimensioneDatabaseMB+dimensioneAllegatiMB)} />
                                }
                            </div>
                            <div style={{paddingLeft:5, minWidth: 35 }}><b>{`${Math.round(percentualeSpazioUtilizzato,)}%`}</b></div>
                        </div> 
                    :""
                :""}
         
                {giorniValiditaAbbonamento<30 ?
                    giorniValiditaAbbonamento > 0 ?
                        giorniValiditaAbbonamento == 1 ?
                            <div className="evidenziatoAvviso" style={{marginTop:10}}><b>Il tuo Gestione Sportiva scade domani: vai su Impostazioni, Mio Abbonamento per rinnovare</b></div>
                        :
                            <div className="evidenziatoAvviso" style={{marginTop:10}}><b>Il tuo Gestione Sportiva scade tra {giorniValiditaAbbonamento} giorni: vai su Impostazioni, Mio Abbonamento per rinnovare</b></div>
                    :
                        giorniValiditaAbbonamento == 0 ?
                            <div className="evidenziatoAllarme" style={{marginTop:10}}><b>Il tuo Gestione Sportiva scade oggi: vai su Impostazioni, Mio Abbonamento per rinnovare</b></div>
                        :
                            giorniValiditaAbbonamento == -1 ?
                                <div className="evidenziatoAllarme" style={{marginTop:10}}><b>Il tuo Gestione Sportiva è scaduto ieri: vai su Impostazioni, Mio Abbonamento per rinnovare</b></div>
                            :
                                <div className="evidenziatoAllarme" style={{marginTop:10}}><b>Il tuo Gestione Sportiva è scaduto da {-giorniValiditaAbbonamento} giorni: vai su Impostazioni, Mio Abbonamento per rinnovare<br/>
                                I dati degli abbonamenti e delle prove gratuite scaduti da più di 2 settimane vengono definitivamente cancellati</b></div>
                :
                    ""}

                {props.token == "" ? 
                    <div style={{backgroundColor:"#D00000",marginTop:10,color:"#FFFFFF",paddingLeft:5}}>Notifiche Non Attive su questo browser</div>
                : 
                    <div style={{backgroundColor:"#299729",marginTop:10,color:"#FFFFFF",paddingLeft:5}}>Notifiche Attive su questo browser</div>
                }

            </CardBody>
            </Card>
        );
    }

    function Aiuto(props) {
        return (
            <Card className='homeWidget' style={{height: altezzaWidget}}>
            <CardTitle>Aiuto</CardTitle>
            <CardBody style={{textAlign:'center'}}>
                <div className='schedaSottotitolo' style={{display:"flex", flexDirection:"row", marginTop:-5}}>Assistenza</div>
                <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => props.setPagina(props.PAGINE.faq)}>FAQ</Button>
                <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setFormAiuto(true)}}>Richiesta di assistenza</Button>
                {suggerimenti.length>0 && <>
                    <div className='schedaSottotitolo' style={{display:"flex", flexDirection:"row"}}>Suggerimenti</div>
                    {suggerimenti.map((elemento,indice) => <div key={indice} style={elemento.paginaRichiamata!=""&&{cursor:'pointer'}} onClick={() => props.setPagina(elemento.paginaRichiamata)}>{elemento.descrizione}</div>)}
                </>}
            </CardBody>
            </Card>
        );
    }
    function tornaHome() {
        setFormAiuto(false);
        setProblemaNonSpecificato(false);
        setRichiestaAiutoFallita(false);
        setRichiestaAiutoInviata(false);
    }
    function fetchRichiestaAiuto() {

        let controlli=0;

       //controllo preventivamente i campi:
        if(problema.current.value===''){
            controlli = 1;
            setProblemaNonSpecificato(true);
        }

        if(controlli===0){   
            // parametri per inviare i dati al server:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: 
                JSON.stringify({op:"richiestaAiuto",
                    problema:problema.current.value,
                    societa:props.identitaSelezionata.societa,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            
            setStatoPagina(STATO_MEMORIZZAZIONE);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione===1) {
                        //messaggio che la richiesta è stata inoltrata correttamente e di attendere risposta tramite mail
                        setMailRichiedenteAiuto(valoreDiRitorno.mailRichiedente);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                        setRichiestaAiutoInviata(true);
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        if(valoreDiRitorno.mess !== undefined){
                            if(valoreDiRitorno.mess.indexOf("Richiesta di aiuto fallita") !== -1){
                                setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                setRichiestaAiutoFallita(true);
                            } 
                        }
                        //altrimenti vado in errore generico
                        setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    } 
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
        }
    }

    function DocumentiScadenze(props) {
        var elencoDoc=[];
        for (var i=1; i<5; i++) elencoDoc.push("doc scadenza "+i)
        return (
            <Card className='homeWidget' style={{height: altezzaWidget}}>
            <CardTitle>Documenti degli iscritti</CardTitle>
            <CardBody style={{overflow:'auto'}}>
                {flagDatiDocumenti ? 
                    <>
                        {documentiCertScaduti+documentiCertScadenza>0 ?
                            <>
                                {documentiCertScaduti>0 ? 
                                    <div 
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAllarme"
                                        onClick={() => props.setPagina(props.PAGINE.certScad)}
                                    >
                                        Certificati medici scaduti o mancanti: {documentiCertScaduti}
                                    </div>
                                :
                                    <div>Non ci sono certificati medici scaduti o mancanti</div>
                                }
                                {documentiCertScadenza>0 ? 
                                    <div
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAvviso"
                                        onClick={() => props.setPagina(props.PAGINE.certScad)}
                                    >
                                        Certificati medici in scadenza entro un mese: {documentiCertScadenza}
                                    </div>
                                :
                                    <div>Non ci sono certificati medici in scadenza entro un mese</div>
                                }
                            </>

                        :
                            <span>Non ci sono certificati medici mancanti, scaduti o in scadenza</span>
                        }
                        
                        <div style={{marginTop:"0.5rem"}}/>
                        
                        {documentiDocIdScaduti+documentiDocIdScadenza>0 ?
                            <>
                            {documentiDocIdScaduti>0 ? 
                                <div 
                                    style={{cursor:"pointer"}}
                                    className="evidenziatoAllarme"
                                    onClick={() => props.setPagina(props.PAGINE.docScad)}
                                >
                                    Documenti d'identità scaduti o mancanti: {documentiDocIdScaduti}
                                </div>
                            :
                                <div>Non ci sono documenti d'identità scaduti o mancanti</div>
                            }
                            {documentiDocIdScadenza>0 ? 
                                <div
                                    style={{cursor:"pointer"}} 
                                    className="evidenziatoAvviso"
                                    onClick={() => props.setPagina(props.PAGINE.docScad)}
                                >
                                    Documenti d'identità in scadenza entro un mese: {documentiDocIdScadenza}
                                </div>
                            :
                                <div>Non ci sono documenti d'identità in scadenza entro un mese</div>
                            }

                            </>

                        :
                            <span>Non ci sono documenti di identità mancanti, scaduti o in scadenza entro un mese</span>
                        }

                        <div style={{marginTop:"0.5rem"}}/>
                        {documentiPrivacyScaduti+documentiPrivacyScadenza>0 ?
                            <>
                                {documentiPrivacyScaduti>0 ? 
                                    <div 
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAllarme"
                                        onClick={() => props.setPagina(props.PAGINE.privacyScad)}
                                    >
                                        Documenti privacy scaduti o mancanti: {documentiPrivacyScaduti}
                                    </div>
                                :
                                    <div>Non ci sono documenti privacy scaduti o mancanti</div>
                                }
                                {documentiPrivacyScadenza>0 ? 
                                    <div
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAvviso"
                                        onClick={() => props.setPagina(props.PAGINE.privacyScad)}
                                    >
                                        Documenti privacy in scadenza entro un mese: {documentiPrivacyScadenza}
                                    </div>
                                :
                                    <div>Non ci sono documenti privacy in scadenza entro un mese</div>
                                }
                            </>

                        :
                            <span>Non ci sono documenti privacy mancanti, scaduti o in scadenza</span>
                        }
                    </>
                :
                    <Clessidra loading={true}/>
                }
            </CardBody>
            </Card>
        );
    }

    function AdempimentiScadenze(props) {
        return (
            <Card className='homeWidget' style={{height: altezzaWidget}}>
            <CardTitle>Adempimenti e Azioni CRM</CardTitle>
            <CardBody style={{overflow:'auto'}}>
                {flagDatiAdempimenti ? 
                    <>
                        {adempimentiScadenza+adempimentiScaduti>0 ?
                            <>
                                {adempimentiScaduti>0 ? 
                                    <div 
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAllarme"
                                        onClick={() => props.setPagina(props.PAGINE.scadenziario)}
                                    >
                                        Adempimenti scaduti: {adempimentiScaduti}
                                    </div>
                                :
                                    <div>Non ci sono adempimenti scaduti</div>
                                }
                                {adempimentiScadenza>0 ? 
                                    <div
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAvviso"
                                        onClick={() => props.setPagina(props.PAGINE.scadenziario)}
                                    >
                                        Adempimenti in scadenza entro un mese: {adempimentiScadenza}
                                    </div>
                                :
                                    <div>Non ci sono adempimenti in scadenza entro un mese</div>
                                }
                            </>

                        :
                            <span>Non ci sono adempimenti scaduti o in scadenza entro un mese</span>
                        }                        
                    </>
                :
                    <Clessidra loading={true}/>
                }

                <div style={{marginTop:"0.5rem"}}/>
                {flagDatiAzioniCRM ? 
                    <>
                        {azioniCRMScadenza+azioniCRMScaduti>0 ?
                            <>
                                {azioniCRMScaduti>0 ? 
                                    <div 
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAllarme"
                                        onClick={() => props.setPagina(props.PAGINE.reportAzioniCrm)}
                                    >
                                        Azioni CRM scadute: {azioniCRMScaduti}
                                    </div>
                                :
                                    <div>Non ci sono azioni CRM scadute</div>
                                }
                                {azioniCRMScadenza>0 ? 
                                    <div
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAvviso"
                                        onClick={() => props.setPagina(props.PAGINE.reportAzioniCrm)}
                                    >
                                        Azioni CRM in scadenza entro un mese: {azioniCRMScadenza}
                                    </div>
                                :
                                    <div>Non ci sono azioni CRM in scadenza entro un mese</div>
                                }
                            </>

                        :
                            <span>Non ci sono azioni CRM scadute o in scadenza entro un mese</span>
                        }                        
                    </>
                :
                    <Clessidra loading={true}/>
                }

            </CardBody>
            </Card>
        );
    }

    function PagamentiScadenze(props) {
        return (
            <Card className='homeWidget' style={{height: altezzaWidget}}>
            <CardTitle>Pagamenti</CardTitle>
            <CardBody style={{overflow:'auto'}}>
                {flagDatiPagamenti ? 
                    <>
                        {numSpesePagare+numRimborsiPagare>0 ?
                            <>
                                {numSpesePagare>0 ? 
                                    <div 
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAvviso"
                                        onClick={() => props.setPagina(props.PAGINE.spese)}
                                    >
                                        Spese da pagare: {numSpesePagare}
                                    </div>
                                :
                                    <div>Non ci sono spese da pagare</div>
                                }
                                {numRimborsiPagare>0 ? 
                                    <div
                                        style={{cursor:"pointer"}} 
                                        className="evidenziatoAvviso"
                                        onClick={() => props.setPagina(props.PAGINE.rimborsi)}
                                    >
                                        Rimborsi da pagare: {numRimborsiPagare}
                                    </div>
                                :
                                    <div>Non ci sono rimborsi da pagare</div>
                                }
                            </>

                        :
                            <span>Non ci sono spese o rimborsi da pagare</span>
                        }                        
                    </>
                :
                    <Clessidra loading={true}/>
                }
            </CardBody>
            </Card>
        );
    }

    function scaricaDati() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"home", emak:props.emak, sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL", props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {          
                if(valoreDiRitorno.risultatoOperazione===1){
                    if (valoreDiRitorno.numero_iscritti !== undefined) {
                        
                        setFlagDatiDocumenti(true);
                        setNumeroIscritti(valoreDiRitorno.numero_iscritti);
                        setDocumentiCertScaduti(valoreDiRitorno.num_cert_medico_scaduti);
                        setDocumentiCertScadenza(valoreDiRitorno.num_cert_medico_scadenza);
                        setDocumentiDocIdScaduti(valoreDiRitorno.num_doc_identita_scaduti);
                        setDocumentiDocIdScadenza(valoreDiRitorno.num_doc_identita_scadenza);
                        setDocumentiPrivacyScaduti(valoreDiRitorno.num_doc_privacy_scaduti);
                        setDocumentiPrivacyScadenza(valoreDiRitorno.num_doc_privacy_scadenza);
                        
                        setFlagDatiAdempimenti(true);
                        setAdempimentiScaduti(valoreDiRitorno.num_adempimenti_scaduti);
                        setAdempimentiScadenza(valoreDiRitorno.num_adempimenti_scadenza);

                        setFlagDatiAzioniCRM(true);
                        setAzioniCRMScaduti(valoreDiRitorno.num_azioniCRM_scaduti);
                        setAzioniCRMScadenza(valoreDiRitorno.num_azioniCRM_scadenza);

                        setFlagDatiPagamenti(true);
                        setNumSpesePagare(valoreDiRitorno.num_spese_pagare);
                        setNumRimborsiPagare(valoreDiRitorno.num_rimborsi_pagare);

                        setDimensioneDatabaseMB(valoreDiRitorno.dimensione_database_mb);
                        setDimensioneAllegatiMB(valoreDiRitorno.dimensione_allegati_mb);

                        setGiorniValiditaAbbonamento(valoreDiRitorno.giorni_validita_abbonamento);
                        setLimiteMB(valoreDiRitorno.limite_mb)
                        setLimiteIscritti(valoreDiRitorno.limite_iscritti)

                        setSuggerimenti(valoreDiRitorno.suggerimenti)
                    }
                }
                if(valoreDiRitorno.risultatoOperazione===2) {
                    console.log("errore scaricamento dati da " + Parametri("serverURL",props.identitaSelezionata));
                    props.setPagina(props.PAGINE.tornaLogin);
                }
          },
          (error) => {console.log("Errore fetch");}
        );        
    }

    return (
        <Fragment>
            {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1HM {error.message}</div> :""}
            {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}

            {/*{statoPagina === STATO_VAI_IN_FAQ? 
                <div>
                <Suspense fallback={
                <div className="loader-container">
                <div className="loader-container-inner"><h6 className="mt-5">Caricamento FAQ in corso...</h6></div>
                </div>
            }>

            <TabellaFaq 
                {...props} 
                callbackTermineVisualizzaFaq={callbackTermineVisualizzaFaq} 
                >
            </TabellaFaq>
            </Suspense>
            </div>
        :""}      */}
        
        {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
            <div className='schedaContenitore' style={{height:props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{display:"flex", flexDirection:"column"}}>

                    {formAiuto === true ?
                            <Modale 
                            larghezza = 'lg'
                            titolo="Richiesta di assistenza"
                            flagErrore={true}
                            contenuto={<div>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="problema">Descrivi nei dettagli il problema così potremo aiutarti</Label>
                                            <Input type="textarea" cols="100" rows="20" name="problema" id="problema" innerRef={problema} placeholder= "" defaultValue= ""/>
                                        </FormGroup>
                                    </Col>
                                </Row>  
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"Invia la richiesta",
                                    "tipo":"success",
                                    callback:() => {tornaHome();fetchRichiestaAiuto()}
                                },
                                {
                                    "etichetta":"Indietro",
                                    "tipo":"primary",
                                    callback:() => {tornaHome()}
                                },
                            ]}
                        />
                    :""}

                    {problemaNonSpecificato === true ?
                            <Modale 
                            titolo="Informativa su Richiesta di assistenza"
                            flagErrore={true}
                            contenuto={<div>Il problema non è stato specificato</div>}
                            bottoni={[
                                {
                                    "etichetta":"Ok",
                                    "tipo":"primary",
                                    callback:() => {tornaHome()},
                                }    
                            ]}
                        />
                    :""}

                    {richiestaAiutoFallita === true ?
                        <Modale 
                            titolo="Informativa su Richiesta di assistenza"
                            flagErrore={true}
                            contenuto={<div>La richiesta non è andata a buon fine</div>}
                            bottoni={[
                                {
                                    "etichetta":"Ok",
                                    "tipo":"primary",
                                    callback:() => {tornaHome(false)},
                                }    
                            ]}
                        />
                    :""}

                    {richiestaAiutoInviata=== true ?
                        <Modale 
                            titolo="Informativa su Richiesta di assistenza"
                            flagErrore={true}
                            contenuto={<div>La richiesta è stata inviata correttamente. Riceverai quanto prima una risposta all'indirizzo {mailRichiedenteAiuto}</div>}
                            bottoni={[
                                {
                                    "etichetta":"Ok",
                                    "tipo":"primary",
                                    callback:() => {tornaHome(false)},
                                }    
                            ]}
                        />
                    :""}


                    <div style={{flex:1, display:"flex", flexDirection:"row"}}>
                        <div style={{flex:1}}><Benvenuto {...props}/></div>                    
                    </div>

                    {props.identitaSelezionata.flag_amministrazione === 1 ?
                    <>
                    <div style={{flex:1, display:"flex", flexDirection:"row"}}>
                        <div style={{flex:1}}><DocumentiScadenze {...props}/></div>
                        <div style={{flex:1}}><AdempimentiScadenze {...props}/></div>
                    </div>
                    <div style={{flex:1, display:"flex", flexDirection:"row"}}>                    
                        <div style={{flex:1}}><PagamentiScadenze {...props}/></div>
                        <div style={{flex:1}}><Aiuto {...props}/></div>
                    </div>
                    </>
                    :
                        props.identitaSelezionata.flag_staff === 1 ?
                            <div style={{flex:1, display:"flex", flexDirection:"row"}}>                    
                            <div style={{flex:1}}><Aiuto {...props}/></div>
                        </div>
                        :""
                    }


                </div>
            </div>
            :""}
        </Fragment>
    );


}
export default Home;
