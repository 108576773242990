import React, {useState, useEffect, useRef} from 'react';
import { Modal } from 'reactstrap';
import SelezionaData from './SelezionaData';
import SelezionaOrario from './selezionaOrario';
import { convertiDataComputer2Umano, interoStringaCifre} from './funzioni.js'
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

const coloreTestoCliccabile = "#0e61cc";
const colorePrenotazioniQuestoCorso = "#0e61cc";
const colorePrenotazioniAltriCorsi = "#2e81cc";

const oggiYYYYMMDD = new Date().toISOString().split('T')[0];

function PrenotazioniCorsi(props) {
  const flagAccettaPrenotazioniSovrapposte = false;
  const step = 20; // di quanto si sposta a sinistra una prenotazione sovrapposta ad un'altra

  const PAGINE = {
    "caricamentoDati": 1,
    "selezionaCorso": 2,
    "giorno": 3,
    "nuovo": 4,
    "rivediPrenotazione": 5,
    "confermaCanc": 6,
    "help": 7
  };

  const [pagina, setPagina] = useState(PAGINE.selezionaCorso);
  const [corsi, setCorsi] = useState([]);
  const [elencoIDCorsi, setElencoIDCorsi] = useState([]);
  const [corsoSelezionato, setCorsoSelezionato] = useState("scegli un corso");
  const [idCorsoSelezionato, setIDCorsoSelezionato] = useState("");
  const [giornoSelezionato, setGiornoSelezionato] = useState("");
  const [indicePrenotazioneVisualizzata, setIndicePrenotazioneVisualizzata] = useState("");
  const [fineFetchYYYYMMDD, setFineFetchYYYYMMDD] = useState("");
  const [timestampInizioPrimaSettimana, setTimestampInizioPrimaSettimana] = useState(0);
  const [timestampInizioSettimana, setTimestampInizioSettimana] = useState(0);
  const [giorniSettimanaNumero, setGiorniSettimanaNumero] = useState([]);
  const [giorniSettimanaMese, setGiorniSettimanaMese] = useState([]);
  const [giorniSettimanaMeseNumerico, setGiorniSettimanaMeseNumerico] = useState([]);
  const [giorniSettimanaAnno, setGiorniSettimanaAnno] = useState([]);
  const [giorniSettimanaNome, setGiorniSettimanaNome] = useState([]);
  const [titoloNomeMese, setTitoloNomeMese] = useState("");
  const [disponibilitaInizio, setDisponibilitaInizio] = useState();
  const [disponibilitaDurata, setDisponibilitaDurata] = useState();
  const [disponibilitaPosti, setDisponibilitaPosti] = useState();
  const [prenotati, setPrenotati] = useState(); // posti prenotati dalla persona selezionata
  const [numeroPostiNelIntervallo, setNumeroPostiNelIntervallo] = useState(0);
  const [minutiMinimiPrenotazione, setMinutiMinimiPrenotazione] = useState(10);
  const [flagDisponibilita, setFlagDisponibilita] = useState(false);
  const [flagOrariCorretti, setFlagOrariCorretti] = useState(true);
  const [flagSovrapposizione, setFlagSovrapposizione] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDisp, setLoadingDisp] = useState(true);
  const [abilitaNuovoAppuntamento, setAbilitaNuovoAppuntamento] = useState(false);
  const [appuntamentoOraInizio, setAppuntamentoOraInizio] = useState(-1);
  const [appuntamentoMinInizio, setAppuntamentoMinInizio] = useState(-1);
  const [appuntamentoOraFine, setAppuntamentoOraFine] = useState(-1);
  const [appuntamentoMinFine, setAppuntamentoMinFine] = useState(-1);
  const [flagSelezionaDataAperto, setFlagSelezionaDataAperto] = useState(false);
  const [flagVisualizzaCalendario, setFlagVisualizzaCalendario] = useState(true);
  const [zoom, setZoom] = useState(30);
  const passoZoom = 2;
  const massimoZoom = 100;
  const nomiMesi = ['Gen','Feb','Mar','Apr','Mag','Giu','Lug','Ago','Set','Ott.','Nov','Dic'];
  const nomiGiorniSettimana = ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'];
  const nomiMesiCompleti = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
  const refScroll = useRef();

  useEffect (() => {
    setGiornoSelezionato(oggiYYYYMMDD);

    var parts = oggiYYYYMMDD.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0: January - 0, February - 1, etc.
    var timestampYMD = new Date(parts[0], parts[1] - 1, parts[2]); 
    var giornoSettimana = timestampYMD.getDay();
    if (giornoSettimana == 0) giornoSettimana = 7; // domenica
    var ts = timestampYMD - (giornoSettimana-1)*24*60*60*1000;
    setTimestampInizioSettimana(ts);
    setTimestampInizioPrimaSettimana(ts);

    // fine fetch = primo giorno del mese successivo a oggi (poi magari portalo a 3 mesi)
    if (parseInt(parts[1])===12) {
      setFineFetchYYYYMMDD((parseInt(parts[0])+1).toString()+"-01-01");
    } else {
      setFineFetchYYYYMMDD(parts[0]+"-"+interoStringaCifre(parseInt(parts[1])+1,2)+"-01");
    }

    ottieniElencoCorsi();
  },[])

  useEffect (() => {
    if (idCorsoSelezionato!="") ottieniElencoDisponibilitaPrenotazioni("sostituisci", oggiYYYYMMDD, fineFetchYYYYMMDD);
  },[idCorsoSelezionato])

  useEffect (() => {
    // quando cambia il primo giorno della settimana, ricalcolo i giorni che compongono la nuova settimana
    var gsnum = [];
    var gsnome = [];
    var gsmese = [];
    var gsmesenumerico = [];
    var gsanno = [];
    var meseTitolo = "";
    for (let i=0; i<7; i++) {
      var d = new Date(timestampInizioSettimana + (86400*i)*1000);
      gsnum[i] = d.getDate();
      gsnome[i] = nomiGiorniSettimana[d.getDay()];
      gsmese[i] = nomiMesi[d.getMonth()];
      gsmesenumerico[i] = d.getMonth()+1;
      gsanno[i] = d.getFullYear();
      if (meseTitolo === "") meseTitolo = nomiMesiCompleti[d.getMonth()];
      if (i>0) {
        if (gsmese[i] != gsmese[i-1]) meseTitolo += " / " + nomiMesiCompleti[d.getMonth()];
      }
    }
    meseTitolo += " " + d.getFullYear();
    setGiorniSettimanaNumero(gsnum);
    setGiorniSettimanaNome(gsnome);
    setGiorniSettimanaMese(gsmese);
    setGiorniSettimanaMeseNumerico(gsmesenumerico);
    setGiorniSettimanaAnno(gsanno);
    setTitoloNomeMese(meseTitolo);
  }, [timestampInizioSettimana])

  // calcolo del numero di posti disponibili nell'intervallo selezionato e indicazione di eventuali sovrapposizioni di prenotazioni
  useEffect(() => {
    if (appuntamentoOraInizio<0) return;
    const minutoIniziale = parseInt(appuntamentoOraInizio)*60+parseInt(appuntamentoMinInizio);
    const minutoFinale = parseInt(appuntamentoOraFine)*60+parseInt(appuntamentoMinFine);    
    //console.log("inizio calcolo numero di posti nell'intervallo " + minutoIniziale + " - " + minutoFinale);
    if (minutoFinale - minutoIniziale <= 0) {
      //console.log("orari non corretti");
      setFlagOrariCorretti(false);
      setNumeroPostiNelIntervallo(0);
      return;
    }

    setFlagOrariCorretti(true);

    let p=-1;
    let s1, s2, s3, mi, mf;
    let postiMinuti=[];
    for (let i=minutoIniziale; i<=minutoFinale; i++) postiMinuti[i]=0;
    for (let id=0; id<disponibilitaPosti[giornoSelezionato].length; id++) {
      s1 = disponibilitaPosti[giornoSelezionato][id].split("-");
      s2 = s1[0].split(":");
      s3 = s1[1].split(":");
      mi = parseInt(s2[0])*60+parseInt(s2[1]);
      mf = parseInt(s3[0])*60+parseInt(s3[1]);
      //console.log("minutoIniziale="+minutoIniziale+"minutoFinale="+minutoFinale+", s1="+s1+",s2="+s2+",s3="+s3+",mi="+mi+",mf="+mf);
      for (let i=mi; i<=mf; i++) postiMinuti[i]=parseInt(s1[2]);
    }
    for (let i=minutoIniziale; i<minutoFinale; i++) {
      if (p<0 || postiMinuti[i]<p) {
        p=postiMinuti[i];
        //console.log("minuto "+i+" posti "+p);
      }
    }
    setNumeroPostiNelIntervallo(p);

    // verifica eventuale sovrapposizione con una prenotazione già fatta
    if (!flagAccettaPrenotazioniSovrapposte) {
      let flag = false;
      if (prenotati[giornoSelezionato]!==undefined) {
        const npg = prenotati[giornoSelezionato].length;
        let s, sInizio, sFine, mInizio, mFine;
        for (let it=0; it<npg; it++) {      
          s = prenotati[giornoSelezionato][it].split("-");
          
          sInizio = s[0].split(":");
          sFine = s[1].split(":");
          mInizio = parseInt(sInizio[0])*60 + parseInt(sInizio[1]);
          mFine = parseInt(sFine[0])*60 + parseInt(sFine[1]);

          //console.log("confronto "+minutoIniziale+"-"+minutoFinale+" con "+s+" quindi con "+mInizio+"-"+mFine);

          if ((minutoIniziale<=mInizio && minutoFinale>mInizio) || (minutoIniziale>mInizio && minutoIniziale<mFine)) {
            //console.log("intervallo occupato da altra prenotazione: "+it)
            flag = true;
            break;
          }
        }
      }
      setFlagSovrapposizione(flag);
    }

  }, [appuntamentoOraInizio, appuntamentoMinInizio, appuntamentoOraFine, appuntamentoMinFine]);

  function ottieniElencoDisponibilitaPrenotazioni(modalita, inizioFetchYYYYMMDD, fineFetchYYYYMMDD) {
    if (idCorsoSelezionato !== "") {
      setFlagDisponibilita(false);
      setLoadingDisp(true);
      //console.log("fetch disponibilità per persona="+props.identitaSelezionata.idpersona+" per corso "+idCorsoSelezionato+" da "+inizioFetchYYYYMMDD+"T00:00:00"+" a "+fineFetchYYYYMMDD+"T00:00:00 modalità:"+modalita);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          op: "verificaDisponibilitaCorso", 
          idCorso: idCorsoSelezionato, 
          idPersona: props.identitaSelezionata.idpersona, 
          dataInizio: inizioFetchYYYYMMDD+"T00:00:00", 
          dataFine: fineFetchYYYYMMDD+"T00:00:00", 
          outputDisponibilita: "app", 
          emak: props.emak, 
          sessionId: props.sessionId 
        })
      };
      fetch(props.UrlBackend, requestOptions)
      .then(risposta => risposta.json())
      .then(
        (valoreRitorno) => {
          //console.log("disponibilita idCorso="+idCorsoSelezionato+" => risultatoOperazione=" + valoreRitorno.risultatoOperazione)
          if (valoreRitorno.verifvers===0) {
            // necessario aggiornare la app
            props.callbackAggiornaApp();
            return;
          }
          if (valoreRitorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
          if (valoreRitorno.risultatoOperazione !== 1) { 
            window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
            props.callback(props.PAGINECLIENTI.login);
            return; 
        }

          if (modalita==="sostituisci") {
            setDisponibilitaInizio(valoreRitorno.disponibilita_app_inizio);
            setDisponibilitaDurata(valoreRitorno.disponibilita_app_durata);
            setDisponibilitaPosti(valoreRitorno.disponibilita_posti);
            setPrenotati(valoreRitorno.prenotati);
          } else {
            // aggiungi
            setDisponibilitaInizio({...disponibilitaInizio,...valoreRitorno.disponibilita_app_inizio});
            setDisponibilitaDurata({...disponibilitaDurata,...valoreRitorno.disponibilita_app_durata});
            setDisponibilitaPosti({...disponibilitaPosti,...valoreRitorno.disponibilita_posti});
            setPrenotati({...prenotati,...valoreRitorno.prenotati});
          }
          setFlagDisponibilita(true);
          setLoadingDisp(false);
        },
        (error) => { 
          props.callback(props.PAGINECLIENTI.login);
          return;
        }
      )
      .catch(function(error) {
        props.callback(props.PAGINECLIENTI.login);
        return;
      });  
    }
  }

  // gestione del tasto BACK
  useEffect(() => {
    if (props.flagRichiestaBack) {
        props.setFlagRichiestaBack(false);
        switch (pagina) {
            case PAGINE.caricamentoDati: break;
            case PAGINE.selezionaCorso: props.callback(props.PAGINECLIENTI.home); break;
            case PAGINE.giorno: setPagina(PAGINE.selezionaCorso); break;
            case PAGINE.nuovo: setPagina(PAGINE.giorno); break;
            case PAGINE.rivediPrenotazione: setPagina(PAGINE.giorno); break;
            case PAGINE.confermaCanc: setPagina(PAGINE.giorno); break;
            case PAGINE.help: setPagina(PAGINE.giorno); break;
        }            
    } 
  }, [props.flagRichiestaBack])  

  useEffect(() => {
    //console.log("variazione disponibilitaPosti: ora vale: "+disponibilitaPosti)
  },[disponibilitaPosti])

  function ottieniElencoCorsi() {
    //console.log("ottieniElencoCorsi: inizio");
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ op:"elencoCorsi", emak: props.emak, sessionId: props.sessionId })
    };
    fetch(props.UrlBackend, requestOptions)
    .then(risposta => risposta.json())
    .then(
      (valoreRitorno) => {
        if (valoreRitorno.verifvers===0) {
          // necessario aggiornare la app
          props.callbackAggiornaApp();
          return;
        }
        if (valoreRitorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
        if (valoreRitorno.risultatoOperazione === 1) {
          setCorsi(valoreRitorno.risultatoRitorno);
          let elencoID = [];
          for (let n=0; n<valoreRitorno.risultatoRitorno.length; n++) {
            elencoID[valoreRitorno.risultatoRitorno[n].ID] = n;
          }
          setElencoIDCorsi(elencoID);
          setLoading(false);
          return;
        }              
      },
      (error) => { 
        props.callback(props.PAGINECLIENTI.login);
        return;
      }
    )
    .catch(function(error) {
      props.callback(props.PAGINECLIENTI.login);
      return;
    });
  }

  function intervalloCliccato(oraSelezionata, minSelezionato) {    
    let ms, oraSelezionataFine, minSelezionatoFine;

    // verifica se il clic è stato fatto su un intervallo prenotabile
    const nip = disponibilitaInizio[giornoSelezionato].length;
    let flag = false;
    for (let it=0; it<nip; it++) {
      ms = oraSelezionata*60+minSelezionato;
      if (ms>=disponibilitaInizio[giornoSelezionato][it] && ms<disponibilitaInizio[giornoSelezionato][it]+disponibilitaDurata[giornoSelezionato][it]) {
        // ho cliccato su un intervallo prenotabile
        //console.log("intervallo valido: "+it)
        flag = true;

        // aggiusto ora e minuto selezionati in modo che siano all'inizio di un blocco minimo prenotabile, dentro all'intervallo prenotabile selezionato
        // scegliendo l'istante corretto più vicino possibile a dove ha cliccato
        let msInferiore = disponibilitaInizio[giornoSelezionato][it];
        let msSuperiore = msInferiore + parseInt(minutiMinimiPrenotazione);
        // seconda condizione del while per essere sicuri di evitare loop infiniti in caso di ms errato per qualche motivo
        while (msSuperiore < ms && msInferiore < 24*60) {
          msInferiore += parseInt(minutiMinimiPrenotazione);
          msSuperiore += parseInt(minutiMinimiPrenotazione);
        }
        oraSelezionata = Math.floor(msInferiore / 60);
        minSelezionato = msInferiore % 60;

        // calcolo l'ora di fine di questa prenotazione
        oraSelezionataFine = Math.floor(msSuperiore / 60);
        minSelezionatoFine = msSuperiore % 60;

        break;
      }
    }
    if (!flag) return; // clic su zona bianca non prenotabile

    setAppuntamentoOraInizio(oraSelezionata);
    setAppuntamentoMinInizio(minSelezionato);
    setAppuntamentoOraFine(oraSelezionataFine);
    setAppuntamentoMinFine(minSelezionatoFine);
    setPagina(PAGINE.nuovo); 
  }
  
  function selezionaNuovoGiorno(g) {
    setGiornoSelezionato(g);
    // se il giorno selezionato va oltre il periodo di cui conosco i dati, devo fare una nuova fetch
    // e per fare questo devo cambiare FineFetchYYYYMMDD e poi chiamare ottieniElencoDisponibilitaPrenotazioni()
    if (g >= fineFetchYYYYMMDD) {
      var precedenteFineFetchYYYYMMDD = fineFetchYYYYMMDD;
      var ffsplit = fineFetchYYYYMMDD.split("-");
      var anno = parseInt(ffsplit[0]);
      var mese = parseInt(ffsplit[1]);
      var nuovaFineFetchYYYYMMDD;
      while (true) {
        mese+=1; // 1 mese, poi magari lo portiamo a 3
        if (mese>12) { mese-=12; anno++; }
        nuovaFineFetchYYYYMMDD = anno+"-"+interoStringaCifre(mese,2)+"-01";
        if (g < nuovaFineFetchYYYYMMDD) break;
      }
      setFineFetchYYYYMMDD(nuovaFineFetchYYYYMMDD);
      ottieniElencoDisponibilitaPrenotazioni("aggiungi",precedenteFineFetchYYYYMMDD,nuovaFineFetchYYYYMMDD);
    }
  }

  function modificaGiornoSelezionato(incrementoGiorni) {
    if (incrementoGiorni===-1 && giornoSelezionato===oggiYYYYMMDD) return;
    var unixTimestampMillisec = parseInt(new Date(giornoSelezionato).getTime());
    unixTimestampMillisec += incrementoGiorni*24*60*60*1000;
    var d = new Date(unixTimestampMillisec);
    var g = interoStringaCifre(d.getDate(),2); // giorno del mese formattato a 2 cifre
    var m = interoStringaCifre(d.getMonth()+1,2); // mese formattato a 2 cifre
    var a = d.getFullYear(); // anno formattato a 4 cifre
    var giornoSettimana = d.getDay();
    if (giornoSettimana == 0) giornoSettimana = 7; // giorno della settimana da 1 a 7

    //console.log("nuovo giorno selezionato: " + a+"-"+m+"-"+g)
    selezionaNuovoGiorno(a+"-"+m+"-"+g);

    // eventuale cambio di settimana
    var ts = unixTimestampMillisec - (giornoSettimana-1)*24*60*60*1000;
    if (ts !== timestampInizioSettimana) setTimestampInizioSettimana(ts);    
  }

  function prenota() {
    //console.log("prenotazione per idCorso "+idCorsoSelezionato+" dalle " + appuntamentoOraInizio +":"+appuntamentoMinInizio+" alle "+appuntamentoOraFine+":"+appuntamentoMinFine);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        op:"prenotazioneCorsiIns", 
        idCorso:idCorsoSelezionato, 
        idPersona:props.identitaSelezionata.idpersona, 
        dataInizio:giornoSelezionato+"T"+interoStringaCifre(appuntamentoOraInizio,2)+":"+interoStringaCifre(appuntamentoMinInizio,2), 
        dataFine:giornoSelezionato+"T"+interoStringaCifre(appuntamentoOraFine,2)+":"+interoStringaCifre(appuntamentoMinFine,2), 
        note: "", 
        emak: props.emak, 
        sessionId: props.sessionId 
      })
    };
    fetch(props.UrlBackend, requestOptions)
    .then(risposta => risposta.json())
    .then(
      (valoreRitorno) => {
        if (valoreRitorno.verifvers===0) {
          // necessario aggiornare la app
          props.callbackAggiornaApp();
          return;
        }
        if (valoreRitorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
        if (valoreRitorno.risultatoOperazione==1) {
          window.alert("Prenotazione memorizzata");
        } else {
          var errore="";
          if (valoreRitorno.mess!=undefined) errore = valoreRitorno.mess;
          switch (errore) {
            
            case "Prenotazione non inserita in quanto rientrerebbe nel periodo di disdetta non consentito":              
              window.alert("PRENOTAZIONE FALLITA: non è possibile prenotare a meno di " + valoreRitorno.stringa_tempo_annullamento + " da adesso");
              break;

            case "Nessun posto disponibile":              
              window.alert("PRENOTAZIONE FALLITA: i posti che erano disponibili nell'intervallo selezionato sono stati occupati, selezionare un intervallo diverso");
              break;

            default:
              window.alert("PRENOTAZIONE FALLITA: la prenotazione non è stata memorizzata. Riprovare o contattare l'assistenza.");
          }
        }
        ottieniElencoDisponibilitaPrenotazioni("sostituisci", oggiYYYYMMDD, fineFetchYYYYMMDD); 
        setAppuntamentoOraInizio(-1); 
        setPagina(PAGINE.giorno);
      },
      (error) => { 
        props.callback(props.PAGINECLIENTI.login);
        return;
      }
    )
    .catch(function(error) {
      props.callback(props.PAGINECLIENTI.login);
      return;
    });  
  }

  function elimina() {
    const splitPrenotazioneVisualizzata = prenotati[giornoSelezionato][indicePrenotazioneVisualizzata].split("-");
    const dalle = splitPrenotazioneVisualizzata[0];
    const alle = splitPrenotazioneVisualizzata[1];
    const idCorsoVisualizzato = splitPrenotazioneVisualizzata[2];
    //console.log("eliminazione prenotazione per idCorso "+idCorsoVisualizzato+" dalle " + dalle + " alle "+alle);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        op:"prenotazioneCorsiCanc", 
        idCorso:idCorsoVisualizzato, 
        idPersona:props.identitaSelezionata.idpersona, 
        dataInizio:giornoSelezionato+"T"+dalle, 
        dataFine:giornoSelezionato+"T"+alle, 
        note: "", 
        emak: props.emak, 
        sessionId: props.sessionId 
      })
    };
    fetch(props.UrlBackend, requestOptions)
    .then(risposta => risposta.json())
    .then(
      (valoreRitorno) => {
        if (valoreRitorno.verifvers===0) {
          // necessario aggiornare la app
          props.callbackAggiornaApp();
          return;
        }
        if (valoreRitorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }        
        if (valoreRitorno.risultatoOperazione==1) {
          window.alert("Prenotazione eliminata");
        } else {
          if (valoreRitorno.mess === "Prenotazione non eliminabile in quanto oramai entrata nel periodo di disdetta non consentito") {
            window.alert("IMPOSSIBILE ELIMINARE: non è possibile disdire prenotazioni a meno di " + valoreRitorno.stringa_tempo_annullamento + " da adesso");
          } else {
            window.alert("ELIMINAZIONE FALLITA: la prenotazione non è stata eliminata. Riprovare o contattare l'assistenza.");
          }
        }
        ottieniElencoDisponibilitaPrenotazioni("sostituisci", oggiYYYYMMDD, fineFetchYYYYMMDD); 
        setAppuntamentoOraInizio(-1); 
        setPagina(PAGINE.giorno);        
      },
      (error) => { 
        props.callback(props.PAGINECLIENTI.login);
        return; // QUI VA GESTITO COSA FARE NEL FRONTEND SE BACKEND DICE ERRORE
      }
    )
    .catch(function(error) {
      props.callback(props.PAGINECLIENTI.login);
      return;
    });  
  }

  function callbackDalle($ora, $min) {
    setAppuntamentoOraInizio($ora);
    setAppuntamentoMinInizio($min);
  }

  function callbackAlle($ora, $min) {
    setAppuntamentoOraFine($ora);
    setAppuntamentoMinFine($min);
  }

  function calcolaFontSizePrenotati(altezzaRettangolo) {
    //console.log("calcolaFontSizePrenotati "+altezzaRettangolo)
    if (altezzaRettangolo>30) {
      //console.log("->altezza fissa 18");
      return 18;
    }
    if (altezzaRettangolo<10) {
      //console.log("->altezza fissa 0");
      return 0;
    }
    //console.log("->altezza calcolata "+(altezzaRettangolo*0.6));
    return altezzaRettangolo*0.6;
  }

  var parts = giornoSelezionato.split('-');
  const stringaGiornoSelezionato = parts[2] + " " + nomiMesiCompleti[parts[1]-1] + " " + parts[0];

  if (loading) return (<Clessidra loading={true} />)

    if (pagina === PAGINE.selezionaCorso) {
  
      if (corsi.length === 0) {
          window.alert("Prenotazioni: Non ci sono corsi o attrezzature da prenotare");
          return (<div></div>);
      }
  
      return (
        <div style={{overflowY:'auto'}}>
          <div style={{ fontSize: 20, textAlign: "center", marginTop:10, marginBottom: 10, color: "#E0E0E0"}}>
            Seleziona:
          </div>
          <div style={{ marginLeft: 30, marginRight: 30, justifyContent: 'center', backgroundColor: "#DDDDDD", marginBottom: 20 }}>
            {corsi.map((x, i) => 
              <div key={i} style={{ width: "100%", alignContent: "center", cursor: 'pointer'}} 
                onClick={() => {
                  setCorsoSelezionato(x.NOME); 
                  setIDCorsoSelezionato(x.ID); 
                  if (x.DURATA_MINIMA > 0) setMinutiMinimiPrenotazione(parseInt(x.DURATA_MINIMA)); 
                  else setMinutiMinimiPrenotazione(30);
                  setAppuntamentoOraInizio(-1); 
                  setPagina(PAGINE.giorno);
                }}>
                <div className={stile.prenotazioniElemento}>
                  {x.NOME}
                </div>
              </div>
            )}
          </div>
        </div>
      );
  }

  if (pagina === PAGINE.help) {
    var testo = "Usa le frecce o il calendario settimanale per scegliere il giorno.\n\nClicca su un orario verde per fare una nuova prenotazione.\n\nClicca su una prenotazione per visualizzarla o eliminarla.";

    return (
        <div style={{flexDirection: 'column', flex: 1, justifyContent: 'center'}}>
            <div style={{marginLeft: 30, marginRight: 30, justifyContent: 'center', backgroundColor: "#DDDDDD"}}>
                <div style={{minHeight: 80, padding: 10, justifyContent: 'center'}}>
                    <div style={{width: "100%", textAlign: 'center', fontSize: 20, justifyContent: 'center', color: "#606060"}}>
                        {testo}
                    </div>
                </div>
                <div style={{flexDirection: 'row'}}>
                    <div style={{ width: "100%", alignContent: "center"}} onClick={() => {
                        ottieniElencoDisponibilitaPrenotazioni("sostituisci", oggiYYYYMMDD, fineFetchYYYYMMDD); 
                        setAppuntamentoOraInizio(-1); 
                        setPagina(PAGINE.giorno);
                    }}>                    
                        <div style={{
                            backgroundColor: "#0066FF", 
                            height: 80, 
                            color: "#FFFFFF", 
                            paddingTop: 5, 
                            paddingBottom: 5, 
                            textAlign: 'center',
                            textAlignVertical: 'center',
                            fontSize: 22,
                        }}>OK</div>
                    </div>  
                </div>
            </div>  
        </div>
    )
  }

  if (pagina === PAGINE.nuovo) {
    // position:'absolute', bottom:0
    return (
        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}> 

            <div style={{ display:'flex', flex: 1, flexDirection: 'column', marginTop: 30, marginLeft: 20, marginRight: 20, backgroundColor: "#f8f3ac" }}>      

                <div style={{ 
                  display:'flex', 
                  padding: 5, 
                  width: "100%", 
                  justifyContent: 'space-between', // Distribuisce spazio tra testo e immagine
                  minHeight: 30, 
                  borderBottomColor: "#606060", 
                  borderBottomWidth: 1, 
                  borderBottomStyle: 'solid',
                  position: 'relative', // Necessario per posizionare l'immagine
                  overflow: 'visible' // Permette all'immagine di uscire dalla div
                }}>
                    <div style={{ width: "auto", fontSize: 22, color: "#606060", marginLeft: 20,  whiteSpace: "pre-line" }}>
                        Nuova{"\n"}Prenotazione
                    </div>
                    <img 
                      src={require('./pixabay-pin1.png')} 
                      alt="Pin" 
                      style={{ 
                          position: 'absolute', // Posizionamento assoluto
                          top: -20, // Sposta l'immagine verso l'alto
                          right: 20, // Puoi regolare questo valore per posizionare l'immagine
                          width: 80, // Imposta una larghezza per l'immagine
                          height: 80, // Imposta un'altezza per l'immagine
                          zIndex: 10 // Assicurati che sia sopra gli altri elementi
                      }} 
                  />
                </div>

                <div style={{ display:'flex', flex: 1, width: "100%", justifyContent: 'center', minHeight: 15 }}>
                    <div style={{ width: "100%", textAlign: 'center', fontSize: 20, color: "#606060", fontWeight: "bold" }}>
                        {corsoSelezionato}
                    </div>
                </div>

                <div style={{ display:'flex', flex: 1, width: "100%", justifyContent: 'center', minHeight: 15 }}>
                    <div style={{ width: "100%", textAlign: 'center', fontSize: 20, color: "#606060" }}>
                        {convertiDataComputer2Umano(giornoSelezionato, "gSett")}
                    </div>
                </div>

                <div style={{ flex: 8, width: "100%", justifyContent: 'center' }}>
                    <div style={{ display:'flex', flexDirection: 'row' }}>
                        <div style={{ display:'flex', flex: 1 }}></div>
                        <SelezionaOrario etichetta="Dalle ore" ora={appuntamentoOraInizio} minuto={appuntamentoMinInizio} minutiMinimiPrenotazione={minutiMinimiPrenotazione} flagOrarioErrato={!flagOrariCorretti || flagSovrapposizione || numeroPostiNelIntervallo === 0} callback={callbackDalle} />
                        <div style={{ display:'flex', flex: 1 }}></div>
                        <SelezionaOrario etichetta="Alle ore" ora={appuntamentoOraFine} minuto={appuntamentoMinFine} minutiMinimiPrenotazione={minutiMinimiPrenotazione} flagOrarioErrato={!flagOrariCorretti || flagSovrapposizione || numeroPostiNelIntervallo === 0} callback={callbackAlle} />
                        <div style={{ display:'flex', flex: 1 }}></div>
                    </div>
                </div>

                <div style={{ display:'flex', width: "100%", marginBottom: 30, backgroundColor: "#d8d38c", padding: 10 }}>
                    {flagOrariCorretti ? 
                        flagSovrapposizione ? 
                            <div style={{ textAlign: 'center', fontSize: 20, color: "#900000" }}>
                                Gli orari scelti si sovrappongono{"\n"}a un'altra tua prenotazione
                            </div>
                        :
                            <div style={{ textAlign: 'center', fontSize: 20, color: numeroPostiNelIntervallo > 0 ? "#505020" : "#900000" }}>
                                Numero di posti disponibili{"\n"}nell'orario selezionato: {numeroPostiNelIntervallo}
                            </div>
                    :
                        <div style={{ textAlign: 'center', fontSize: 20, color: "#900000" }}>
                            Gli orari scelti{"\n"}non sono corretti
                        </div>
                    }
                </div>
            </div>

            <div style={{ display:'flex', marginTop: 30, flexDirection: 'row' }}>

                <div 
                    className={`${stile.bottone3} ${pagina === PAGINE.eventoInserisci ? stile.bottoneColoreRosso : stile.bottoneColoreNormale}`} 
                    onClick={() => { setAppuntamentoOraInizio(-1); setPagina(PAGINE.giorno) }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./annulla.png')}
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                </div>

                <div 
                    className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                    onClick={() => { if (numeroPostiNelIntervallo > 0) prenota() }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./modifica.png')}
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>PRENOTA</div>
                </div>
            </div>

        </div>
    )
  }

  if (pagina === PAGINE.rivediPrenotazione || pagina === PAGINE.confermaCanc) {
    const splitPrenotazioneVisualizzata = prenotati[giornoSelezionato][indicePrenotazioneVisualizzata].split("-");
    const dalle = splitPrenotazioneVisualizzata[0];
    const alle = splitPrenotazioneVisualizzata[1];
    const idCorsoVisualizzato = splitPrenotazioneVisualizzata[2];
    const corsoPrenotazioneVisualizzata = corsi[elencoIDCorsi[idCorsoVisualizzato]].NOME;

    return (
      <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>

        <div style={{ display:'flex', flex: 1, flexDirection: 'column', marginTop: 30, marginLeft: 20, marginRight: 20, backgroundColor: "#f8f3ac" }} onClick={() => { setAppuntamentoOraInizio(-1); setPagina(PAGINE.giorno) }}>

          <div style={{ display:'flex', height: 80, width: "100%", justifyContent: 'center', minHeight: 30, borderBottomColor: "#606060", borderBottomWidth: 1 }}>
          <div style={{ 
                  display:'flex', 
                  padding: 5, 
                  width: "100%", 
                  justifyContent: 'space-between', // Distribuisce spazio tra testo e immagine
                  minHeight: 30, 
                  borderBottomColor: "#606060", 
                  borderBottomWidth: 1, 
                  borderBottomStyle: 'solid',
                  position: 'relative', // Necessario per posizionare l'immagine
                  overflow: 'visible' // Permette all'immagine di uscire dalla div
                }}>
                    <div style={{ width: "auto", fontSize: 22, color: "#606060", marginLeft: 20,  whiteSpace: "pre-line" }}>
                        Visualizza{"\n"}Prenotazione
                    </div>
                    <img 
                      src={require('./pixabay-pin1.png')} 
                      alt="Pin" 
                      style={{ 
                          position: 'absolute', // Posizionamento assoluto
                          top: -20, // Sposta l'immagine verso l'alto
                          right: 20, // Puoi regolare questo valore per posizionare l'immagine
                          width: 80, // Imposta una larghezza per l'immagine
                          height: 80, // Imposta un'altezza per l'immagine
                          zIndex: 10 // Assicurati che sia sopra gli altri elementi
                      }} 
                  />
                </div>
          </div>

          <div style={{ flex: 1, width: "100%", justifyContent: 'center'}}>
            </div>

          <div style={{ flex: 3, width: "100%", justifyContent: 'center'}}>
            <div style={{ fontSize: 18, color: "#606030", marginLeft: 40, paddingLeft: 10, paddingRight: 20, backgroundColor: "#c8c37c" }}>
              Prenotazione:
            </div>
            <div style={{ marginLeft: 50, marginTop: 10, fontSize: 20, color: "#606060" }}>{corsoPrenotazioneVisualizzata}</div>
          </div>

          <div style={{ flex: 3, width: "100%", justifyContent: 'center' }}>
            <div style={{ fontSize: 18, color: "#606030", marginLeft: 40, paddingLeft: 10, paddingRight: 20, backgroundColor: "#c8c37c" }}>
              Giorno:
            </div>
            <div style={{ marginLeft: 50, marginTop: 10, fontSize: 20, color: "#606060" }}>{stringaGiornoSelezionato}</div>
          </div>

          <div style={{ flex: 3, width: "100%", justifyContent: 'center' }}>
            <div style={{ fontSize: 18, color: "#606030", marginLeft: 40, paddingLeft: 10, paddingRight: 20, backgroundColor: "#c8c37c" }}>
              Orario:
            </div>
            <div style={{ marginLeft: 50, marginTop: 10, fontSize: 20, color: "#606060" }}>{dalle} - {alle}</div>
          </div>          

        </div>

        <div style={{ display:'flex', marginTop: 30, flexDirection: 'row' }}>
          <div 
            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
            onClick={() => { setPagina(PAGINE.giorno) }}
          >
            <div className={stile.spazioIconaPiccola}>
              <img
                className={stile.iconaPiccolaQuadrata}
                src={require('./annulla.png')}
              />
            </div>
            <div className={stile.bottoneTestoNormale}>INDIETRO</div>
          </div>
          <div 
            className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
            onClick={() => { setPagina(PAGINE.confermaCanc) }}
          >
            <div className={stile.spazioIconaPiccola}>
              <img
                className={stile.iconaPiccolaQuadrata}
                src={require('./elimina.png')}
              />
            </div>
            <div className={stile.bottoneTestoNormale}>ELIMINA</div>
          </div>
        </div>

        {pagina === PAGINE.confermaCanc ?
          <Modal 
            size={props.larghezza} 
            isOpen={true} 
            style={{ 
              backgroundColor: "transparent"
            }}
          >
            <div style={{ 
              display:'flex',
              backgroundColor: "lightgray", 
              flexDirection: "column", 
              padding:10, 
              boxShadow: "0 8px 10px rgba(0, 0, 0, 0.5)", 
              borderWidth: 2, 
              borderColor: "black"
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 10 }}>Confermi la cancellazione{"\n"}della prenotazione</div>
              <div style={{ width: "100%", marginTop: 10, marginBottom: 10, textAlign: 'center', fontSize: 20, color: "#606060" }}>
                {corsoPrenotazioneVisualizzata}
              </div>
              <div style={{ width: "100%", textAlign: 'center', fontSize: 20, color: "#606060" }}>
                per il {stringaGiornoSelezionato}{"\n"}dalle {dalle} alle {alle}?{"\n"}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                <div 
                  className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
                  onClick={() => { setPagina(PAGINE.rivediPrenotazione) }}
                >
                  <div className={stile.spazioIconaPiccola}>
                    <img
                      className={stile.iconaPiccolaQuadrata}
                      src={require('./annulla.png')}
                    />
                  </div>
                  <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                </div>
                <div 
                  className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                  onClick={() => { elimina() }}
                >
                  <div className={stile.spazioIconaPiccola}>
                    <img
                      className={stile.iconaPiccolaQuadrata}
                      src={require('./ok.png')}
                    />
                  </div>
                  <div className={stile.bottoneTestoNormale}>CONFERMA</div>
                </div>
              </div>
            </div>
          </Modal>
          :
          <></>
        }
      </div>
    );
  }

  if (pagina === PAGINE.giorno) {
    var arrayPrenotazioni = [],
      elemento,
      s = "",
      sInizio = "",
      sFine = "",
      hInizio = 0,
      orarioPrenotazione = [];
    if (flagDisponibilita) {
      if (prenotati[giornoSelezionato] !== undefined) {
        for (let i = 0; i < prenotati[giornoSelezionato].length; i++) {
          s = prenotati[giornoSelezionato][i].split("-");
          sInizio = s[0].split(":");
          sFine = s[1].split(":");
          hInizio = parseFloat(sInizio[0]) + parseFloat(sInizio[1] / 60);
          elemento = {
            indice: i,
            inizio: hInizio,
            durata:
              parseFloat(sFine[0]) +
              parseFloat(sFine[1] / 60) -
              hInizio,
            idCorso: s[2],
            offsetSinistro: 0,
          };
          arrayPrenotazioni.push(elemento);
          orarioPrenotazione.push("dalle " + s[0] + " alle " + s[1]);
        }
        if (flagAccettaPrenotazioniSovrapposte) {
          arrayPrenotazioni.sort((a, b) => {
            return b.durata - a.durata;
          });
  
          for (let questo = 0; questo < arrayPrenotazioni.length; questo++) {
            for (let precedente = 0; precedente < questo; precedente++) {
              if (
                (arrayPrenotazioni[questo].inizio <= arrayPrenotazioni[precedente].inizio &&
                  arrayPrenotazioni[questo].inizio +
                    arrayPrenotazioni[questo].durata >
                    arrayPrenotazioni[precedente].inizio) ||
                (arrayPrenotazioni[questo].inizio >=
                  arrayPrenotazioni[precedente].inizio &&
                  arrayPrenotazioni[questo].inizio <
                    arrayPrenotazioni[precedente].inizio +
                      arrayPrenotazioni[precedente].durata)
              ) {
                if (
                  arrayPrenotazioni[questo].offsetSinistro <
                  arrayPrenotazioni[precedente].offsetSinistro + step
                ) {
                  arrayPrenotazioni[questo].offsetSinistro =
                    arrayPrenotazioni[precedente].offsetSinistro + step;
                }
              }
            }
          }
        }
      }
    }
  
    return (
      <>
        {flagSelezionaDataAperto ? (
          <SelezionaData
            data={giornoSelezionato}
            setData={(g) => {
              if (g < oggiYYYYMMDD) g = oggiYYYYMMDD;
              selezionaNuovoGiorno(g);
              var unixTimestampMillisec = parseInt(new Date(g).getTime());
              var d = new Date(unixTimestampMillisec);
              var g = interoStringaCifre(d.getDate(), 2);
              var giornoSettimana = d.getDay();
              if (giornoSettimana == 0) giornoSettimana = 7;
              var ts =
                unixTimestampMillisec - (giornoSettimana - 1) * 24 * 60 * 60 * 1000;
              if (ts !== timestampInizioSettimana) setTimestampInizioSettimana(ts);
            }}
            titolo={"Seleziona giorno"}
            flagAperto={true}
            flagCasellaTestoConData={false}
            setFlagAperto={setFlagSelezionaDataAperto}
          />
        ) : null}
  
        <div className={stile.prenotazioniviewContenitoreTitolo}>
          <div className={stile.prenotazioniviewFreccia}>
            <div
              className={stile.prenotazionitestoFreccia}
              onClick={() => {
                var ts = timestampInizioSettimana - 86400 * 7 * 1000;
                if (ts >= timestampInizioPrimaSettimana) setTimestampInizioSettimana(ts);
              }}
            >
              &nbsp;◀&nbsp;
            </div>
          </div>
          <div className={stile.prenotazioniviewTitoloNomeMese}>
            <div className={stile.prenotazionitestoNomeMeseTitolo}>{titoloNomeMese}</div>
          </div>
          <div className={stile.prenotazioniviewFreccia}>
            <div
              className={stile.prenotazionitestoFreccia}
              onClick={() =>
                setTimestampInizioSettimana(timestampInizioSettimana + 86400 * 7 * 1000)
              }
            >
              &nbsp;▶&nbsp;
            </div>
          </div>
        </div>
  
        <div className={stile.prenotazioniviewContenitoreStrisciaCalendario}>
          {[...Array(7)].map((x, i) => (
            <div key={i} className={stile.prenotazioniviewGiornoSettimana}>
              <div className={stile.prenotazionitestoNomeGiorno}>
                {giorniSettimanaNome[i]}
              </div>
              <div
                className={
                  giorniSettimanaAnno[i] +
                    "-" +
                    interoStringaCifre(giorniSettimanaMeseNumerico[i], 2) +
                    "-" +
                    interoStringaCifre(giorniSettimanaNumero[i], 2) ===
                  giornoSelezionato
                    ? stile.prenotazionitestoNumeroGiornoSelezionato
                    : giorniSettimanaAnno[i] +
                        "-" +
                        interoStringaCifre(giorniSettimanaMeseNumerico[i], 2) +
                        "-" +
                        interoStringaCifre(giorniSettimanaNumero[i], 2) <
                      oggiYYYYMMDD
                    ? stile.prenotazionitestoNumeroGiornoDisabilitato
                    : stile.prenotazionitestoNumeroGiorno
                }
                onClick={() => {
                  if (
                    giorniSettimanaAnno[i] +
                      "-" +
                      interoStringaCifre(giorniSettimanaMeseNumerico[i], 2) +
                      "-" +
                      interoStringaCifre(giorniSettimanaNumero[i], 2) <
                    oggiYYYYMMDD
                  )
                    return;
                  selezionaNuovoGiorno(
                    giorniSettimanaAnno[i] +
                      "-" +
                      interoStringaCifre(giorniSettimanaMeseNumerico[i], 2) +
                      "-" +
                      interoStringaCifre(giorniSettimanaNumero[i], 2)
                  );
                }}
              >
                {giorniSettimanaNumero[i]}
              </div>
              <div className={stile.prenotazionitestoNomeMese}>
                {giorniSettimanaMese[i]}
              </div>
            </div>
          ))}
        </div>
  
        <div style={{ display:"flex", flexDirection: "row", height: 50, width: "100%", backgroundColor: "#FFFFDD" }}>
          <div
            style={{ display:"flex", flex: 1, justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              modificaGiornoSelezionato(-1);
            }}
          >
            <img style={{ maxHeight: 45 }} src={require("./frecciasinistra.png")} />
          </div>
          <div
            style={{ display:"flex", flex: 1, justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              if (zoom < massimoZoom) setZoom(zoom + passoZoom);
            }}
          >
            <img style={{ maxHeight: 45 }} src={require("./zoompiu.png")} />
          </div>
          <div
            style={{ display:"flex", flex: 1, justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              if (zoom >= 30 + passoZoom) setZoom(zoom - passoZoom);
            }}
          >
            <img style={{ maxHeight: 45 }} src={require("./zoommeno.png")} />
          </div>
          <div
            style={{ display:"flex", flex: 1, justifyContent: "center", alignItems: "center" }}
            onClick={() => setFlagSelezionaDataAperto(true)}
          >
            <img style={{ maxHeight: 45 }} src={require("./calendario.png")} />
          </div>
          <div
            style={{ display:"flex", flex: 1, justifyContent: "center", alignItems: "center" }}
            onClick={() => setFlagVisualizzaCalendario(!flagVisualizzaCalendario)}
          >
            {flagVisualizzaCalendario ? (
              <img style={{ maxHeight: 45 }} src={require("./frecciasu.png")} />
            ) : (
              <img style={{ maxHeight: 45 }} src={require("./frecciagiu.png")} />
            )}
          </div>
          <div
            style={{ display:"flex", flex: 1, justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              modificaGiornoSelezionato(1);
            }}
          >
            <img style={{ maxHeight: 45 }} src={require("./frecciadestra.png")} />
          </div>
        </div>

        {/* testo che indica il giorno selezionato */}
        <div className={stile.prenotazionitestoGiornoSelezionato}>{stringaGiornoSelezionato}</div>

        {loadingDisp && <Clessidra loading={true} />}

        {/* scrollview che contiene le ore del giorno */}
        <div ref={refScroll} style={{ position:'relative', overflowY: "scroll" }} onScroll={() => refScroll.current.scrollTo({ behavior: "auto" })}>
          
          {/* Spazio totale occupato dalle 24 ore */}
          <div style={{ height: zoom * 24 }}></div>

          {/* intervalli prenotabili (disponibilità) */}
          {flagDisponibilita ?       
            disponibilitaInizio[giornoSelezionato].map((x, i) => 
              <div key={i} style={{
                position: 'absolute', 
                top: zoom * x / 60,
                left: 50, 
                width: "100%",
                height: zoom * disponibilitaDurata[giornoSelezionato][i] / 60,
                backgroundColor: "#1e8c51", 
                padding: 5,
              }}></div>
            )
          : null}

          {/* intervalli cliccabili (tutte le ore) */}
          {[...Array(24)].map((x, i) =>
            <div key={i} style={{
              position: 'absolute', 
              top: zoom * i, 
              left: 50,          
              width: "100%",      
              height: zoom,
              backgroundColor: "transparent"
            }} onClick={(evt) => intervalloCliccato(i, 60 * evt.nativeEvent.offsetY / zoom)}>
            </div>
          )}

          {/* intervalli prenotati dalla persona selezionata */}
          {flagDisponibilita ? 
            arrayPrenotazioni.map((x, i) => 
              <div key={i} onClick={() => {setIndicePrenotazioneVisualizzata(x.indice); setPagina(PAGINE.rivediPrenotazione)}} style={{
                position: 'absolute', 
                top: zoom * x.inizio, 
                left: 50, 
                width: "100%",
                height: zoom * x.durata,
                backgroundColor: x.idCorso === idCorsoSelezionato ? colorePrenotazioniQuestoCorso : colorePrenotazioniAltriCorsi, 
                paddingLeft: 10,
                marginLeft: step + x.offsetSinistro,
                borderRadius: 5, 
                border: "1px solid #C0C0C0"
              }}>
                <div style={{ fontSize: calcolaFontSizePrenotati(zoom * x.durata), color: "#FFFFFF" }}>
                  {corsi[elencoIDCorsi[x.idCorso]].NOME + " " + orarioPrenotazione[x.indice]}
                </div>
              </div>
            )
          : null}

          {/* 24 righe orizzontali */}
          {[...Array(24)].map((x, i) =>
            <div key={i} style={{
              position: 'absolute', 
              top: zoom * i,
              width: "100%",
              left: 50,
              height: 1,
              borderTop: "0.5px solid #E0E0E0",
              borderBottom: "none",
            }}/>
          )}

          {/* indicazione delle 24 ore */}
          {[...Array(24)].map((x, i) =>
            <div key={i} style={{
              position: 'absolute', 
              top: zoom * i - 10,
              width: 44,
            }}>
              {i > 0 ? <div style={{ fontSize: 13, color: "#C0C0C0", textAlign: 'right' }}>Ore {i}</div> : null}
            </div>
          )}
        </div>


      </>
    );
  }
  

  // se arrivo qui è pagina non trovata
  return (<div>Errore pagina non trovata (Prenotazione Corsi)</div>);
}

export default PrenotazioniCorsi;
