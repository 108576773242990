import React, {Fragment,useState,useEffect,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
  import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaPosizioneSocieta/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '../Clessidra.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Modale from '../Modale.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaPosizioniSocieta(props) {

    const operazione = "elencoPosizioniSocieta";
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');

    const coloreDirettivoRigaPari="#FFC0CB";
    const coloreDirettivoRigaDispari="#fadadd";

    const [flagModaleInfoPosizione, setFlagModaleInfoPosizione] = React.useState(false);
 
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE'
      },
      {
        etichetta: 'Direttivo',
        accessor: 'FLAG_DIRETTIVO'
      },
      {
        etichetta: 'Appartenente Libro Soci',
        accessor: 'FLAG_LIBRO_SOCI'
      },
    ];
    const arrayTipiDati=[];
    arrayTipiDati[2]="flag";
    arrayTipiDati[3]="flag";

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  function tornaElenco() {
      setFlagModaleInfoPosizione(false);
  }
  return (
      <div>

      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda posizione in società in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda posizione in società in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda posizione in società in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_PosSoc</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ?   <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>

          {flagModaleInfoPosizione=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Che cosa sono e qual è la funzione delle posizioni in società?"
                        flagErrore={false}
                        contenuto={<div>Le posizioni in società possono includere, ad esempio, Socio Ordinario, Socio Onorario, Presidente, Segretario, Allenatore, Atleta, ecc.<br/>
                        Queste possono essere caratterizzate da diverse specifiche:<br/> 
                        <p>- <b>Appartenenza al "Direttivo"</b>: nella scheda della Posizione è possibile indicare se appartenente al Direttivo o meno, tramite l'apposito checkbox.
                          Successivamente, recandosi sulla scheda di un Iscritto (menu principale, Anagrafiche, Elenco Iscritti), nella sezione 'Posizioni e Cariche Sociali' sarà possibile assegnare la Posizione all'Iscritto indicando le date di inizio ed eventualmente di fine assegnazione.
                          Se la Posizione appartiene al Direttivo, l'Iscritto in esame risulterà nell'elenco del Direttivo per il periodo di assegnazione indicato.</p>
                        <p>- <b>Inclusione nel "Libro Soci"</b>: nella scheda della Posizione è possibile indicare se appartenente al Libro Soci o meno, tramite l'apposito checkbox.
                          Successivamente, recandosi sulla scheda di un Iscritto (menu principale, Anagrafiche, Elenco Iscritti), nella sezione 'Posizioni e Cariche Sociali' sarà possibile assegnare la Posizione all'Iscritto indicando le date di inizio ed eventualmente di fine assegnazione.
                          Se la Posizione appartiene al Libro Soci, l'Iscritto in esame sarà incluso nel Libro Soci per il periodo indicato.</p>
                        <p>- <b>Quota associativa</b>:
                        È possibile associare una quota associativa specifica a ogni posizione tramite il bottone 'Nuovo' in Gestione Economica, Tabella Quote Associative. Assegnando una quota a una posizione, tutti gli iscritti che ricoprono quella posizione saranno tenuti al versamento della quota stabilita.
                        </p>
                        
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}

                    <CardBody>
                            <CardTitle>Elenco Posizioni in Società
                            </CardTitle>
                            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
                            &nbsp;&nbsp;Legenda:
                            <span style={{marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Non Appartenente al Direttivo</span>
                            <span style={{backgroundColor:coloreDirettivoRigaPari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Appartenente al Direttivo</span>
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPosizione(true)}/>
                    </CardBody>

                        
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              <TabellaRicerca 
                              columns={colonne} 
                              data={risultatoServer} 
                              arrayTipiDati={arrayTipiDati} 
                              campoChiave={0} 
                              campoOrdinamento={1} 
                              nascondiColonna={0} 
                              coloriPersonalizzati={[
                                {"campo":"2","condizione":"==","valore":"1","carattereRigaPari":"#000000","sfondoRigaPari":coloreDirettivoRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreDirettivoRigaDispari}
                              ]}
                              callbackSelezionaElemento={VaiModifica} 
                              callbackEliminaElemento={VaiElimina}/>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      
        </div>
      :""}
    </div>
   );
}
export default TabellaPosizioniSocieta;