import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import TendinaEditabile from '../TendinaEditabile.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import FormattaImportoVideo from '../FormattaImportoVideo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;
const STATO_SCHEDA_SINGOLA_COMUNICAZIONE = 7;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    const [dataInvioDefault,setDataInvioDefault]=useState(dataOggi);

    const dataInvio   = useRef();
    const sugg1  = useRef();
    const sugg2  = useRef();
    const [sugg1Default,setSugg1Default]=useState('0');
    const [sugg2Default,setSugg2Default]=useState('0');
    const [insSugg1,setSugg1]=useState('0');
    const [insSugg2,setSugg2]=useState('0');
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [statoTendinaIscritti,setStatoTendinaIscritti]=useState(false);
    const [statoTendinaProspect,setStatoTendinaProspect]=useState(false);
    const [statoTendinaMezziComunicazione,setStatoTendinaMezziComunicazione]=useState(false);

    const [modaleInfoDestinatari,setModaleInfoDestinatari]=useState(false);
    const [modaleInfoParametri,setModaleInfoParametri]=useState(false);
    const [modaleNoMezzo,setModaleNoMezzo]=useState(false);
    const [modaleNoData,setModaleNoData]=useState(false);

    const [modaleInfoPunteggioRisposte,setModaleInfoPunteggioRisposte]=useState(false);
    const [modaleInfoPunteggioAbbonamenti,setModaleInfoPunteggioAbbonamenti]=useState(false);
    const [modaleInfoPunteggioEventi,setModaleInfoPunteggioEventi]=useState(false);

    const [modaleInfoPercPos,setModaleInfoPercPos]=useState(false);
    const [modaleInfoPercNeu,setModaleInfoPercNeu]=useState(false);
    const [modaleInfoPercNeg,setModaleInfoPercNeg]=useState(false);

    const [modaleInfoProssimaAzione,setModaleInfoProssimaAzione]=useState(false);

    const [recuperatoDatiComunicazione,setRecuperatoDatiComunicazione]=useState(false);
    
    const [risultatoServerDatiIscritti,setRisultatoServerDatiIscritti]=useState('');
    const [risultatoServerDatiProspect,setRisultatoServerDatiProspect]=useState('');
    //const [risultatoServerDatiMezziComunicazione,setRisultatoServerDatiMezziComunicazione]=useState('');

    const [risultatoServer,setRisultatoServer]=useState('');
    

    const [iscrittiFiltrati, setIscrittiFiltrati] = useState([]);
    const [prospectFiltrati, setProspectFiltrati] = useState([]);

    const [testoRicercaIscritti, setTestoRicercaIscritti] = useState("");
    const [testoRicercaProspect, setTestoRicercaProspect] = useState("");

    //const coloreEvidenziato="#84b352";
    const coloreEvidenziato='#1E90FF';
    const coloreVerde='#98FB98';
    const coloreGiallo='#EEDD82';
    const coloreRosso='#F08080';


    const [numeroIscrittiSelezionati, setNumeroIscrittiSelezionati] = useState(0);
    const [numeroProspectSelezionati, setNumeroProspectSelezionati] = useState(0);

    const [iscrittiSelezionati, setIscrittiSelezionati] = useState([]);
    const [prospectSelezionati, setProspectSelezionati] = useState([]);

    const testo   = useRef();
    const [testoDefault,setTestoDefault]=useState('');

    const risposta   = useRef();
    const [rispostaDefault, setRispostaDefault] = useState('');

    const [insMezzoEdi, setInsMezzoEdi] = useState('');
    const [insIdMezzoEdi, setInsIdMezzoEdi] = useState(0);

    const [destinatariIscrittiDefault, setDestinatariIscrittiDefault] = useState([]);//contiene eventualmente tutti gli ID degli iscritti che sono stati destinatari della comunicazione
    const [destinatariProspectDefault, setDestinatariProspectDefault] = useState([]);//contiene eventualmente tutti gli ID dei prospect che sono stati destinatari della comunicazione

    const [arrayEsitiPossibili, setArrayEsitiPossibili] = useState(['Neutro','Positivo','Negativo']);

    const [esitiIscritti, setEsitiIscritti] = useState([]);//ha chiave ID_ISCRITTO
    const [esitiProspect, setEsitiProspect] = useState([]);//ha chiave ID_PROSPECT

    const [risposteIscritti, setRisposteIscritti] = useState([]);//ha chiave ID_ISCRITTO
    const [risposteProspect, setrisposteProspect] = useState([]);//ha chiave ID_PROSPECT

    const [arrayIdMezziPossibili, setArrayIdMezziPossibili] = useState([]);//ha chiave 
    const [arrayMezziPossibili, setArrayMezziPossibili] = useState([]);//ha chiave ID del mezzo

    const [cognomeDefault, setCognomeDefault] = useState('');
    const [nomeDefault, setNomeDefault] = useState('');
    const [tesseraDefault, setTesseraDefault] = useState('');
    const [emailDefault, setEmailDefault] = useState('');
    const [cellulareDefault, setCellulareDefault] = useState('');
    const [esitoDefault, setEsitoDefault] = useState('');

    const [azioneDefault, setAzioneDefault] = useState('');
    const [dataAzioneDefault, setDataAzioneDefault] = useState('');
    const dataAzione  = useRef();
    const azione  = useRef();
    //per i punteggi:
    const [percRispostePos,setRispostePos] = useState(0);
    const [percRisposteNeu,setRisposteNeu] = useState(0);
    const [percRisposteNeg,setRisposteNeg] = useState(0);
    const [puntRisp,setPuntRisp] = useState(0);
    const [puntAbbo,setPuntAbbo] = useState(0);
    const [puntEve,setPuntEve] = useState(0);
    //per iscritti:
    const [percRispostePosMediaAltriIscritti,setRispostePosMediaAltriIscritti] = useState(0);
    const [percRisposteNeuMediaAltriIscritti,setRisposteNeuMediaAltriIscritti] = useState(0);
    const [percRisposteNegMediaAltriIscritti,setRisposteNegMediaAltriIscritti] = useState(0);
    const [puntRispMediaAltriIscritti,setPuntRispMediaAltriIscritti] = useState(0);
    const [puntAbboMediaAltriIscritti,setPuntAbboMediaAltriIscritti] = useState(0);
    const [puntEveMediaAltriIscritti,setPuntEveMediaAltriIscritti] = useState(0);

    const [percRispostePosMaxAltriIscritti,setRispostePosMaxAltriIscritti] = useState(0);
    const [percRisposteNeuMaxAltriIscritti,setRisposteNeuMaxAltriIscritti] = useState(0);
    const [percRisposteNegMaxAltriIscritti,setRisposteNegMaxAltriIscritti] = useState(0);
    const [puntRispMaxAltriIscritti,setPuntRispMaxAltriIscritti] = useState(0);
    const [puntAbboMaxAltriIscritti,setPuntAbboMaxAltriIscritti] = useState(0);
    const [puntEveMaxAltriIscritti,setPuntEveMaxAltriIscritti] = useState(0);

    const [percRispostePosMinAltriIscritti,setRispostePosMinAltriIscritti] = useState(0);
    const [percRisposteNeuMinAltriIscritti,setRisposteNeuMinAltriIscritti] = useState(0);
    const [percRisposteNegMinAltriIscritti,setRisposteNegMinAltriIscritti] = useState(0);
    const [puntRispMinAltriIscritti,setPuntRispMinAltriIscritti] = useState(0);
    const [puntAbboMinAltriIscritti,setPuntAbboMinAltriIscritti] = useState(0);
    const [puntEveMinAltriIscritti,setPuntEveMinAltriIscritti] = useState(0);
    //per prospect:
    const [percRispostePosMediaAltriPro,setRispostePosMediaAltriPro] = useState(0);
    const [percRisposteNeuMediaAltriPro,setRisposteNeuMediaAltriPro] = useState(0);
    const [percRisposteNegMediaAltriPro,setRisposteNegMediaAltriPro] = useState(0);
    const [puntRispMediaAltriPro,setPuntRispMediaAltriPro] = useState(0);
    const [puntAbboMediaAltriPro,setPuntAbboMediaAltriPro] = useState(0);
    const [puntEveMediaAltriPro,setPuntEveMediaAltriPro] = useState(0);

    const [percRispostePosMaxAltriPro,setRispostePosMaxAltriPro] = useState(0);
    const [percRisposteNeuMaxAltriPro,setRisposteNeuMaxAltriPro] = useState(0);
    const [percRisposteNegMaxAltriPro,setRisposteNegMaxAltriPro] = useState(0);
    const [puntRispMaxAltriPro,setPuntRispMaxAltriPro] = useState(0);
    const [puntAbboMaxAltriPro,setPuntAbboMaxAltriPro] = useState(0);
    const [puntEveMaxAltriPro,setPuntEveMaxAltriPro] = useState(0);

    const [percRispostePosMinAltriPro,setRispostePosMinAltriPro] = useState(0);
    const [percRisposteNeuMinAltriPro,setRisposteNeuMinAltriPro] = useState(0);
    const [percRisposteNegMinAltriPro,setRisposteNegMinAltriPro] = useState(0);
    const [puntRispMinAltriPro,setPuntRispMinAltriPro] = useState(0);
    const [puntAbboMinAltriPro,setPuntAbboMinAltriPro] = useState(0);
    const [puntEveMinAltriPro,setPuntEveMinAltriPro] = useState(0);

    //per silgola comunicazione (quindi solo le medie per iscritti se si tratta di iscritto o per i prospect se si tratta di un contatto/prospect)

    const [percRispostePosMediaAltri,setRispostePosMediaAltri] = useState(0);
    const [percRisposteNeuMediaAltri,setRisposteNeuMediaAltri] = useState(0);
    const [percRisposteNegMediaAltri,setRisposteNegMediaAltri] = useState(0);
    const [puntRispMediaAltri,setPuntRispMediaAltri] = useState(0);
    const [puntAbboMediaAltri,setPuntAbboMediaAltri] = useState(0);
    const [puntEveMediaAltri,setPuntEveMediaAltri] = useState(0);

    const [ricercaValoriCrm,setRicercaValoriCrm]=useState(false);
    
    const [expandedIndexRispostaIscritti, setExpandedIndexRispostaIscritti] = useState(null);
    const [expandedIndexRispostaProspect, setExpandedIndexRispostaProspect] = useState(null);

    const handleMouseEnterRispostaIscritti = (index) => {setExpandedIndexRispostaIscritti(index);};
    const handleMouseLeaveRispostaIscritti = () => {setExpandedIndexRispostaIscritti(null);};
    const textareaStyleRispostaIscritti = (index) => ({
        // Aggiungi la logica di stile condizionale qui
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        width: '100%', // La textarea riempie tutta la larghezza del <td>
        height: index === expandedIndexRispostaIscritti ? '200px' : '50px', // Adatta l'altezza in base all'espansione
        transition: 'height 0.3s ease',
    });

    const handleMouseEnterRispostaProspect = (index) => {setExpandedIndexRispostaProspect(index);};
    const handleMouseLeaveRispostaProspect = () => {setExpandedIndexRispostaProspect(null);};
    const textareaStyleRispostaProspect = (index) => ({
        // Aggiungi la logica di stile condizionale qui
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        width: '100%', // La textarea riempie tutta la larghezza del <td>
        height: index === expandedIndexRispostaProspect ? '200px' : '50px', // Adatta l'altezza in base all'espansione
        transition: 'height 0.3s ease',
    });
    
    
    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaIscritti(false);
        setStatoTendinaProspect(false);
        setStatoTendinaMezziComunicazione(false);
        setModaleNoMezzo(false);
        setModaleNoData(false);
        
        ricercaIscritti();//ricerca iscritti ed ex-iscritti che abbiano data di uscita valorizzata
        ricercaProspect();
        ricercaMezziComunicazione();
        ricercaValCrm();
    },[]);

    useEffect(() => {
        //console.log("statoTendinaIscritti"+statoTendinaIscritti)
        //console.log("statoTendinaProspect"+statoTendinaProspect)
        //console.log("statoTendinaMezziComunicazione"+statoTendinaMezziComunicazione)
        let visual = 0;


        if(statoTendinaIscritti === true && statoTendinaProspect === true && statoTendinaMezziComunicazione === true){
            if(props.ope==='inserisci'){
                visual = 1;
            }else{
                if(recuperatoDatiComunicazione === true && ricercaValoriCrm === true){
                    visual = 1;
                }
            }
        }  
        if(visual ==1){  
            //ora che ho tutti i dati posso vedere quali eventualmente sono i selezionati sia tra gli iscritti che tra i prospect:
            let f=[];
            let s=[];
            for (let i=0; i<destinatariIscrittiDefault.length; i++) {
                f[i]=true;
                s[i]=false;
            }
            setIscrittiFiltrati(f);
            setIscrittiSelezionati(s);

            let fp=[];
            let sp=[];
            for (let ip=0; ip<destinatariProspectDefault.length; ip++) {
                fp[ip]=true;
                sp[ip]=false;
            }
            setProspectFiltrati(fp);
            setProspectSelezionati(sp);

            if(props.ope==='inserisci'){
                setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
            }else{
                setStatoPagina(STATO_SCHEDA_SINGOLA_COMUNICAZIONE);
            }
        }
    },[statoTendinaIscritti,statoTendinaProspect,statoTendinaMezziComunicazione,ricercaValoriCrm,recuperatoDatiComunicazione]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("comunicazioniCrmIns");
        if(props.ope==='modifica') setOperazione("comunicazioniCrmMod");
        if(props.ope==='cancella') setOperazione("comunicazioniCrmCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"comunicazioniCrmRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                   
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    //console.log("RISULTATO".valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    
                    setTestoDefault(valoreDiRitorno.risultatoRitorno[0].CONTENUTO);
                    setRispostaDefault(valoreDiRitorno.risultatoRitorno[0].RISPOSTA);
                    setInsIdMezzoEdi(valoreDiRitorno.risultatoRitorno[0].ID_MEZZO);
                    setInsMezzoEdi(valoreDiRitorno.risultatoRitorno[0].MEZZO);
                    setDataInvioDefault(valoreDiRitorno.risultatoRitorno[0].DATA);
                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setTesseraDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                    setEmailDefault(valoreDiRitorno.risultatoRitorno[0].EMAIL);
                    setCellulareDefault(valoreDiRitorno.risultatoRitorno[0].CELLULARE);
                    if(valoreDiRitorno.risultatoRitorno[0].ESITO != ''){
                        setEsitoDefault(valoreDiRitorno.risultatoRitorno[0].ESITO);
                    }
                    
                    setRecuperatoDatiComunicazione(true);
                    
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    //setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }
    },[props.ope]);

  

    const getBackgroundColor = (insSugg, 
        punteggioRisposte,punteggioAbbo,punteggioEve,punteggioRispostePos,punteggioRisposteNeu,punteggioRisposteNeg,
        maxRisposte, mediaRisposte, minRisposte, 
        maxAbbo, mediaAbbo, minAbbo, 
        maxEve, mediaEve, minEve, 
        maxRispostePos, mediaRispostePos, minRispostePos, 
        maxRisposteNeu, mediaRisposteNeu, minRisposteNeu, 
        maxRisposteNeg, mediaRisposteNeg, minRisposteNeg,  
        coloreVerde, coloreGiallo, coloreRosso, coloreEvidenziato, isSelected) => {
        if (isSelected) {
            return coloreEvidenziato;
        }
    
        switch (insSugg) {
            case '0':
                let sogliaSupRisposte = Number(mediaRisposte) + (Number(maxRisposte) - Number(mediaRisposte)) / 2;
                let sogliaInfRisposte = Number(minRisposte) + (Number(mediaRisposte) - Number(minRisposte)) / 2;
                if (punteggioRisposte >= sogliaSupRisposte)  return coloreVerde;
                else if (punteggioRisposte <= sogliaInfRisposte) return coloreRosso;
                else return coloreGiallo;
            case '1':
                let sogliaSupAbbo = Number(mediaAbbo) + (Number(maxAbbo) - Number(mediaAbbo)) / 2;
                let sogliaInfAbbo = Number(minAbbo) + (Number(mediaAbbo) - Number(minAbbo)) / 2;
                if (punteggioAbbo >= sogliaSupAbbo)  return coloreVerde;
                else if (punteggioAbbo <= sogliaInfAbbo) return coloreRosso;
                else return coloreGiallo;
            case '2':
                let sogliaSupEve = Number(mediaEve) + (Number(maxEve) - Number(mediaEve)) / 2;
                let sogliaInfEve = Number(minEve) + (Number(mediaEve) - Number(minEve)) / 2;
                if (punteggioEve >= sogliaSupEve)  return coloreVerde;
                else if (punteggioEve <= sogliaInfEve) return coloreRosso;
                else return coloreGiallo;
            case '3':
                let sogliaSupRispostePos = Number(mediaRispostePos) + (Number(maxRispostePos) - Number(mediaRispostePos)) / 2;
                let sogliaInfRispostePos= Number(minRispostePos) + (Number(mediaRispostePos) - Number(minRispostePos)) / 2;
                if (punteggioRispostePos >= sogliaSupRispostePos)  return coloreVerde;
                else if (punteggioRispostePos <= sogliaInfRispostePos) return coloreRosso;
                else return coloreGiallo;
            case '4':
                let sogliaSupRisposteNeu = Number(mediaRisposteNeu) + (Number(maxRisposteNeu) - Number(mediaRisposteNeu)) / 2;
                let sogliaInfRisposteNeu= Number(minRisposteNeu) + (Number(mediaRisposteNeu) - Number(minRisposteNeu)) / 2;
                if (punteggioRisposteNeu >= sogliaSupRisposteNeu)  return coloreVerde;
                else if (punteggioRisposteNeu <= sogliaInfRisposteNeu) return coloreRosso;
                else return coloreGiallo;
            case '5':
                //inverto la logica dei colori
                let sogliaSupRisposteNeg = Number(mediaRisposteNeg) + (Number(maxRisposteNeg) - Number(mediaRisposteNeg)) / 2;
                let sogliaInfRisposteNeg= Number(minRisposteNeg) + (Number(mediaRisposteNeg) - Number(minRisposteNeg)) / 2;
                if (punteggioRisposteNeg >= sogliaSupRisposteNeg)  return coloreRosso;
                else if (punteggioRisposteNeg <= sogliaInfRisposteNeg) return coloreVerde;
                else return coloreGiallo;
            default:
                return null; // Valore di default se insSugg non è tra 0-5
        }
    }

    function ricercaValCrm() {
        //ricerco i valori crm del contattato:

        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"datiCrmRecuperaDati",idCom:props.id,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    if(valoreDiRitorno.risultatoRitorno[0]){
                        setRispostePos(valoreDiRitorno.risultatoRitorno[0].PERC_RISP_POSITIVE);
                        setRisposteNeu(valoreDiRitorno.risultatoRitorno[0].PERC_RISP_NEUTRE);
                        setRisposteNeg(valoreDiRitorno.risultatoRitorno[0].PERC_RISP_NEGATIVE);
                        setPuntRisp(valoreDiRitorno.risultatoRitorno[0].PUNTEGGIO_RISPOSTE);
                        setPuntAbbo(valoreDiRitorno.risultatoRitorno[0].PUNTEGGIO_ABBONAMENTI);
                        setPuntEve(valoreDiRitorno.risultatoRitorno[0].PUNTEGGIO_PART_EVENTI);
                        if(props.ope==="modifica" || props.ope==="cancella"){
                            setDataAzioneDefault(valoreDiRitorno.risultatoRitorno[0].DATA_AZIONE);
                            setAzioneDefault(valoreDiRitorno.risultatoRitorno[0].AZIONE);
                        }
                    }
                    if(valoreDiRitorno.datiAltri[0]){    
                        //per gli iscritti:
                        setRispostePosMediaAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MEDIA_PERC_RISP_POSITIVE);
                        setRisposteNeuMediaAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MEDIA_PERC_RISP_NEUTRE);
                        setRisposteNegMediaAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MEDIA_PERC_RISP_NEGATIVE);
                        setPuntRispMediaAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MEDIA_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMediaAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MEDIA_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMediaAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MEDIA_PUNTEGGIO_PART_EVENTI);

                        setRispostePosMaxAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MAX_PERC_RISP_POSITIVE);
                        setRisposteNeuMaxAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MAX_PERC_RISP_NEUTRE);
                        setRisposteNegMaxAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MAX_PERC_RISP_NEGATIVE);
                        setPuntRispMaxAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MAX_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMaxAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MAX_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMaxAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MAX_PUNTEGGIO_PART_EVENTI);

                        setRispostePosMinAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MIN_PERC_RISP_POSITIVE);
                        setRisposteNeuMinAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MIN_PERC_RISP_NEUTRE);
                        setRisposteNegMinAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MIN_PERC_RISP_NEGATIVE);
                        setPuntRispMinAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MIN_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMinAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MIN_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMinAltriIscritti(valoreDiRitorno.datiAltri[0].ISCRITTI_MIN_PUNTEGGIO_PART_EVENTI);
                        //per i prospect:
                       
                        setRispostePosMediaAltriPro(valoreDiRitorno.datiAltri[0].PRO_MEDIA_PERC_RISP_POSITIVE);
                        setRisposteNeuMediaAltriPro(valoreDiRitorno.datiAltri[0].PRO_MEDIA_PERC_RISP_NEUTRE);
                        setRisposteNegMediaAltriPro(valoreDiRitorno.datiAltri[0].PRO_MEDIA_PERC_RISP_NEGATIVE);
                        setPuntRispMediaAltriPro(valoreDiRitorno.datiAltri[0].PRO_MEDIA_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMediaAltriPro(valoreDiRitorno.datiAltri[0].PRO_MEDIA_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMediaAltriPro(valoreDiRitorno.datiAltri[0].PRO_MEDIA_PUNTEGGIO_PART_EVENTI);

                        setRispostePosMaxAltriPro(valoreDiRitorno.datiAltri[0].PRO_MAX_PERC_RISP_POSITIVE);
                        setRisposteNeuMaxAltriPro(valoreDiRitorno.datiAltri[0].PRO_MAX_PERC_RISP_NEUTRE);
                        setRisposteNegMaxAltriPro(valoreDiRitorno.datiAltri[0].PRO_MAX_PERC_RISP_NEGATIVE);
                        setPuntRispMaxAltriPro(valoreDiRitorno.datiAltri[0].PRO_MAX_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMaxAltriPro(valoreDiRitorno.datiAltri[0].PRO_MAX_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMaxAltriPro(valoreDiRitorno.datiAltri[0].PRO_MAX_PUNTEGGIO_PART_EVENTI);

                        setRispostePosMinAltriPro(valoreDiRitorno.datiAltri[0].PRO_MIN_PERC_RISP_POSITIVE);
                        setRisposteNeuMinAltriPro(valoreDiRitorno.datiAltri[0].PRO_MIN_PERC_RISP_NEUTRE);
                        setRisposteNegMinAltriPro(valoreDiRitorno.datiAltri[0].PRO_MIN_PERC_RISP_NEGATIVE);
                        setPuntRispMinAltriPro(valoreDiRitorno.datiAltri[0].PRO_MIN_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMinAltriPro(valoreDiRitorno.datiAltri[0].PRO_MIN_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMinAltriPro(valoreDiRitorno.datiAltri[0].PRO_MIN_PUNTEGGIO_PART_EVENTI);

                        //per singola comunicazione (quindi sò già chi è il destinatario e vado a prendere la medie degli altri iscritti o prospect)
                        setRispostePosMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PERC_RISP_POSITIVE);
                        setRisposteNeuMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PERC_RISP_NEUTRE);
                        setRisposteNegMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PERC_RISP_NEGATIVE);
                        setPuntRispMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PUNTEGGIO_PART_EVENTI);

                    }     
                    setRicercaValoriCrm(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaIscritti() {
      
        //ricerco tra gli iscritti che mi servono per popolare lo spazio dei destinatari iscritti o ex-iscritti:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoDestinatariIscritti",elencaDisdetti:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiIscritti(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaIscritti(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaProspect() {

        //ricerco tra i prospect:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoDestinatariProspectCrm",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiProspect(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaProspect(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaMezziComunicazione() {
        //ricerco tra i mezzi di comunicazione con i quali si possono mandare le comunicazioni:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoMezziComunicazCrm",emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServerDatiMezziComunicazione(valoreDiRitorno.risultatoRitorno);
                    
                    const tempArrayMezziPossibili = {};
                    for (let i = 0; i < valoreDiRitorno.risultatoRitorno.length; i++) {
                        arrayIdMezziPossibili[i] = valoreDiRitorno.risultatoRitorno[i].ID;
                        tempArrayMezziPossibili[valoreDiRitorno.risultatoRitorno[i].ID] = valoreDiRitorno.risultatoRitorno[i].DESCRIZIONE;
                    }
                    setArrayMezziPossibili(tempArrayMezziPossibili);
                    const defaultValue = valoreDiRitorno.risultatoRitorno[0].ID;
                    setInsIdMezzoEdi(defaultValue);
                    setInsMezzoEdi(tempArrayMezziPossibili[defaultValue]);
                   
                    setStatoTendinaMezziComunicazione(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
      }

    /*function callbackSetValoreTendEditabileMezzoComunicazione(valoreNonVisibile){
      if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile !== -1){
          setInsIdMezzoEdi(valoreNonVisibile);
          //ora noto l'id  cerco la descrizione:
          for (let i=0; i<arrayIdMezziPossibili.length; i++) {
            if(arrayIdMezziPossibili[i] == valoreNonVisibile) setInsMezzoEdi(arrayMezziPossibili[i]);
            }
      }
    }*/

      const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        //console.log("Selected Value:", selectedValue); // Debug
        setInsMezzoEdi(arrayMezziPossibili[selectedValue]);
        setInsIdMezzoEdi(selectedValue);
        setVariazioneCampi(1);
    };

    function callbackSetValoreTendEditabileIscritti(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        //let dati = [...esitiIscritti];//popolo l'array locale con i dati dell'array di stato
        //dati.splice(index,1,valore);//);
        //setEsitiIscritti(dati);//aggiorno nuovamente la var di stato
        esitiIscritti[index]=valore;
    }

    function callbackSetValoreRisposteIscritti(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        risposteIscritti[index]=valore;
    }
    function callbackSetValoreRisposteProspect(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        risposteProspect[index]=valore;
    }

    function callbackSetValoreTendEditabileProspect(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        //let dati = [...esitiProspect];//popolo l'array locale con i dati dell'array di stato
        //dati.splice(index,1,valore);//);
        //setEsitiProspect(dati);//aggiorno nuovamente la var di stato
        if(valore == '') valore = 'Neutro';
        esitiProspect[index]=valore;
    }

    function tornaElenco() {
        setModaleInfoDestinatari(false);
        setModaleInfoParametri(false);
        setModaleNoMezzo(false);
        setModaleNoData(false);
        setModaleInfoPunteggioRisposte(false);
        setModaleInfoPunteggioAbbonamenti(false);
        setModaleInfoPunteggioEventi(false);
        setModaleInfoPercPos(false);
        setModaleInfoPercNeu(false);
        setModaleInfoPercNeg(false);
        setModaleInfoProssimaAzione(false);
        props.callbackTermineModifica();
    }
    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServerModCancSingola(bypassaControlli=0) {
        let controlli=0;


        if(controlli===0){ 
            //invio l'azione da fare sul server
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        //cognome:cognome.current.value,
                        data:dataInvio.current.value,
                        esito:esitoDefault,
                        idmezzo:insIdMezzoEdi,
                        testo:testoDefault,
                        risposta:rispostaDefault,
                        azione:azioneDefault,
                        dataAzione:dataAzioneDefault,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                    JSON.stringify({op:operazione,
                    idElemento:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }

    }

    function fetchOperazioneServer(bypassaControlli=0) {
        
        let controlli=0;

        //controllo che ci sia il mezzo di comunicazione:
        if(insIdMezzoEdi == ''){
            setModaleNoMezzo(true);
            controlli=1;
        }
        if(dataInvio.current.value == ''){
            setModaleNoData(true);
            controlli=1;
        }

        var elencoIscrittiID = [];
        var esitiIscrittiESITO = [];
        var risposteIscrittiRISPOSTA = [];
        //var elencoIscrittiCOGNOME = [];
        //var elencoIscrittiNOME = [];
        //var elencoIscrittiTESSERA = [];

        

        for (let ni=0; ni<iscrittiSelezionati.length; ni++) {
            if (iscrittiSelezionati[ni]){
                //console.log("Posizione"+ni)
                elencoIscrittiID.push(risultatoServerDatiIscritti[ni].ID);
                if(esitiIscritti[risultatoServerDatiIscritti[ni].ID] === undefined || esitiIscritti[risultatoServerDatiIscritti[ni].ID] ===''){
                    esitiIscritti[risultatoServerDatiIscritti[ni].ID]= 'Neutro';
                } 
                esitiIscrittiESITO.push(esitiIscritti[risultatoServerDatiIscritti[ni].ID]);
                risposteIscrittiRISPOSTA.push(risposteIscritti[risultatoServerDatiIscritti[ni].ID]);
                //console.log("ISCRITTO"+risultatoServerDatiIscritti[ni].ID);
                //console.log("ESITO"+esitiIscritti[risultatoServerDatiIscritti[ni].ID]);
                //console.log("RISPOSTA"+risposteIscritti[risultatoServerDatiIscritti[ni].ID]);
            }
             
            //if (iscrittiSelezionati[ni]) elencoIscrittiCOGNOME.push(risultatoServerDatiIscritti[ni].COGNOME);
            //if (iscrittiSelezionati[ni]) elencoIscrittiNOME.push(risultatoServerDatiIscritti[ni].NOME);
            //if (iscrittiSelezionati[ni]) elencoIscrittiTESSERA.push(risultatoServerDatiIscritti[ni].NUMERO_TESSERA);
        }

        var elencoProspectID = [];
        var esitiProspectESITO  = [];
        var risposteProspectRISPOSTA = [];
        //var elencoProspectCOGNOME = [];
        //var elencoProspectNOME = [];

    
        for (let np=0; np<prospectSelezionati.length; np++) {
            if (prospectSelezionati[np]){
                elencoProspectID.push(risultatoServerDatiProspect[np].ID);
                
                if(esitiProspect[risultatoServerDatiProspect[np].ID] === undefined || esitiProspect[risultatoServerDatiProspect[np].ID] ===''){
                    esitiProspect[risultatoServerDatiProspect[np].ID] = 'Neutro';
                } 
                esitiProspectESITO.push(esitiProspect[risultatoServerDatiProspect[np].ID]);
                risposteProspectRISPOSTA.push(risposteProspect[risultatoServerDatiProspect[np].ID]);
            } 
            //if (prospectSelezionati[np]) elencoProspectCOGNOME.push(risultatoServerDatiProspect[np].COGNOME);
            //if (prospectSelezionati[np]) elencoProspectNOME.push(risultatoServerDatiProspect[np].NOME);
        }


        if(controlli===0){ 

            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        //cognome:cognome.current.value,
                        idiscritti:elencoIscrittiID,
                        idprospect:elencoProspectID,
                        esitiIscritti:esitiIscrittiESITO,
                        esitiProspect:esitiProspectESITO,
                        risposteIscritti:risposteIscrittiRISPOSTA,
                        risposteProspect:risposteProspectRISPOSTA,
                        azione:azioneDefault,
                        dataAzione:dataAzioneDefault,
                        data:dataInvio.current.value,
                        idmezzo:insIdMezzoEdi,
                        testo:testoDefault,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                    JSON.stringify({op:operazione,
                    idElemento:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }


    //console.log(statoPagina);
    //console.log(JSON.stringify(risultatoServer))
        return (
            <Fragment>
               
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1scComCrm {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_SCHEDA_SINGOLA_COMUNICAZIONE ?
                    <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
        
                        {props.ope === "modifica" ? <CardTitle>Modifica Comunicazione</CardTitle> :""}

                        {modaleInfoDestinatari===true ?
                        <Modale 
                            titolo="Informativa dati di contatto destinatari"
                            flagErrore={false}
                            contenuto={<div>Nel caso in cui all'iscritto o al prospect venga assegnato un riferimento con flag referente acceso, i dati di contatto saranno quelli del riferimento</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);setModaleInfoDestinatari(false)}
                                }    
                            ]}
                        />
                        :""}

                        {modaleInfoParametri===true ?
                            <Modale 
                                titolo="Informativa Parametri"
                                flagErrore={false}
                                contenuto={<div>I valori riportati tra parentesi sono la media dei valori degli altri iscritti o degli altri contatti/prospect a seconda dell'appartenza del destinatario</div>}
                                bottoni={[
                                    {
                                        "etichetta":"OK",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(false);setModaleInfoParametri(false)}
                                    }    
                                ]}
                            />
                        :""}
       
        
                        <Clessidra loading={loading}/>
                        {props.ope === "cancella" ?
                            <FinestraDialogo 
                                titolo="Conferma eliminazione"
                                flagErrore={true}                                
                                contenuto={<div>
                                    Confermi l'eliminazione della comunicazione selezionata ?<br/>                                    
                                </div>}
                                bottoni={[
                                    {
                                        "etichetta":"Conferma",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(true);fetchOperazioneServerModCancSingola()}
                                    },
                                    {
                                        "etichetta":"Annulla",
                                        "tipo":"primary",
                                        callback: () => {tornaElenco()}
                                    }
                                ]}
                            />                        
                            
                        :
                        <>
                        <Row form> 
                        <Col md={12}>
                            <div className='schedaSottotitolo'>
                                Dati di Contatto<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoDestinatari(true)}/>
                            </div>
                            </Col>
                        </Row>
                        <Row form>  
                            <Col md={6}>
                            <Label for="descrizione">Destinatario:&nbsp;</Label> 
                            <b> {cognomeDefault} {nomeDefault} {tesseraDefault}</b>
                            </Col>
                            <Col md={3}>
                            {cellulareDefault != '' ? <> Cellulare: <b>{cellulareDefault}</b></>:""}  
                            </Col>
                            {emailDefault != '' ? <> Email: <b>{emailDefault}</b></>:""}
                            <Col md={3}>
                                    
                            </Col>
                        </Row>
                             

                        <Row form><Col md={12}><div className='schedaSottotitolo'>Parametri<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoParametri(true)}/></div></Col></Row>
                        <Row form>
                            <Col md={6}>Punteggio su Risposte : <b style={{ color: Number(puntRisp) >= Number(puntRispMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(puntRisp)}</b> ({FormattaImportoVideo(puntRispMediaAltri)})</Col>
                            <Col md={6}>Risposte esito Positivo : <b style={{ color: Number(percRispostePos) >= Number(percRispostePosMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(percRispostePos)}%</b> ({FormattaImportoVideo(percRispostePosMediaAltri)}%)</Col>
                        </Row>
                        <Row form>   
                            <Col md={6}>Risposte esito Negativo : <b style={{ color: Number(percRisposteNeg) > Number(percRisposteNegMediaAltri) ? 'red' : 'green' }}>{FormattaImportoVideo(percRisposteNeg)}%</b> ({FormattaImportoVideo(percRisposteNegMediaAltri)}%)</Col>
                            <Col md={6}>Risposte esito Neutro : <b>{FormattaImportoVideo(percRisposteNeu)}%</b> ({FormattaImportoVideo(percRisposteNeuMediaAltri)}%)</Col>
                            {/*<Col md={4}>Punteggio su Abbonamenti : <b style={{ color: Number(puntAbbo) >= Number(puntAbboMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(puntAbbo)}</b> ({FormattaImportoVideo(puntAbboMediaAltri)})</Col>
                            <Col md={4}>Punteggio su Eventi : <b style={{ color: Number(puntEve) >= Number(puntEveMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(puntEve)}</b> ({FormattaImportoVideo(puntEveMediaAltri)})</Col>
                        */}
                            </Row>

                        <Row form><Col md={12}><div className='schedaSottotitolo'>Prossima azione da intraprendere nei confronti del destinatario</div></Col></Row>
                        <Row form>
                            <Col md={2}> 
                            <FormGroup>
                                <Label for="testo">Data</Label>
                                            <Input maxLength={10} type="date" name="dataAzione" id="dataAzione"
                                                innerRef={dataAzione} 
                                                placeholder=""
                                                defaultValue={dataAzioneDefault}
                                                value={dataAzioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataAzioneDefault(dataAzione.current.value);}}/> 
                                </FormGroup>
                            </Col>
                            <Col md={10}>
                                <FormGroup>
                                    <Label for="testo">Azione</Label>
                                        <Input maxLength={200} type="textarea" name="azione" id="azione"  defaultValue={azioneDefault} innerRef={azione}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setAzioneDefault(azione.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row> 

                        <Row form> 
                        <Col md={12}>
                            <div className='schedaSottotitolo'>
                                Comunicazione
                            </div>
                            </Col>
                        </Row>
                        <Row form> 
                            <Col md={4}>
                                <FormGroup>
                                    Data*
                                        {props.ope === "modifica" ?
                                            <Input maxLength={10} type="date" name="dataInvio" id="dataInvio"
                                                innerRef={dataInvio} 
                                                placeholder=""
                                                defaultValue={dataInvioDefault}
                                                value={dataInvioDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataInvioDefault(dataInvio.current.value);}}/> 
                                            :""}     
                                </FormGroup>
                            </Col>

           
                            <Col md={4}> 
                                <FormGroup>
                                {props.ope === "modifica" ? (
                            <div>
                                Esito
                                <select
                                    className="form-control"
                                    onInput={(e) => {
                                        callbackSetVariazioneCampi(1);
                                        setEsitoDefault(e.target.value);
                                    }}
                                >
                                    <option value=''></option>
                                    {arrayEsitiPossibili.map((elementoPossibile, indexPossibile) => (
                                        <option
                                            key={indexPossibile}
                                            value={arrayEsitiPossibili[indexPossibile]}
                                            selected={esitoDefault !== undefined && arrayEsitiPossibili[indexPossibile] === esitoDefault}
                                        >
                                            {arrayEsitiPossibili[indexPossibile]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            esitoDefault
                        )}
                                </FormGroup>
                            </Col>
                            
                            <Col md={4}>    
                                <FormGroup>
                                    Mezzo di Comunicazione*
                                    {/*<TendinaEditabile
                                        //titolo={"Iscritto"}
                                        nomeCampo="DESCRIZIONE"
                                        nomeCampoNonVisibile="ID"
                                        bloccoInserimento = "1"
                                        valoreDefault={insMezzoEdi}
                                        arrayValori={risultatoServerDatiMezziComunicazione}
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileMezzoComunicazione}
                                    ></TendinaEditabile>*/}

                                <select
                                    className="form-control"
                                    style={{ width: '100%' }}
                                    value={insIdMezzoEdi} // Controlla il valore selezionato
                                    onChange={handleSelectChange} // Funzione corretta
                                >
                                    {arrayIdMezziPossibili.map((elementoPossibile, indexPossibile) => (
                                        <option key={indexPossibile} value={elementoPossibile}>
                                            {arrayMezziPossibili[elementoPossibile]}
                                        </option>
                                    ))}
                                </select>
                                </FormGroup>
                            </Col>   
                        </Row>
                        {/*testo*/}
                        <Row form> 
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="testo">Testo</Label>
                                        <Input maxLength={200} type="textarea" name="testo" id="testo"  defaultValue={testoDefault} innerRef={testo}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTestoDefault(testo.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form> 
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="risposta">Risposta</Label>
                                        <Input maxLength={200} type="textarea" name="risposta" id="risposta"  defaultValue={rispostaDefault} innerRef={risposta}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setRispostaDefault(risposta.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row>
                        </>
                        }
            
                    </div>

                            <div className="schedaSpazioInferiore">
                            {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServerModCancSingola()}}>Memorizza Modifiche</Button>:""}
                    
                            <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                            {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                            </Button>
                            </div>
                        
                </div>
                :""}




                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    
                    <div className="schedaSpazioCentrale">
                            {modaleInfoPercPos===true ?
                                <Modale 
                                    titolo="Informativa colori percentuali esiti Positivi"
                                    flagErrore={false}
                                    contenuto={<div>Il destinatario che ha dato un'elevata percentuale di esiti positivi verrà evidenziato in verde, 
                                        se gli esiti positivi vicini alla media, verrà evidenziato in giallo, se gli esiti positivi sono bassi verrà evidenziati in rosso.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoPercPos(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoPercNeu===true ?
                                <Modale 
                                    titolo="Informativa colori percentuali esiti Neutri"
                                    flagErrore={false}
                                    contenuto={<div>Il destinatario che ha dato un'elevata percentuale di esiti neutri verrà evidenziato in verde, 
                                        se gli esiti neutri vicini alla media, verrà evidenziato in giallo, se gli esiti neutri sono bassi verrà evidenziati in rosso.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoPercNeu(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoPercNeg===true ?
                                <Modale 
                                    titolo="Informativa colori percentuali esiti Negativi"
                                    flagErrore={false}
                                    contenuto={<div>Il destinatario che ha dato un'elevata percentuale di esiti negativi verrà evidenziato in rosso, 
                                        se gli esiti negativi vicini alla media, verrà evidenziato in giallo, se gli esiti negativi sono bassi verrà evidenziati in verde.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoPercNeg(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoProssimaAzione==true ?
                                <Modale 
                                    titolo="Informativa prossima azione"
                                    flagErrore={false}
                                    contenuto={<div>Se vengono indicati nuovi dati in questa sezione, gli eventuali dati precedenti di azioni da intraprendere sui destinatari selezionati, verranno sovrascritti.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoProssimaAzione(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoPunteggioRisposte===true ?
                                <Modale 
                                    titolo="Informativa punteggio risposte"
                                    flagErrore={false}
                                    contenuto={<div>Il destinatario ha un punteggio risposte dipendente dall'esito delle comunicazioni precedenti e dal mezzo di comunicazione utilizzato.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoPunteggioRisposte(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoPunteggioAbbonamenti===true ?
                                <Modale 
                                    titolo="Informativa punteggio abbonamenti"
                                    flagErrore={false}
                                    contenuto={<div>Il destinatario ha un punteggio abbonamenti dipendente dagli ingressi economici legati agli abbonamenti sottoscritti.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoPunteggioAbbonamenti(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoPunteggioEventi===true ?
                                <Modale 
                                    titolo="Informativa punteggio eventi"
                                    flagErrore={false}
                                    contenuto={<div>Il destinatario ha un punteggio abbonamenti dipendente dalla partecipazione agli eventi cui è stato convocato.</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleInfoPunteggioEventi(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleInfoDestinatari===true ?
                            <Modale 
                                titolo="Informativa dati di contatto destinatari"
                                flagErrore={false}
                                contenuto={<div>Nel caso in cui all'iscritto o al prospect venga assegnato un riferimento con flag referente acceso, i dati di contatto saranno quelli del riferimento</div>}
                                bottoni={[
                                    {
                                        "etichetta":"OK",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(false);setModaleInfoDestinatari(false)}
                                    }    
                                ]}
                            />
                            :""}
                            
       
                            {modaleNoMezzo===true ?
                                <Modale 
                                    titolo="Richiesto Mezzo di Comunicazione"
                                    flagErrore={true}
                                    contenuto={<div>Non è stato indicato il mezzo di comunicazione, che è necessario</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleNoMezzo(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleNoData===true ?
                                    <Modale 
                                        titolo="Richiesta Data"
                                        flagErrore={true}
                                        contenuto={<div>Non è stata indicata la data, che è necessaria</div>}
                                        bottoni={[
                                            {
                                                "etichetta":"OK",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(false);setModaleNoData(false)}
                                            }    
                                        ]}
                                    />
                                :""}

                            {props.ope === "inserisci" ? <CardTitle>Inserimento Comunicazione</CardTitle> :""}
                            {/*{props.ope === "modifica" ? <CardTitle>Modifica Comunicazione</CardTitle> :""}*/}
             
                          
                            <Clessidra loading={loading}/>  

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della comunicazione selezionata ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            : 
                            
                            <Fragment>
                            {/*data*/}
                        <Row form>  
                            <Col md={6}>
                                <FormGroup>
                                    Data*
                                        {/*{props.ope === "inserisci" || props.ope === "modifica" ?*/}
                                        <Input maxLength={10} type="date" name="dataInvio" id="dataInvio"
                                            innerRef={dataInvio} 
                                            placeholder=""
                                            defaultValue={dataInvioDefault}
                                            value={dataInvioDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setDataInvioDefault(dataInvio.current.value);}}/> 
                                        {/*}:""}     */}
                                </FormGroup>
                            </Col>
                        

                        {/*mezzo di comunicazione*/}
                        
                            <Col md={6}>    
                                <FormGroup>
                                Mezzo di Comunicazione*
                                {/*<TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="DESCRIZIONE"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={insMezzoEdi}
                                    arrayValori={risultatoServerDatiMezziComunicazione}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileMezzoComunicazione}
                                ></TendinaEditabile>*/}

                                <select
                                    className="form-control"
                                    style={{ width: '100%' }}
                                    value={insIdMezzoEdi} // Controlla il valore selezionato
                                    onChange={handleSelectChange} // Funzione corretta
                                >
                                    {arrayIdMezziPossibili.map((elementoPossibile, indexPossibile) => (
                                        <option key={indexPossibile} value={elementoPossibile}>
                                            {arrayMezziPossibili[elementoPossibile]}
                                        </option>
                                    ))}
                                </select>
                                </FormGroup>
                            </Col>   
                        </Row>
                            <Row form>  
                            <Col md={6}>
                                
                                {/*Punteggio risposte
                                Punteggio abbonamenti
                                Punteggio eventi
                                Percentuale risposte Positive
                                Percentuale risposte Neutre
                                Percentuale risposte Negative
                                 */}
                                 <b>Suggerisci destinatari tra gli Iscritti in base a : </b>
                                 <Row form>  
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg1" innerRef={sugg1} defaultValue={sugg1Default} 
                                            defaultChecked = {sugg1Default === "0" ?  'checked' :""} value="0"
                                            onInput={(e)=>{setSugg1(e.target.value);handleCambiamentoMod(e);}}/> {'Punteggio risposte'} <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPunteggioRisposte(true)}/>
                                    </Label></FormGroup></Col>
                                    {/*<Col md={4}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg1" innerRef={sugg1} defaultValue={sugg1Default} 
                                            defaultChecked = {sugg1Default === "1" ?  'checked' :""} value="1"
                                            onInput={(e)=>{setSugg1(e.target.value);handleCambiamentoMod(e);}}/> {'Punteggio abbonamenti'} <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPunteggioAbbonamenti(true)}/>
                                    </Label></FormGroup></Col>
                                    <Col md={4}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg1" innerRef={sugg1} defaultValue={sugg1Default} 
                                            defaultChecked = {sugg1Default === "2" ?  'checked' :""} value="2"
                                            onInput={(e)=>{setSugg1(e.target.value);handleCambiamentoMod(e);}}/> {'Punteggio eventi'} <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPunteggioEventi(true)}/>
                                    </Label></FormGroup></Col>*/}
                                
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg1" innerRef={sugg1} defaultValue={sugg1Default} 
                                            defaultChecked = {sugg1Default === "3" ?  'checked' :""} value="3"
                                            onInput={(e)=>{setSugg1(e.target.value);handleCambiamentoMod(e);}}/> {'Perc. risposte Positive'}<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPercPos(true)}/>
                                    </Label></FormGroup></Col>
                                    </Row>
                                    <Row form>  
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg1" innerRef={sugg1} defaultValue={sugg1Default} 
                                            defaultChecked = {sugg1Default === "4" ?  'checked' :""} value="4"
                                            onInput={(e)=>{setSugg1(e.target.value);handleCambiamentoMod(e);}}/> {'Perc. risposte Neutre'}<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPercNeu(true)}/>
                                    </Label></FormGroup></Col>
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg1" innerRef={sugg1} defaultValue={sugg1Default} 
                                            defaultChecked = {sugg1Default === "5" ?  'checked' :""} value="5"
                                            onInput={(e)=>{setSugg1(e.target.value);handleCambiamentoMod(e);}}/> {'Perc. risposte Negative'}<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPercNeg(true)}/>
                                    </Label></FormGroup></Col>
                                </Row>
                                <br/>

                                <b>Elenco destinatari tra gli Iscritti (attivi e disdetti)</b><img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoDestinatari(true)}/><br/>
                                Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaIscritti(e.target.value.toLowerCase())}/>
                                <div style={{height:350, backgroundColor:"#EEEEEE", overflow:"auto"}}>
                                {/*iscrittiFiltrati[i] 
                                    && 
                                        (setTestoRicercaIscritti==="" || x.toLowerCase().includes(testoRicercaIscritti)) 
                                    &&  */}
                                    {risultatoServerDatiIscritti.map((x,i) =>
                                    (testoRicercaIscritti==="" || (x.COGNOME.toLowerCase()+" "+x.NOME.toLowerCase()+" "+x.NUMERO_TESSERA.toLowerCase()).includes(testoRicercaIscritti)) 
                                    && 
                                    
                                        <div style={{display:"flex",flexDirection:"row",backgroundColor:iscrittiSelezionati[i]?coloreEvidenziato:"transparent"}}>
                                        <div 
                                            key={i}
                                          
                                            style={{
                                                marginLeft: 5, 
                                                flex: 2, 
                                                backgroundColor: getBackgroundColor(
                                                    insSugg1, 
                                                    x.PUNTEGGIO_RISPOSTE,x.PUNTEGGIO_ABBONAMENTI,x.PUNTEGGIO_PART_EVENTI,x.PERC_RISP_POSITIVE,x.PERC_RISP_NEUTRE,x.PERC_RISP_NEGATIVE,     
                                                    puntRispMaxAltriIscritti,puntRispMediaAltriIscritti,puntRispMinAltriIscritti, 
                                                    puntAbboMaxAltriIscritti,puntAbboMediaAltriIscritti,puntAbboMinAltriIscritti, 
                                                    puntEveMaxAltriIscritti,puntEveMediaAltriIscritti,puntEveMinAltriIscritti,
                                                    percRispostePosMaxAltriIscritti,percRispostePosMediaAltriIscritti,percRispostePosMinAltriIscritti,
                                                    percRisposteNeuMaxAltriIscritti,percRisposteNeuMediaAltriIscritti,percRisposteNeuMinAltriIscritti,
                                                    percRisposteNegMaxAltriIscritti,percRisposteNegMediaAltriIscritti,percRisposteNegMinAltriIscritti,
                                                    coloreVerde,coloreGiallo,coloreRosso,coloreEvidenziato, 
                                                    iscrittiSelezionati[i]
                                                ),
                                                color: iscrittiSelezionati[i] ? "#FFFFFF" : "#000000",
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                // Update the count of selected items
                                                if (iscrittiSelezionati[i]) {
                                                    setNumeroIscrittiSelezionati(numeroIscrittiSelezionati - 1);
                                                } else {
                                                    setNumeroIscrittiSelezionati(numeroIscrittiSelezionati + 1);
                                                }

                                                // Update the selection state
                                                var ps = [...iscrittiSelezionati]; // Correctly spreading iscrittiSelezionati array
                                                ps[i] = !ps[i];
                                                setIscrittiSelezionati(ps);
                                            }}
                                        >
                                            
                                            <>
                                            {insMezzoEdi === 'Email' && (
                                                <>
                                                {x.COGNOME} {x.NOME} {x.NUMERO_TESSERA} ({x.EMAIL}) 
                                                </>
                                            )}
                                            {insMezzoEdi === 'Telefono' && (
                                                <>
                                                {x.COGNOME} {x.NOME} {x.NUMERO_TESSERA} ({x.CELLULARE})
                                                </>
                                            )}
                                            {insMezzoEdi !== 'Email' && insMezzoEdi !== 'Telefono' && (
                                                <>
                                                {x.COGNOME} {x.NOME} {x.NUMERO_TESSERA} 
                                                </>
                                            )}
                                           
                                            {iscrittiSelezionati[i] && insSugg1 == 0 ? (<><br/>Punteggio {FormattaImportoVideo(x.PUNTEGGIO_RISPOSTE)} <br/>(Max = {FormattaImportoVideo(puntRispMaxAltriIscritti)}; Media = {FormattaImportoVideo(puntRispMediaAltriIscritti)}; Min = {FormattaImportoVideo(puntRispMinAltriIscritti)})</>) : null}
                                            {iscrittiSelezionati[i] && insSugg1 == 1 ? (<><br/>Punteggio {FormattaImportoVideo(x.PUNTEGGIO_ABBONAMENTI)} <br/>(Max = {FormattaImportoVideo(puntAbboMaxAltriIscritti)}; Media = {FormattaImportoVideo(puntAbboMediaAltriIscritti)}; Min = {FormattaImportoVideo(puntAbboMinAltriIscritti)})</>) : null}
                                            {iscrittiSelezionati[i] && insSugg1 == 2 ? (<><br/>Punteggio {FormattaImportoVideo(x.PUNTEGGIO_PART_EVENTI)} <br/>(Max = {FormattaImportoVideo(puntEveMaxAltriIscritti)}; Media = {FormattaImportoVideo(puntEveMediaAltriIscritti)}; Min = {FormattaImportoVideo(puntEveMinAltriIscritti)})</>) : null}
                                            {iscrittiSelezionati[i] && insSugg1 == 3 ? (<><br/>Percentuale {FormattaImportoVideo(x.PERC_RISP_POSITIVE)}% <br/>(Max = {FormattaImportoVideo(percRispostePosMaxAltriIscritti)}%; Media = {FormattaImportoVideo(percRispostePosMediaAltriIscritti)}%; Min = {FormattaImportoVideo(percRispostePosMinAltriIscritti)})%</>) : null}
                                            {iscrittiSelezionati[i] && insSugg1 == 4 ? (<><br/>Percentuale {FormattaImportoVideo(x.PERC_RISP_NEUTRE)}% <br/>(Max = {FormattaImportoVideo(percRisposteNeuMaxAltriIscritti)}%; Media = {FormattaImportoVideo(percRisposteNeuMediaAltriIscritti)}%; Min = {FormattaImportoVideo(percRisposteNeuMinAltriIscritti)})%</>) : null}
                                            {iscrittiSelezionati[i] && insSugg1 == 5 ? (<><br/>Percentuale {FormattaImportoVideo(x.PERC_RISP_NEGATIVE)}% <br/>(Max = {FormattaImportoVideo(percRisposteNegMaxAltriIscritti)}%; Media = {FormattaImportoVideo(percRisposteNegMediaAltriIscritti)}%; Min = {FormattaImportoVideo(percRisposteNegMinAltriIscritti)})%</>) : null}

                                            </>

                                        </div >   
                                            {iscrittiSelezionati[i]?
                                           
                                            <div style={{flex:3,display:"flex",flexDirection:"row"}}> 
                                            <div style={{flex:1,marginRight: '10px'}}>
                                            <span style={{ color: iscrittiSelezionati[i] ? "#FFFFFF" : "#000000" }}>Esito</span>
                                            <select                  
                                                    className="form-control"  
                                                    style={{ width: '100%' }}
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabileIscritti(e.target.value,x.ID);}}>
                                                    
                                                    {arrayEsitiPossibili.map((elementoPossibile,indexPossibile) =>

                                                    esitiIscritti[x.ID] !== undefined && arrayEsitiPossibili[indexPossibile] === esitiIscritti[x.ID] ? 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]} selected>{arrayEsitiPossibili[indexPossibile]}</option> 
                                                    : 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]}>{arrayEsitiPossibili[indexPossibile]}</option>
        
                                                )};
                                            </select>
                                            </div > 
                                            <div style={{flex:2,marginRight: '10px'}}>
                                            <span style={{ color: iscrittiSelezionati[i] ? "#FFFFFF" : "#000000" }}>Risposta</span>
                                            <textarea
                                                        key={i}
                                                        maxLength={200}
                                                        placeholder=""
                                                        style={textareaStyleRispostaIscritti(i)} // Applica lo stile condizionale
                                                        onChange={(e) => {
                                                            callbackSetValoreRisposteIscritti(e.target.value,x.ID)
                                                            handleCambiamentoMod(e);
                                                        }}
                                                        onMouseEnter={() => handleMouseEnterRispostaIscritti(i)}
                                                        onMouseLeave={handleMouseLeaveRispostaIscritti}
                                                    />
                                            </div>
                                            </div>
                                            :""}


                                        </div>                                
                                    )}   
                                    
                                </div> 
                                Numero Destinatari Selezionati tra gli Iscritti : {numeroIscrittiSelezionati}
                                                    
                            </Col> 

                            <Col md={6}>
                                 <b>Suggerisci destinatari tra i Contatti / Prospect in base a : </b>
                                 <Row form>  
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg2" innerRef={sugg2} defaultValue={sugg2Default} 
                                            defaultChecked = {sugg2Default === "0" ?  'checked' :""} value="0"
                                            onInput={(e)=>{setSugg2(e.target.value);handleCambiamentoMod(e);}}/> {'Punteggio risposte'} <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPunteggioRisposte(true)}/>
                                    </Label></FormGroup></Col>
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg2" innerRef={sugg2} defaultValue={sugg2Default} 
                                            defaultChecked = {sugg2Default === "3" ?  'checked' :""} value="3"
                                            onInput={(e)=>{setSugg2(e.target.value);handleCambiamentoMod(e);}}/> {'Perc. risposte Positive'}<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPercPos(true)}/>
                                    </Label></FormGroup></Col>
                                </Row>
                                <Row form> 
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg2" innerRef={sugg2} defaultValue={sugg2Default} 
                                            defaultChecked = {sugg2Default === "4" ?  'checked' :""} value="4"
                                            onInput={(e)=>{setSugg2(e.target.value);handleCambiamentoMod(e);}}/> {'Perc. risposte Neutre'}<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPercNeu(true)}/>
                                    </Label></FormGroup></Col>
                                    <Col md={6}><FormGroup check><Label check>
                                            <Input type="radio" name="sugg2" innerRef={sugg2} defaultValue={sugg2Default} 
                                            defaultChecked = {sugg2Default === "5" ?  'checked' :""} value="5"
                                            onInput={(e)=>{setSugg2(e.target.value);handleCambiamentoMod(e);}}/> {'Perc. risposte Negative'}<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoPercNeg(true)}/>
                                    </Label></FormGroup></Col>
                                </Row>
                                <br/>

                                <b>Elenco destinatari tra i Contatti / Prospect</b><img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoDestinatari(true)}/><br/>
                                Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaProspect(e.target.value.toLowerCase())}/>
                                <div style={{height:350, backgroundColor:"#EEEEEE", overflow:"auto"}}>


                                {/*prospectFiltrati[i]
                                    && 
                                        (setTestoRicercaProspect==="" || x.toLowerCase().includes(testoRicercaProspect)) 
                                    &&  */}
                                    {risultatoServerDatiProspect.map((x,i) =>

                                    (testoRicercaProspect==="" || (x.COGNOME.toLowerCase()+" "+x.NOME.toLowerCase()).includes(testoRicercaProspect)) 
                                    &&  

                                        <div style={{display:"flex",flexDirection:"row",backgroundColor:prospectSelezionati[i]?coloreEvidenziato:"transparent"}}>
                                        <div 
                                            key={i}
                                            style={{
                                                marginLeft: 5, 
                                                flex: 2, 
                                                backgroundColor: getBackgroundColor(
                                                    insSugg2, 
                                                    x.PUNTEGGIO_RISPOSTE,x.PUNTEGGIO_ABBONAMENTI,x.PUNTEGGIO_PART_EVENTI,x.PERC_RISP_POSITIVE,x.PERC_RISP_NEUTRE,x.PERC_RISP_NEGATIVE,     
                                                    puntRispMaxAltriPro,puntRispMediaAltriPro,puntRispMinAltriPro, 
                                                    puntAbboMaxAltriPro,puntAbboMediaAltriPro,puntAbboMinAltriPro, 
                                                    puntEveMaxAltriPro,puntEveMediaAltriPro,puntEveMinAltriPro,
                                                    percRispostePosMaxAltriPro,percRispostePosMediaAltriPro,percRispostePosMinAltriPro,
                                                    percRisposteNeuMaxAltriPro,percRisposteNeuMediaAltriPro,percRisposteNeuMinAltriPro,
                                                    percRisposteNegMaxAltriPro,percRisposteNegMediaAltriPro,percRisposteNegMinAltriPro,
                                                    coloreVerde,coloreGiallo,coloreRosso,coloreEvidenziato, 
                                                    prospectSelezionati[i]
                                                ),
                                                color: prospectSelezionati[i] ? "#FFFFFF" : "#000000",
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                // Update the count of selected items
                                                if (prospectSelezionati[i]) {
                                                    setNumeroProspectSelezionati(numeroProspectSelezionati - 1);
                                                } else {
                                                    setNumeroProspectSelezionati(numeroProspectSelezionati + 1);
                                                }

                                                // Update the selection state
                                                var ps = [...prospectSelezionati]; // Correctly spreading prospectSelezionati array
                                                ps[i] = !ps[i];
                                                setProspectSelezionati(ps);
                                            }}
                                        >
                                
                                            <>
                                            {insMezzoEdi === 'Email' && (
                                                <>
                                                {x.COGNOME} {x.NOME} ({x.EMAIL})
                                                </>
                                            )}
                                            {insMezzoEdi === 'Telefono' && (
                                                <>
                                                {x.COGNOME} {x.NOME} ({x.CELLULARE})
                                                </>
                                            )}
                                            {insMezzoEdi !== 'Email' && insMezzoEdi !== 'Telefono' && (
                                                <>
                                                {x.COGNOME} {x.NOME}
                                                </>
                                            )}
                    
                                            {prospectSelezionati[i] && insSugg2 == 0 ? (<><br/>Punteggio {FormattaImportoVideo(x.PUNTEGGIO_RISPOSTE)} <br/>(Max = {FormattaImportoVideo(puntRispMaxAltriPro)}; Media = {FormattaImportoVideo(puntRispMediaAltriPro)}; Min = {FormattaImportoVideo(puntRispMinAltriPro)})</>) : null}
                                            {prospectSelezionati[i] && insSugg2 == 1 ? (<><br/>Punteggio {FormattaImportoVideo(x.PUNTEGGIO_ABBONAMENTI)} <br/>(Max = {FormattaImportoVideo(puntAbboMaxAltriPro)}; Media = {FormattaImportoVideo(puntAbboMediaAltriPro)}; Min = {FormattaImportoVideo(puntAbboMinAltriPro)})</>) : null}
                                            {prospectSelezionati[i] && insSugg2 == 2 ? (<><br/>Punteggio {FormattaImportoVideo(x.PUNTEGGIO_PART_EVENTI)} <br/>(Max = {FormattaImportoVideo(puntEveMaxAltriPro)}; Media = {FormattaImportoVideo(puntEveMediaAltriPro)}; Min = {FormattaImportoVideo(puntEveMinAltriPro)})</>) : null}
                                            {prospectSelezionati[i] && insSugg2 == 3 ? (<><br/>Percentuale {FormattaImportoVideo(x.PERC_RISP_POSITIVE)}% <br/>(Max = {FormattaImportoVideo(percRispostePosMaxAltriPro)}%; Media = {FormattaImportoVideo(percRispostePosMediaAltriPro)}%; Min = {FormattaImportoVideo(percRispostePosMinAltriPro)})%</>) : null}
                                            {prospectSelezionati[i] && insSugg2 == 4 ? (<><br/>Percentuale {FormattaImportoVideo(x.PERC_RISP_NEUTRE)}% <br/>(Max = {FormattaImportoVideo(percRisposteNeuMaxAltriPro)}%; Media = {FormattaImportoVideo(percRisposteNeuMediaAltriPro)}%; Min = {FormattaImportoVideo(percRisposteNeuMinAltriPro)})%</>) : null}
                                            {prospectSelezionati[i] && insSugg2 == 5 ? (<><br/>Percentuale {FormattaImportoVideo(x.PERC_RISP_NEGATIVE)}% <br/>(Max = {FormattaImportoVideo(percRisposteNegMaxAltriPro)}%; Media = {FormattaImportoVideo(percRisposteNegMediaAltriPro)}%; Min = {FormattaImportoVideo(percRisposteNegMinAltriPro)})%</>) : null}
                                            
                                            </>
                                        </div> 
                                            
                                            {prospectSelezionati[i]?
                                            <div style={{flex:3,display:"flex",flexDirection:"row"}}> 
                                            <div style={{flex:1,marginRight: '10px'}}> 
                                            <span style={{ color: prospectSelezionati[i] ? "#FFFFFF" : "#000000" }}>Esito</span>
                                            <select                   
                                                    className="form-control"  
                                                    style={{ width: '100%' }}
                                                    onClick={(e) => e.stopPropagation()}  // Stop propagation on click
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabileProspect(e.target.value,x.ID);}}>
                                                   
                                                    {arrayEsitiPossibili.map((elementoPossibile,indexPossibile) =>

                                                    esitiProspect[x.ID] !== undefined && arrayEsitiPossibili[indexPossibile] === esitiProspect[x.ID] ? 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]} selected>{arrayEsitiPossibili[indexPossibile]}</option> : 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]}>{arrayEsitiPossibili[indexPossibile]}</option>
                                                                        
                                                )};
                                            </select>
                                            </div>
                                            <div style={{flex:2,marginRight: '10px'}}> 
                                            <span style={{ color: prospectSelezionati[i] ? "#FFFFFF" : "#000000" }}>Risposta</span>
                                            <textarea
                                                        key={i}
                                                        maxLength={200}
                                                        placeholder=""
                                                        style={textareaStyleRispostaProspect(i)} // Applica lo stile condizionale
                                                        onChange={(e) => {
                                                            callbackSetValoreRisposteProspect(e.target.value,x.ID)
                                                            handleCambiamentoMod(e);
                                                        }}
                                                        onMouseEnter={() => handleMouseEnterRispostaProspect(i)}
                                                        onMouseLeave={handleMouseLeaveRispostaProspect}
                                                    />
                                            </div>
                                            </div>
                                            :""}
                                           
                                        </div>                            
                                    )}   
                                </div>    
                                Numero Destinatari Selezionati tra i Contatti / Prospect : {numeroProspectSelezionati}                 
                            </Col>              
                        </Row>  
                        {/*testo*/}
                        <Row form> 
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="testo">Testo</Label>
                                        <Input maxLength={200} type="textarea" name="testo" id="testo"  defaultValue={testoDefault} innerRef={testo}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTestoDefault(testo.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form><Col md={12}><div className='schedaSottotitolo'>Prossima azione da intraprendere nei confronti dei destinatari<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoProssimaAzione(true)}/></div></Col></Row>
                        <Row form>
                            <Col md={2}> 
                            <FormGroup>
                                <Label for="testo">Data</Label>
                                        <Input maxLength={10} type="date" name="dataAzione" id="dataAzione"
                                                innerRef={dataAzione} 
                                                placeholder=""
                                                defaultValue={dataAzioneDefault}
                                                value={dataAzioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataAzioneDefault(dataAzione.current.value);}}/> 
                                             
                                </FormGroup>
                            </Col>
                            <Col md={10}>
                                <FormGroup>
                                    <Label for="testo">Azione</Label>
                                        <Input maxLength={200} type="textarea" name="azione" id="azione"  defaultValue={azioneDefault} innerRef={azione}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setAzioneDefault(azione.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row> 
                        </Fragment>
                    }  
                                
                                
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}

                       
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                <Fragment>Torna all'elenco</Fragment>
                                </Button>
                                </div>
                  
                        
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
