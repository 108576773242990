import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input,Modal } from 'reactstrap';

import Parametri from '../../parametri';
import arrayColoriGrafico from '../../coloriGrafici';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

//per grafici:
//import {Bar} from 'react-chartjs-2';
//import {Pie} from 'react-chartjs-2';

//possibili grafici(possible exports: Bar, Bubble, Chart, Doughnut, Line, Pie, PolarArea, Radar, Scatter, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent)

import {Bar} from 'react-chartjs-2';//istogramma a barre verticali
import {Doughnut} from 'react-chartjs-2';//un grafico a torta con stile ciambella
//import {HorizontalBar} from 'react-chartjs-2';//istogramma a barre orizzontali
import {Line} from 'react-chartjs-2';//grafico con linea su piano cartesiano
import {Pie} from 'react-chartjs-2';//la classica torta
import {PolarArea} from 'react-chartjs-2';//tipo torta ma con un aspetto grafico più ricercato e tecnologico
import {Radar} from 'react-chartjs-2';//tipo radar con ai vertici le voci; è possibile rappresentare più dataset
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

//per pdf:
//import html2canvas from "html2canvas";//(tentativo 1)
//import { jsPDF } from "jspdf";//(tentativo 1)

//import html2pdf from 'html2pdf.js'//npm install --save html2pdf.js (https://github.com/eKoopmans/html2pdf.js) (tentativo 2)

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import styles from '../stiliPdf.js'

import { calcolaAnnoOggi } from '../../funzioni.js';


//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;


const COLORE_TITOLI_REPORT="#a5a5a4";

function TabellaReportAttivita(props) {

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);

    const [ricercaCompletata,setRicercaCompletata]=useState(0);

    const vociGraficoCanvas = useRef();
    const vociNettiGraficoCanvas = useRef();
    const vociIvaGraficoCanvas = useRef();
    const attivitaGraficoCanvas = useRef();
    const attivitaNettiGraficoCanvas = useRef();
    const attivitaIvaGraficoCanvas = useRef();

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);
    const [topDefault,setTopDefault]=useState('10');
    const top = useRef();

    const coloriGraficoSfondoVoci=arrayColoriGrafico;
    const coloriGraficoSfondoAttivita=arrayColoriGrafico;

    const coloriGraficoBordoVoci='rgb(0,0,0)';
    const coloriGraficoBordoAttivita='rgb(0,0,0)';
    const larghezzaBordoGrafico=1;
    
    const larghColonnaDati=2;
    const larghColonnaVoci=8;//modificato da 4 a 8 in seguito all'eliminazione delle colonne iva e imponibili

    const flagVisualizzaImportiLordiDefault=1;
    const flagVisualizzaImportiNettiDefault=1;
    const flagVisualizzaImportiIvaDefault=1;
    //const flagVisualizzaGiorniDefault=1;

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");

    const operazione = "reportAttivitaPrincipali";
    const dataPartenza = useRef();
    
    const [risultatoServer,setRisultatoServer]=useState('');

    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
  
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    //per grafici:
    const [dataVoci,setDataVoci]=useState([]);
    const [dataVociNetti,setDataVociNetti]=useState([]);
    const [dataVociIva,setDataVociIva]=useState([]);
    const [dataAttivita,setDataAttivita]=useState([]);
    const [dataAttivitaNetti,setDataAttivitaNetti]=useState([]);
    const [dataAttivitaIva,setDataAttivitaIva]=useState([]);

    //DATI DA VISIONARE:
    const [arrayVoci,setArrayVoci]=useState([]);
    const [arrayAttivitaVoci,setArrayAttivitaVoci]=useState([]);
    const [arrayVociImporti,setArrayVociImporti]=useState([]);
    const [arrayVociImportiNetti,setArrayVociImportiNetti]=useState([]);
    const [arrayVociImportiIva,setArrayVociImportiIva]=useState([]);

    const [arrayAttivita,setArrayAttivita]=useState([]);
    const [arrayAttivitaImporti,setArrayAttivitaImporti]=useState([]);
    const [arrayAttivitaImportiNetti,setArrayAttivitaImportiNetti]=useState([]);
    const [arrayAttivitaImportiIva,setArrayAttivitaImportiIva]=useState([]);
    //Totali:
    const [statoTotaleVociAnno,setStatoTotaleVociAnno]=useState(0);
    const [statoTotaleVociNettiAnno,setStatoTotaleVociNettiAnno]=useState(0);
    const [statoTotaleVociIvaAnno,setStatoTotaleVociIvaAnno]=useState(0);

    const [statoTotaleAttivitaAnno,setStatoTotaleAttivitaAnno]=useState(0);
    const [statoTotaleAttivitaNettiAnno,setStatoTotaleAttivitaNettiAnno]=useState(0);
    const [statoTotaleAttivitaIvaAnno,setStatoTotaleAttivitaIvaAnno]=useState(0);

    //consensi visualizzazione grafici:
    const [visualizzaVoci,setVisualizzaVoci]=useState(1);
    const [visualizzaAttivita,setVisualizzaAttivita]=useState(1);

    const [graficoVoci,setGraficoVoci]=useState(0);
    const [graficoAttivita,setGraficoAttivita]=useState(0);

    //tipologie di grafici per ogni sezione:
    const [tipoGraficoVoci,setTipoGraficoVoci]=useState("Bar");
    const [tipoGraficoAttivita,setTipoGraficoAttivita]=useState("Bar");

    //consensi visualizzazione tabelle:
    const [visualizzaTabVoci,setVisualizzaTabVoci]=useState(1);
    const [visualizzaTabAttivita,setVisualizzaTabAttivita]=useState(1);

    const [arrayVociTopImporti,setArrayVociTopImporti]=useState([]);
    const [arrayVociTopImportiNetti,setArrayVociTopImportiNetti]=useState([]);
    const [arrayVociTopImportiIva,setArrayVociTopImportiIva]=useState([]);

    const [arrayVociTopImportiDescrizione,setArrayVociTopImportiDescrizione]=useState([]);
    const [arrayVociTopImportiNettiDescrizione,setArrayVociTopImportiNettiDescrizione]=useState([]);
    const [arrayVociTopImportiIvaDescrizione,setArrayVociTopImportiIvaDescrizione]=useState([]);

    const [arrayAttivitaTopImporti,setArrayAttivitaTopImporti]=useState([]);
    const [arrayAttivitaTopImportiNetti,setArrayAttivitaTopImportiNetti]=useState([]);
    const [arrayAttivitaTopImportiIva,setArrayAttivitaTopImportiIva]=useState([]);

    const [arrayAttivitaTopImportiDescrizione,setArrayAttivitaTopImportiDescrizione]=useState([]);
    const [arrayAttivitaTopImportiNettiDescrizione,setArrayAttivitaTopImportiNettiDescrizione]=useState([]);
    const [arrayAttivitaTopImportiIvaDescrizione,setArrayAttivitaTopImportiIvaDescrizione]=useState([]);

    const [visualizzaAltro,setVisualizzaAltro]=useState('0');
    const  visualizzaAltroRif = useRef();

    const [importoRicaviNetto,setImportoRicaviNetto]=useState(0);
    const [importoRicavi,setImportoRicavi]=useState(0);
    const [percRicaviNetto,setPercRicaviNetto]=useState(0);
    const [percRicavi,setPercRicavi]=useState(0);

    const [importoCostiNetto,setImportoCostiNetto]=useState(0);
    const [importoCosti,setImportoCosti]=useState(0);
    const [percCostiNetto,setPercCostiNetto]=useState(0);
    const [percCosti,setPercCosti]=useState(0);

    const [totaleAttivitaSecondariaNetto,setTotaleAttivitaSecondariaNetto]=useState(0);
    const [totaleAttivitaSecondaria,setTotaleAttivitaSecondaria]=useState(0);
    const [percPrincipaleNetto,setPercPrincipaleNetto]=useState(0);
    const [percPrincipale,setPercPrincipale]=useState(0);
    const [percSecondariaNetto,setPercSecondariaNetto]=useState(0);
    const [percSecondaria,setPercSecondaria]=useState(0);
    
    //const [visualizzaInPdf,setVisualizzaInPdf]=useState(false);

    //nomi dei tipi di grafici: (se cambi vai a modificare anche le select dei tipi che ora sono in ordine alfabetico secondo quete deciture)
    let descriDoughnut = "Ciambella";
    let descriLine = "Funzione";
    //let descriHorizontalBar = "Istogramma orizzontale";
    let descriBar = "Istogramma verticale";
    let descriPolarArea = "Polare";
    let descriRadar = "Radar";
    let descriPie =  "Torta";

    const  TGVoci = useRef();
    const  TGAttivita = useRef();

    //modale per attivar le impostazioni:
    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [cambiamento,setCambiamento] = useState(0);
    const [ricercaImpostazioniReport,setRicercaImpostazioniReport] = useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      if(ricercaCompletata === 1
        && dataVoci
        && dataAttivita
        && ricercaLogo === 1
        && ricercaImpostazioniReport === 1
        ){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
        setRicercaCompletata(0);
        //setRicercaLogo(0);
      }
    },[ricercaCompletata,ricercaLogo,ricercaImpostazioniReport])

    useEffect(() => {
      if(statoPagina === STATO_PREPARA_PDF){
          setStatoPagina(STATO_SCARICA_PDF);
      }
    },[statoPagina]);

    useEffect(() => { 
      //grafico Voci
      setDataVociNetti({labels: [''],
          datasets: [{label: 'Voci (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataVociIva({labels: [''],
          datasets: [{label: 'Voci (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
    hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataVoci({labels: [''],
          datasets: [{label: 'Voci (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      //grafico Attivita
      setDataAttivitaNetti({labels: [''],
        datasets: [{label: 'Attività (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
        hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataAttivitaIva({labels: [''],
        datasets: [{label: 'Attività  (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
      hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataAttivita({labels: [''],
        datasets: [{label: 'Attività  (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
        hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
    
      scaricaLogo();
      trovaImpostazioniReport();

      },[])

    useEffect(() => {
      //grafico Voci Importi Lordi
      if(arrayVociTopImporti.length>0){
      setDataVoci({labels: arrayVociTopImportiDescrizione,
      datasets: [
        {
          label: '',
          backgroundColor :  coloriGraficoSfondoVoci,
          borderColor: coloriGraficoBordoVoci,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayVociTopImporti,
      },
      ]});
    }
    },[arrayVociTopImporti]);

    useEffect(() => {
    //grafico Voci Importi Netti
    if(arrayVociTopImportiNetti.length>0){
      setDataVociNetti({labels: arrayVociTopImportiNettiDescrizione,
      datasets: [
        {
          label: '',
          backgroundColor :  coloriGraficoSfondoVoci,
          borderColor: coloriGraficoBordoVoci,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayVociTopImportiNetti,
      },
      ]});
    }
    },[arrayVociTopImportiNetti]);

    useEffect(() => {
      //grafico Voci Importi IVA
      if(arrayVociTopImportiIva.length>0){
        setDataVociIva({labels: arrayVociTopImportiIvaDescrizione,
        datasets: [
          {
            label: '',
            backgroundColor :  coloriGraficoSfondoVoci,
            borderColor: coloriGraficoBordoVoci,
            borderWidth: larghezzaBordoGrafico,
            hoverBackgroundColor: arrayColoriGrafico,
            hoverBorderColor: 'rgba(255,99,132,1)',
            borderCapStyle: 'round',
            data: arrayVociTopImportiIva,
        },
        ]});
      }
      },[arrayVociTopImportiIva]);

      useEffect(() => {
        //grafico Attivita Importi Lordi
        if(arrayAttivitaTopImporti.length>0){
        setDataAttivita({labels: arrayAttivitaTopImportiDescrizione,
        datasets: [
          {
            label: '',
            backgroundColor :  coloriGraficoSfondoAttivita,
            borderColor: coloriGraficoBordoAttivita,
            borderWidth: larghezzaBordoGrafico,
            hoverBackgroundColor: arrayColoriGrafico,
            hoverBorderColor: 'rgba(255,99,132,1)',
            borderCapStyle: 'round',
            data: arrayAttivitaTopImporti,
        },
        ]});
      }
      },[arrayAttivitaTopImporti]);
  
      useEffect(() => {
      //grafico Attivita Importi Netti
      if(arrayAttivitaTopImportiNetti.length>0){
        setDataAttivitaNetti({labels: arrayAttivitaTopImportiNettiDescrizione,
        datasets: [
          {
            label: '',
            backgroundColor :  coloriGraficoSfondoAttivita,
            borderColor: coloriGraficoBordoAttivita,
            borderWidth: larghezzaBordoGrafico,
            hoverBackgroundColor: arrayColoriGrafico,
            hoverBorderColor: 'rgba(255,99,132,1)',
            borderCapStyle: 'round',
            data: arrayAttivitaTopImportiNetti,
        },
        ]});
      }
      },[arrayAttivitaTopImportiNetti]);
  
      useEffect(() => {
        //grafico Attivita Importi IVA
        if(arrayAttivitaTopImportiIva.length>0){
          setDataAttivitaIva({labels: arrayAttivitaTopImportiIvaDescrizione,
          datasets: [
            {
              label: '',
              backgroundColor :  coloriGraficoSfondoAttivita,
              borderColor: coloriGraficoBordoAttivita,
              borderWidth: larghezzaBordoGrafico,
              hoverBackgroundColor: arrayColoriGrafico,
              hoverBorderColor: 'rgba(255,99,132,1)',
              borderCapStyle: 'round',
              data: arrayAttivitaTopImportiIva,
          },
          ]});
        }
        },[arrayAttivitaTopImportiIva]);

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI && ricercaImpostazioniReport===1){
        setGraficoVoci(0);
        setGraficoAttivita(0);

        // parametri per inviare i dati al server:
        //console.log("RICERCA");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            visualizzaAltro:visualizzaAltro,
            sogliaTop:topDefault,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                
                let arrLocale1=[];//Voci -> nome della voce
                let arrLocale1a=[];//Voci -> attivita della voce
                let arrLocale2=[];//Voci -> importi lordi
                let arrLocale3=[];//Voci -> importi netti
                let arrLocale4=[];//Voci -> importi iva
                let arrLocale5=[];//Attivita -> nome della attivita
                let arrLocale6=[];//Attivita -> importi lordi
                let arrLocale7=[];//Attivita -> importi netti
                let arrLocale8=[];//Attivita -> importi iva
                
                let totaleVociAnno = 0;
                let totaleVociNettiAnno = 0;
                let totaleVociIvaAnno = 0;

                let totaleAttivitaAnno = 0;
                let totaleAttivitaNettiAnno = 0;
                let totaleAttivitaIvaAnno = 0;
                
                let arrLocale1TopImporti=[];
                let arrLocale2TopImporti=[];
                let arrLocale3TopImporti=[];
                let arrLocale4TopImporti=[];
                let arrLocale5TopImporti=[];
                let arrLocale6TopImporti=[];

                let arrLocale1TopImportiDescrizione=[];
                let arrLocale2TopImportiDescrizione=[];
                let arrLocale3TopImportiDescrizione=[];
                let arrLocale4TopImportiDescrizione=[];
                let arrLocale5TopImportiDescrizione=[];
                let arrLocale6TopImportiDescrizione=[];

                for (let i=0; i<valoreDiRitorno.risultatoRitorno[0].dati.length ; i++) {
                  arrLocale1[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].VOCE;
                  setArrayVoci(arrLocale1);
                  arrLocale1a[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].ATTIVITA;
                  setArrayAttivitaVoci(arrLocale1a);
                  arrLocale2[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_VOCE;
                  setArrayVociImporti(arrLocale2);
                  arrLocale3[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_VOCE;
                  setArrayVociImportiNetti(arrLocale3);
                  arrLocale4[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_VOCE;
                  setArrayVociImportiIva(arrLocale4);
                  //totali:
                  totaleVociAnno = totaleVociAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_VOCE);
                  totaleVociNettiAnno = totaleVociNettiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_VOCE);
                  totaleVociIvaAnno = totaleVociIvaAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_VOCE);
                }

                for (let i=0; i<valoreDiRitorno.risultatoRitorno[1].dati_attivita.length ; i++) {
                  arrLocale5[i]=valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].ATTIVITA;
                  //console.log("w"+valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].ATTIVITA)
                  setArrayAttivita(arrLocale5);
                  arrLocale6[i]=valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].IMPORTO_ATTIVITA;
                  setArrayAttivitaImporti(arrLocale6);
                  arrLocale7[i]=valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].IMPORTO_NETTO_ATTIVITA;
                  setArrayAttivitaImportiNetti(arrLocale7);
                  arrLocale8[i]=valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].IMPORTO_IVA_ATTIVITA;
                  setArrayAttivitaImportiIva(arrLocale8);
                  totaleAttivitaAnno = totaleAttivitaAnno + parseFloat(valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].IMPORTO_ATTIVITA);
                  totaleAttivitaNettiAnno = totaleAttivitaNettiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].IMPORTO_NETTO_ATTIVITA);
                  totaleAttivitaIvaAnno = totaleAttivitaIvaAnno + parseFloat(valoreDiRitorno.risultatoRitorno[1].dati_attivita[i].IMPORTO_IVA_ATTIVITA);
                }

                for (let i1=0; i1<valoreDiRitorno.risultatoRitorno[2].topImportiVoci.length ; i1++) {
                  if (valoreDiRitorno.risultatoRitorno[2].topImportiVoci) {
                    arrLocale1TopImporti[i1]=valoreDiRitorno.risultatoRitorno[2].topImportiVoci[i1].IMPORTI;
                    setArrayVociTopImporti(arrLocale1TopImporti);
                  }
                  if (valoreDiRitorno.risultatoRitorno[3].topImportiVociNetti) {
                    arrLocale2TopImporti[i1]=valoreDiRitorno.risultatoRitorno[3].topImportiVociNetti[i1].IMPORTI;
                    setArrayVociTopImportiNetti(arrLocale2TopImporti);
                  }
                  if (valoreDiRitorno.risultatoRitorno[4].topImportiVociIva) {
                    arrLocale3TopImporti[i1]=valoreDiRitorno.risultatoRitorno[4].topImportiVociIva[i1].IMPORTI;
                    setArrayVociTopImportiIva(arrLocale3TopImporti);
                  }
                  if (valoreDiRitorno.risultatoRitorno[2].topImportiVoci) {
                    arrLocale1TopImportiDescrizione[i1]=valoreDiRitorno.risultatoRitorno[2].topImportiVoci[i1].VOCE;
                    setArrayVociTopImportiDescrizione(arrLocale1TopImportiDescrizione);
                  }
                  if (valoreDiRitorno.risultatoRitorno[3].topImportiVociNetti) {
                    arrLocale2TopImportiDescrizione[i1]=valoreDiRitorno.risultatoRitorno[3].topImportiVociNetti[i1].VOCE;
                    setArrayVociTopImportiNettiDescrizione(arrLocale2TopImportiDescrizione);
                  }
                  if (valoreDiRitorno.risultatoRitorno[4].topImportiVociIva) {
                    arrLocale3TopImportiDescrizione[i1]=valoreDiRitorno.risultatoRitorno[4].topImportiVociIva[i1].VOCE;
                    setArrayVociTopImportiIvaDescrizione(arrLocale3TopImportiDescrizione);
                  }
                }
            
                for (let i2=0; i2<valoreDiRitorno.risultatoRitorno[5].topImportiAttivita.length ; i2++) {
                  if (valoreDiRitorno.risultatoRitorno[5].topImportiAttivita) {
                    arrLocale4TopImporti[i2]=valoreDiRitorno.risultatoRitorno[5].topImportiAttivita[i2].IMPORTI;
                    setArrayAttivitaTopImporti(arrLocale4TopImporti);
                  }
                  if (valoreDiRitorno.risultatoRitorno[6].topImportiAttivitaNetti) {
                    arrLocale5TopImporti[i2]=valoreDiRitorno.risultatoRitorno[6].topImportiAttivitaNetti[i2].IMPORTI;
                    setArrayAttivitaTopImportiNetti(arrLocale5TopImporti);
                  }
                  if (valoreDiRitorno.risultatoRitorno[7].topImportiAttivitaIva) {
                    arrLocale6TopImporti[i2]=valoreDiRitorno.risultatoRitorno[7].topImportiAttivitaIva[i2].IMPORTI;
                    setArrayAttivitaTopImportiIva(arrLocale6TopImporti);
                  }
                  if (valoreDiRitorno.risultatoRitorno[5].topImportiAttivita) {
                    arrLocale4TopImportiDescrizione[i2]=valoreDiRitorno.risultatoRitorno[5].topImportiAttivita[i2].ATTIVITA;
                    setArrayAttivitaTopImportiDescrizione(arrLocale4TopImportiDescrizione);
                  }
                  if (valoreDiRitorno.risultatoRitorno[6].topImportiAttivitaNetti) {
                    arrLocale5TopImportiDescrizione[i2]=valoreDiRitorno.risultatoRitorno[6].topImportiAttivitaNetti[i2].ATTIVITA;
                    setArrayAttivitaTopImportiNettiDescrizione(arrLocale5TopImportiDescrizione);
                  }
                  if (valoreDiRitorno.risultatoRitorno[7].topImportiAttivitaIva) {
                    arrLocale6TopImportiDescrizione[i2]=valoreDiRitorno.risultatoRitorno[7].topImportiAttivitaIva[i2].ATTIVITA;
                    setArrayAttivitaTopImportiIvaDescrizione(arrLocale6TopImportiDescrizione);
                  }
                }
                
                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[8].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[8].periodo[0].DATA_FINE_PERIODO);
                
                setImportoRicaviNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].IMPORTO_RICAVI_NETTO);
                setImportoRicavi(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].IMPORTO_RICAVI);
                setPercRicaviNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_SU_RICAVI_NETTO);
                setPercRicavi(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_SU_RICAVI);

                setImportoCostiNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].IMPORTO_COSTI_NETTO);
                setImportoCosti(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].IMPORTO_COSTI);
                setPercCostiNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_SU_COSTI_NETTO);
                setPercCosti(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_SU_COSTI);

                setTotaleAttivitaSecondariaNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].TOTALE_ATTIVITA_SECONDARIA_NETTO);
                setTotaleAttivitaSecondaria(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].TOTALE_ATTIVITA_SECONDARIA);
                setPercPrincipaleNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_PRINCIPALE_NETTO);
                setPercPrincipale(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_PRINCIPALE);
                setPercSecondariaNetto(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_SECONDARIA_NETTO);
                setPercSecondaria(valoreDiRitorno.risultatoRitorno[9].riassuntivo[0].PERC_SECONDARIA);

                //ora setto le var di stato dei totali:
                setStatoTotaleVociAnno(totaleVociAnno);
                setStatoTotaleVociNettiAnno(totaleVociNettiAnno);
                setStatoTotaleVociIvaAnno(totaleVociIvaAnno);

                setStatoTotaleAttivitaAnno(totaleAttivitaAnno);
                setStatoTotaleAttivitaNettiAnno(totaleAttivitaNettiAnno);
                setStatoTotaleAttivitaIvaAnno(totaleAttivitaIvaAnno);

                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                
                setRicercaCompletata(1);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina,ricercaImpostazioniReport])
    //},[props.numPagina])

    useEffect(() => {
      if(necessitaRicalcolo===1){
        //resetto tutte le var di stato:
        setArrayVoci(['']);
        setArrayAttivitaVoci(['']);
        setArrayVociImporti(['']);
        setArrayVociImportiNetti(['']);
        setArrayVociImportiIva(['']);

        setArrayAttivita(['']);
        setArrayAttivitaImporti(['']);
        setArrayAttivitaImportiNetti(['']);
        setArrayAttivitaImportiIva(['']);

        setStatoTotaleVociAnno(0);
        setStatoTotaleVociNettiAnno(0);
        setStatoTotaleVociIvaAnno(0);

        setStatoTotaleAttivitaAnno(0);
        setStatoTotaleAttivitaNettiAnno(0);
        setStatoTotaleAttivitaIvaAnno(0);

        setStatoPagina(STATO_RICERCA_DATI);   
        setNecessitaRicalcolo(0);
      }
    },[necessitaRicalcolo])

    function trovaImpostazioniReport() {
      //vado a fare la fetch di ricerca delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          ricercaImpostazioni : 1,
          report : 6,
          emak: props.emak,
          sessionId: props.sessionId})
      };

      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              setVisualizzaTabVoci(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB_VOCI));
              setVisualizzaVoci(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_VOCI));
              setTipoGraficoVoci(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_VOCI);

              setVisualizzaTabAttivita(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB_ATTIVITA));
              setVisualizzaAttivita(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ATTIVITA));
              setTipoGraficoAttivita(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_ATTIVITA);

              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
              setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    ); 
    }

    function aggiornaImpostazioniReport() {
      setCambiamento(0);
      //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          modificaImpostazioni : 1,
          report : 6,
          visualizzaTabellaVoci:visualizzaTabVoci,
          visualizzaGraficiVoci:visualizzaVoci,
          tipoGraficiVoci:tipoGraficoVoci,
          visualizzaTabellaAttivita:visualizzaTabAttivita,
          visualizzaGraficiAttivita:visualizzaAttivita,
          tipoGraficiAttivita:tipoGraficoAttivita,
          periodoConsiderato:radioPeriodoDefault,
          elementiSignificativi:topDefault,
          visualizzaAltro:visualizzaAltro,
          emak: props.emak,
          sessionId: props.sessionId})
      };

      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1 && valoreDiRitorno.risultatoRitorno){
              setVisualizzaTabVoci(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB_VOCI);
              setVisualizzaVoci(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_VOCI);
              setTipoGraficoVoci(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_VOCI);
              setVisualizzaTabAttivita(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB_ATTIVITA);
              setVisualizzaAttivita(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ATTIVITA);
              setTipoGraficoAttivita(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_ATTIVITA);
              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
            
              //setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );


    }

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      if(cambiamento === 1) aggiornaImpostazioniReport();
      //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
    }
    function ResettaImpostazioni() {
      setTipoGraficoVoci("Bar");
      setTipoGraficoAttivita("Bar");
    }

    function funzioneTrasformaDateAUmano(data) {
      var dataOutput='';
      //da formato aaaa-mm-gg a gg/mm/aaaa
      if(data){
          const arrdata = data.split('-');
          let anno = arrdata[0];
          let mese = arrdata[1];
          let giorno = arrdata[2];
          dataOutput = giorno+"/"+mese+"/"+anno;
      }else dataOutput = '';
  
      return dataOutput;
   
  }
  function scaricaLogo() {
    //console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

  return (
      <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>

      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          {/*<PDFViewer style={styles.viewer}>*/}
          <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={{width:"100%"}} >
                  <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                    <View wrap={false} style={styles.tableRowIntestazione}> 
                      <View style={styles.tableColIntestazioneSx}> 
                        <View style={styles.tableCell}> 
                          {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}
                          
                        </View> 
                      </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                          <Text style={styles.titolo}>
                            Report Distribuzione Attività Principali / Secondarie {dataPartenzaDefault} 
                          </Text>
                          
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>
                      Periodo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
                    </Text>
                  </View>
                    
                  {visualizzaTabVoci === 1 ?
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol4}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Voce di Bilancio</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol4}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Imponibile</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={styles.tableCol4}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>IVA</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol4}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        </View>
                      :<Text></Text>}
                    </View>
                    
                    {arrayVoci.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={styles.tableCol4}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayVoci[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayVociImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayVociImportiIva[index])}</Text>
                         </View>
                         :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayVociImporti[index])}</Text>
                         </View>
                         :<Text></Text>}              
                    </View>)}
                   
                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleVociNettiAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleVociIvaAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleVociAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                           
                    </View> 
                  </View>
                  :""}

                  {flagVisualizzaImportiNettiDefault === 1 && vociNettiGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoVoci === "Line" || tipoGraficoVoci === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + vociNettiGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}

                  {flagVisualizzaImportiIvaDefault === 1 && vociIvaGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoVoci === "Line" || tipoGraficoVoci === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + vociIvaGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}

                  {flagVisualizzaImportiLordiDefault === 1 && vociGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoVoci === "Line" || tipoGraficoVoci === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + vociGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}


                  {visualizzaTabAttivita === 1 ?
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol4}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Attività</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol4}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Imponibile</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={styles.tableCol4}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>IVA</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol4}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        </View>
                      :<Text></Text>}
                    </View>
                    
                    {arrayAttivita.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={styles.tableCol4}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayAttivita[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayAttivitaImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayAttivitaImportiIva[index])}</Text>
                         </View>
                         :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayAttivitaImporti[index])}</Text>
                         </View>
                         :<Text></Text>}              
                    </View>)}
                   
                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleAttivitaNettiAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleAttivitaIvaAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol4}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleAttivitaAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                    </View> 
                  </View>
                  :""}

                  {flagVisualizzaImportiNettiDefault === 1 && attivitaNettiGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoAttivita === "Line" || tipoGraficoAttivita === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + attivitaNettiGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}

                  {flagVisualizzaImportiIvaDefault === 1 && attivitaIvaGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoAttivita === "Line" || tipoGraficoAttivita === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + attivitaIvaGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}

                  {flagVisualizzaImportiLordiDefault === 1 && attivitaGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoAttivita === "Line" || tipoGraficoAttivita === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + attivitaGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}


                  <View>
                    <View style={{ height: 20 }} /> {/* Spazio vuoto */}
                    <Text style={styles.titoloPiccolo}>
                      Percentuali relative tra Attività Principale e Attività Secondaria
                    </Text>
                  
              
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol3}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Attività</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Percentuale su Imponibile</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Percentuale su Importo</Text> 
                        </View>
                      :<Text></Text>}
                    </View>

                    <View wrap={false} style={styles.tableRow}> 
                      <View style={styles.tableCol3}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Principale</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percPrincipaleNetto)} %</Text>
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percPrincipale)} %</Text>
                        </View>
                      :<Text></Text>}
                    </View>

                    <View wrap={false} style={styles.tableRow}> 
                      <View style={styles.tableCol3}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Secondaria</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percSecondariaNetto)} %</Text>
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percSecondaria)} %</Text>
                        </View>
                      :<Text></Text>}
                    </View>
                  </View>
                  </View>
                  
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol1}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Percentuali relative tra Ricavi e Attività Secondaria</Text> 
                      </View> 
                    </View>

                  <View wrap={false} style={styles.tableRow}> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale Ricavi (imponibile)</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(importoRicaviNetto)}</Text>
                      </View>
                  </View>
                  
                 
                  <View wrap={false} style={styles.tableRow}> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale da Attività Secondaria (imponibile)</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(totaleAttivitaSecondariaNetto)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Percentuale Attività Secondaria / Ricavi(imponibile)</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percRicaviNetto)} %</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale Ricavi</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(importoRicavi)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale da Attività Secondaria</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(totaleAttivitaSecondaria)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Percentuale Attività Secondaria / Ricavi</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percRicavi)} %</Text>
                      </View>
                  </View>
                  </View>
                  

                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol1}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Percentuali relative tra Costi e Attività Secondaria</Text> 
                      </View> 
                    </View>
                  <View wrap={false} style={styles.tableRow}> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale Costi (imponibile)</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(importoCostiNetto)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale da Attività Secondaria (imponibile)</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(totaleAttivitaSecondariaNetto)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Percentuale Attività Secondaria / Costi (imponibile)</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percCostiNetto)} %</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale Costi</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(importoCosti)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Totale da Attività Secondaria</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(totaleAttivitaSecondaria)}</Text>
                      </View>
                  </View>
                  <View wrap={false} style={styles.tableRow}>
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoSinistra}>Percentuale Attività Secondaria / Costi</Text> 
                      </View> 
                      <View style={styles.tableCol2}> 
                        <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(percCosti)} %</Text>
                      </View>
                  </View>
                  </View>

                </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </Page>
          </Document>
        </PDFViewer>
      </div>

      :""}

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Rep_Att</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}
        
        {/*<div style={{minHeight: '100%'}}> */}
      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
           <div className="schedaSpazioCentrale" style={{minHeight: '100%',display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
            <Row>
              <Col lg="12">
                    <Card className="main-card mb-3" >
                    <CardBody>
                            <FormGroup>
                            <div id="reportIntestazione">
                            <CardTitle>
                            Report Distribuzione Attivita Principali / Secondarie  &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select>      
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>                  
                            </CardTitle> 
                              <Button color="success" className="bottoneMargineASinistra bottoneMargineADestra" onClick={() => {setModaleImpostazioni(true);}}>Impostazioni</Button>
                              {/*<Button color="success" className="mt-2 bottoneMargineASinistra" onClick={(e)=>{handleEsportaPDF(e,dataPartenzaDefault,inizioPeriodo,finePeriodo);}}>Esporta il PDF</Button>*/}
                              <Button color="success" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                            

                            <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>

                            {modaleImpostazioni === true ?
                            <Modale 
                            larghezza = "lg"
                            titolo="Impostazioni layout report distribuzione attivita principali / secondarie"
                            flagErrore={false}
                            contenuto={<div>
                              
                                <CardBody>
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>
                                                    
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Voci di Bilancio</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTab" 
                                      defaultChecked = {visualizzaTabVoci === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTabVoci === 1 ? setVisualizzaTabVoci(0) : setVisualizzaTabVoci(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaVoci" 
                                      defaultChecked = {visualizzaVoci === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaVoci === 1 ? setVisualizzaVoci(0) : setVisualizzaVoci(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGVoci" id="TGVoci" innerRef={TGVoci} onChange={(e)=>{setTipoGraficoVoci(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoVoci === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoVoci === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoVoci === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoVoci === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoVoci === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoVoci === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Attività</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTabAtt" 
                                      defaultChecked = {visualizzaTabAttivita === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTabAttivita === 1 ? setVisualizzaTabAttivita(0) : setVisualizzaTabAttivita(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaAttivita" 
                                      defaultChecked = {visualizzaAttivita === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAttivita=== 1 ? setVisualizzaAttivita(0) : setVisualizzaAttivita(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGAttivita" id="TGAttivita" innerRef={TGVoci} onChange={(e)=>{setTipoGraficoAttivita(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoAttivita === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoAttivita === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoAttivita === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoAttivita === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoAttivita === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoAttivita === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                </CardBody>
                                
                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </Col>
                                <Col>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                             
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {chiudiModaleImpostazioni()}
                                },    
                                {
                                  "etichetta":"Resetta Impostazioni Grafici",
                                  "tipo":"danger",
                                  callback:() => {ResettaImpostazioni();setCambiamento(1)}
                              },  
                            ]}
                            />
                            :""}

                            </div>
                            </FormGroup>

                            <div id="titolo" style={{display:"none"}}>
                            Report Distribuzione Attività Principali / Secondarie {dataPartenzaDefault} calcolato dal {funzioneTrasformaDateAUmano(inizioPeriodo)} al {funzioneTrasformaDateAUmano(finePeriodo)} 
                            </div>

                            

                            {risultatoServer !== undefined && risultatoServer !== "" ?
                            <div id="reportAttGlob">  
                                {/*<div id="reportatt"  style={{width:'100%'}}>
                                {visualizzaTabVoci === 1 || visualizzaVoci== 1 || visualizzaTabAttivita== 1 || visualizzaAttivita== 1 ?
                                 <Row><Col><CardTitle>Riepilogo</CardTitle></Col></Row>
                                :""}
                                </div>*/}
                                <div id="Tabella"  style={{width:'100%'}}>
                                <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      backgroundColor: 'lightgreen',
                                      marginRight: '0.5rem'
                                    }}
                                  />
                                  <span>Attività Principale</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      backgroundColor: 'lightblue',
                                      marginRight: '0.5rem'
                                    }}
                                  />
                                  <span>Attività Secondaria</span>
                                </div>
                                </div>


                                  {((arrayVociImporti.length>0))
                                  ?
                                    visualizzaTabVoci === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col><b>Voce di Bilancio</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>Imponibili</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>IVA</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo</b></Col> :""}
                                    </Row>
                                    {arrayVoci.map((elemento,index) => <Row
                                        form
                                        className="border"
                                        key={index}
                                        style={{
                                          backgroundColor: arrayAttivitaVoci[index] === '1' ? 'lightgreen' : 'lightblue'
                                        }}
                                      >
                                      {arrayVoci[index]!=='' ? 
                                        <Col>{arrayVoci[index]}</Col>
                                      : <Col>Non specificato</Col>}
                                      
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayVociImportiNetti[index])}</Col> :""} 
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayVociImportiIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayVociImporti[index])}</Col> :""}
                                      </Row>)
                                    }
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleVociNettiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleVociIvaAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleVociAnno)}</b></Col> :""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}
                                  
                                  </div>
                           
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoVoci==="Bar" && arrayVociTopImportiNetti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataVociNetti} ref={vociNettiGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Imponibile Voci (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoVoci==="Doughnut" && arrayVociTopImportiNetti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataVociNetti} ref={vociNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Imponibile Voci (Top '+topDefault+')'}},}} /></div>: ""}                                
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoVoci==="Line" && arrayVociTopImportiNetti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataVociNetti} ref={vociNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Imponibile Voci (Top '+topDefault+')'}},}} /></div>: ""}                             
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoVoci==="Pie" && arrayVociTopImportiNetti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataVociNetti} ref={vociNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Imponibile Voci (Top '+topDefault+')'}},}} /></div>: ""}                                   
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoVoci==="PolarArea" && arrayVociTopImportiNetti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataVociNetti} ref={vociNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Imponibile Voci (Top '+topDefault+')'}},}} /></div>: ""}                               
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoVoci==="Radar" && arrayVociTopImportiNetti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataVociNetti} ref={vociNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Imponibile Voci (Top '+topDefault+')'}},}} /></div>: ""}

                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoVoci==="Bar" && arrayVociTopImportiIva.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataVociIva} ref={vociIvaGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Voci (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoVoci==="Doughnut" && arrayVociTopImportiIva.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataVociIva} ref={vociIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Voci (Top '+topDefault+')'}},}} /></div>: ""}                                
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoVoci==="Line" && arrayVociTopImportiIva.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataVociIva} ref={vociIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Voci (Top '+topDefault+')'}},}} /></div>: ""}                             
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoVoci==="Pie" && arrayVociTopImportiIva.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataVociIva} ref={vociIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Voci (Top '+topDefault+')'}},}} /></div>: ""}                                   
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoVoci==="PolarArea" && arrayVociTopImportiIva.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataVociIva} ref={vociIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Voci (Top '+topDefault+')'}},}} /></div>: ""}                               
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoVoci==="Radar" && arrayVociTopImportiIva.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataVociIva} ref={vociIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Voci (Top '+topDefault+')'}},}} /></div>: ""}

                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoVoci==="Bar" && arrayVociTopImporti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataVoci} ref={vociGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Voci (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoVoci==="Doughnut" && arrayVociTopImporti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataVoci} ref={vociGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Voci (Top '+topDefault+')'}},}} /></div>: ""}                                
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoVoci==="Line" && arrayVociTopImporti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataVoci} ref={vociGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Voci (Top '+topDefault+')'}},}} /></div>: ""}                             
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoVoci==="Pie" && arrayVociTopImporti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataVoci} ref={vociGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Voci (Top '+topDefault+')'}},}} /></div>: ""}                                   
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoVoci==="PolarArea" && arrayVociTopImporti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataVoci} ref={vociGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Voci (Top '+topDefault+')'}},}} /></div>: ""}                               
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoVoci==="Radar" && arrayVociTopImporti.length>0  && visualizzaVoci === 1?
                                    <div id="vociGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataVoci} ref={vociGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Voci (Top '+topDefault+')'}},}} /></div>: ""}
                                 
                                    <br></br>
                                    <div id="Tabella"  style={{width:'100%'}}>  
                                  {((arrayAttivitaImporti.length>0))
                                  ?
                                    visualizzaTabAttivita === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col><b>Attività</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>Imponibili</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>IVA</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo</b></Col> :""}
                                    </Row>
                                    {arrayAttivita.map((elemento,index) => 
                                      <Row
                                        form
                                        className="border"
                                        key={index}
                                        style={{
                                          backgroundColor: arrayAttivita[index] === 'Principale' ? 'lightgreen' : 'lightblue'
                                        }}
                                      >

                                      {arrayVoci[index]!=='' ? 
                                        <Col>{arrayAttivita[index]}</Col>
                                      : <Col>Non specificato</Col>}
                                      
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayAttivitaImportiNetti[index])}</Col> :""} 
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayAttivitaImportiIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayAttivitaImporti[index])}</Col> :""}
                                      </Row>)
                                    }
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleAttivitaNettiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleAttivitaIvaAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleAttivitaAnno)}</b></Col> :""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}
                                  </div>

                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoAttivita==="Bar" && arrayAttivitaTopImportiNetti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataAttivitaNetti} ref={attivitaNettiGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Imponibile Attivita (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoAttivita==="Doughnut" && arrayAttivitaTopImportiNetti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataAttivitaNetti} ref={attivitaNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Imponibile Attivita (Top '+topDefault+')'}},}} /></div>: ""}                                
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoAttivita==="Line" && arrayAttivitaTopImportiNetti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataAttivitaNetti} ref={attivitaNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Imponibile Attivita (Top '+topDefault+')'}},}} /></div>: ""}                             
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoAttivita==="Pie" && arrayAttivitaTopImportiNetti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataAttivitaNetti} ref={attivitaNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Imponibile Attivita (Top '+topDefault+')'}},}} /></div>: ""}                                   
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoAttivita==="PolarArea" && arrayAttivitaTopImportiNetti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataAttivitaNetti} ref={attivitaNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Imponibile Attivita (Top '+topDefault+')'}},}} /></div>: ""}                               
                                  {flagVisualizzaImportiNettiDefault === 1 && tipoGraficoAttivita==="Radar" && arrayAttivitaTopImportiNetti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataAttivitaNetti} ref={attivitaNettiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Imponibile Attivita (Top '+topDefault+')'}},}} /></div>: ""}

                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoAttivita==="Bar" && arrayAttivitaTopImportiIva.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataAttivitaIva} ref={attivitaIvaGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Attivita (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoAttivita==="Doughnut" && arrayAttivitaTopImportiIva.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataAttivitaIva} ref={attivitaIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Attivita (Top '+topDefault+')'}},}} /></div>: ""}                                
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoAttivita==="Line" && arrayAttivitaTopImportiIva.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataAttivitaIva} ref={attivitaIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Attivita (Top '+topDefault+')'}},}} /></div>: ""}                             
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoAttivita==="Pie" && arrayAttivitaTopImportiIva.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataAttivitaIva} ref={attivitaIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Attivita (Top '+topDefault+')'}},}} /></div>: ""}                                   
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoAttivita==="PolarArea" && arrayAttivitaTopImportiIva.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataAttivitaIva} ref={attivitaIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Attivita (Top '+topDefault+')'}},}} /></div>: ""}                               
                                  {flagVisualizzaImportiIvaDefault === 1 && tipoGraficoAttivita==="Radar" && arrayAttivitaTopImportiIva.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataAttivitaIva} ref={attivitaIvaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Attivita (Top '+topDefault+')'}},}} /></div>: ""}

                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoAttivita==="Bar" && arrayAttivitaTopImporti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataAttivita} ref={attivitaGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Attivita (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoAttivita==="Doughnut" && arrayAttivitaTopImporti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataAttivita} ref={attivitaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Attivita (Top '+topDefault+')'}},}} /></div>: ""}                                
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoAttivita==="Line" && arrayAttivitaTopImporti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataAttivita} ref={attivitaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Attivita (Top '+topDefault+')'}},}} /></div>: ""}                             
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoAttivita==="Pie" && arrayAttivitaTopImporti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataAttivita} ref={attivitaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Attivita (Top '+topDefault+')'}},}} /></div>: ""}                                   
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoAttivita==="PolarArea" && arrayAttivitaTopImporti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataAttivita} ref={attivitaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Attivita (Top '+topDefault+')'}},}} /></div>: ""}                               
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoAttivita==="Radar" && arrayAttivitaTopImporti.length>0  && visualizzaAttivita === 1?
                                    <div id="AttivitaGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataAttivita} ref={attivitaGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Attivita (Top '+topDefault+')'}},}} /></div>: ""}

                                    <div className='schedaSottotitolo'>Percentuali relative tra Attività Principale e Attività Secondaria</div>
                                    <Row form className="border">
                                    <Col><b>Attività</b></Col><Col><b>Percentuale su Imponibile</b></Col><Col><b>Percentuale su Importo</b></Col>
                                    </Row>
                                    <Row style={{backgroundColor: 'lightgreen'}}><Col>Principale</Col><Col>{FormattaImportoVideo(percPrincipaleNetto)} %</Col><Col>{FormattaImportoVideo(percPrincipale)} %</Col></Row>
                                    <Row style={{backgroundColor: 'lightblue'}}><Col>Secondaria</Col><Col>{FormattaImportoVideo(percSecondariaNetto)} %</Col><Col>{FormattaImportoVideo(percSecondaria)} %</Col></Row>

                                    <div className='schedaSottotitolo'>Percentuali relative tra Totale Ricavi e Attività Secondaria</div>
                                  
                                    <Row><Col><b>Totale Ricavi (imponibile)</b></Col><Col>{FormattaImportoVideo(importoRicaviNetto)}</Col></Row>
                                    <Row><Col><b>Totale da Attività Secondaria (imponibile)</b></Col><Col>{FormattaImportoVideo(totaleAttivitaSecondariaNetto)}</Col></Row>
                                    <Row><Col><b>Percentuale Attività Secondaria / Ricavi (imponibile)</b></Col><Col>{FormattaImportoVideo(percRicaviNetto)} %</Col></Row>
                                    <br></br>
                                    <Row><Col><b>Totale Ricavi</b></Col><Col>{FormattaImportoVideo(importoRicavi)}</Col></Row>
                                    <Row><Col><b>Totale da Attività Secondaria</b></Col><Col>{FormattaImportoVideo(totaleAttivitaSecondaria)}</Col></Row>
                                    <Row><Col><b>Percentuale Attività Secondaria / Ricavi</b></Col><Col>{FormattaImportoVideo(percRicavi)} %</Col></Row>
                                    
                                    <div className='schedaSottotitolo'>Percentuali relative tra Totale Costi e Attività Secondaria</div>
                                    
                                    <Row><Col><b>Totale Costi (imponibile)</b></Col><Col>{FormattaImportoVideo(importoCostiNetto)}</Col></Row>
                                    <Row><Col><b>Totale da Attività Secondaria (imponibile)</b></Col><Col>{FormattaImportoVideo(totaleAttivitaSecondariaNetto)}</Col></Row>
                                    <Row><Col><b>Percentuale Attività Secondaria / Costi (imponibile)</b></Col><Col>{FormattaImportoVideo(percCostiNetto)} %</Col></Row>
                                    <br></br>
                                    <Row><Col><b>Totale Costi</b></Col><Col>{FormattaImportoVideo(importoCosti)}</Col></Row>
                                    <Row><Col><b>Totale da Attività Secondaria</b></Col><Col>{FormattaImportoVideo(totaleAttivitaSecondaria)}</Col></Row>
                                    <Row><Col><b>Percentuale Attività Secondaria / Costi</b></Col><Col>{FormattaImportoVideo(percCosti)} %</Col></Row>
                                    
                                  </div>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
      :""}
    </div>
   );
}
export default TabellaReportAttivita;