import React, {useState,useEffect} from 'react';
import {
    Card, CardBody, CardTitle, Button, Modal, Row, Col
} from 'reactstrap';
import Modale from '../Modale.js';

function AppartenentiAggiungiRimuovi(props) {
    const [testoRicercaPersone, setTestoRicercaPersone] = useState("");
    const [mostraClessidra, setMostraClessidra] = useState(false);
    const [numeroPersoneSelezionate, setNumeroPersoneSelezionate] = useState(0);
    // array di boolean con tanti elementi quante sono tutte le persone: true per le persone selezionate da utente, per aggiungerle o rimuoverle
    const [personeSelezionate, setPersoneSelezionate] = useState([]);
    // array di boolean: true per le persone filtrate dai raggruppamenti (appartengono o non appartengono ai raggruppamenti selezionati)
    const [personeFiltrate, setPersoneFiltrate] = useState([]);

    useEffect(() => {
        let f=[];
        let s=[];
        for (let i=0; i<props.persone.length; i++) {
            f[i]=true;
            s[i]=false;
        }
        setPersoneFiltrate(f);
        setPersoneSelezionate(s);
    },[])
        
    // restituisce l'intersezione degli array a e b, che DEVONO essere array con elementi NUMERICI ORDINATI in ordine crescente
    function intersezioneArray(a, b) {
      var ai=0, bi=0, intersezione=[];
      while (ai<a.length && bi<b.length) {
         if (a[ai]<b[bi]) { ai++; }
         else if (a[ai]>b[bi]) { bi++; }
         else { intersezione.push(a[ai]); ai++; bi++ }
      }
      return intersezione;
    }

    var titoloModale="";
    var etichettaElementi="";
    var coloreEvidenziato="";
    switch(props.azioneAppartenenti) {
        case "aggiungiAppartenenti": titoloModale="Aggiungi iscritti"; etichettaElementi="Iscritti da aggiungere al raggruppamento "+props.descrizioneGruppo; coloreEvidenziato="#84b352"; break;
        case "rimuoviAppartenenti": titoloModale="Rimuovi iscritti"; etichettaElementi="Iscritti da eliminare dal raggruppamento "+props.descrizioneGruppo; coloreEvidenziato="#e26e24"; break;
    }

    return (
        <Modale 
            titolo={titoloModale}
            larghezza = "xl"
            flagErrore={false}
            contenuto={<div>
                <Row form>  
                    <Col md={12}>
                        <b>{etichettaElementi}</b><br/>
                        Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaPersone(e.target.value.toLowerCase())}/>
                        <div style={{height:300, backgroundColor:"#EEEEEE", overflow:"auto"}}>
                            {props.persone.map((x,i) =>
                                personeFiltrate[i] 
                            && 
                                (
                                    (props.azioneAppartenenti == "aggiungiAppartenenti" && props.persone[i].APPARTENENTE==0) 
                                    ||
                                    (props.azioneAppartenenti== "rimuoviAppartenenti" && props.persone[i].APPARTENENTE==1) 
                                )
                            && 
                                (testoRicercaPersone==="" || 
                                    x.COGNOME.toLowerCase().includes(testoRicercaPersone) || 
                                    x.NOME.toLowerCase().includes(testoRicercaPersone) ||
                                    x.NUMERO_TESSERA.toLowerCase().includes(testoRicercaPersone)
                                ) 
                            &&  
                                <div 
                                    key={i}
                                    style={{
                                        marginLeft:5, 
                                        backgroundColor:personeSelezionate[i]?coloreEvidenziato:"transparent", 
                                        color:personeSelezionate[i]?"#FFFFFF":"#000000",
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (personeSelezionate[i]) setNumeroPersoneSelezionate(numeroPersoneSelezionate-1); else setNumeroPersoneSelezionate(numeroPersoneSelezionate+1);                                         
                                        var ps=[...personeSelezionate];                                        
                                        ps[i]=!ps[i];
                                        setPersoneSelezionate(ps);
                                    }}
                                >
                                    {props.stringhePersone[i]}
                                </div>                                
                            )}   
                        </div>                     
                        
                    </Col>                    
                </Row>
                
                <Row form>  
                    <Col md={4}>

                    </Col>
                    <Col md={4}>
                        {numeroPersoneSelezionate>0 ? numeroPersoneSelezionate===1 ? "1 elemento selezionato" : numeroPersoneSelezionate+" elementi selezionati" : "Nessun elemento selezionato" }                        
                    </Col>
                </Row>

                {props.azioneAppartenenti == "aggiungiAppartenenti" && <>Premendo il bottone verde "Aggiungi i selezionati" gli iscritti selezionati saranno immediatamente aggiunti al raggruppamento</>}
                {props.azioneAppartenenti == "rimuoviAppartenenti" && <>Premendo il bottone arancione "Rimuovi i selezionati" gli iscritti agli atleti selezionati saranno immediatamente eliminati dal raggruppamento</>}

                
            </div>
            }
            bottoni={[
                {
                    
                    "etichetta":props.azioneAppartenenti=="aggiungiAppartenenti"?"Aggiungi i selezionati":"Rimuovi i selezionati",
                    "tipo":props.azioneAppartenenti=="aggiungiAppartenenti"?"success":"warning",
                    callback:() => {
                        var elencoID = [];
                        for (let n=0; n<personeSelezionate.length; n++) {
                            if (personeSelezionate[n]) elencoID.push(props.persone[n].ID);
                        }
                        props.callbackAppartenentiAggiungiRimuovi(elencoID, props.azioneAppartenenti)
                    }
                },
                {
                    "etichetta":"Seleziona Visibili", 
                    "tipo":"primary",
                    callback:() => {
                        var ps=[...personeSelezionate]; 
                        for (let i=0; i<props.stringhePersone.length; i++) if (personeFiltrate[i]) ps[i] = true; 
                        setPersoneSelezionate(ps);
                    }
                },
                {
                    "etichetta":"Desel. Visibili", 
                    "tipo":"primary",
                    callback:() => {
                        var ps=[...personeSelezionate]; 
                        for (let i=0; i<props.stringhePersone.length; i++) if (personeFiltrate[i]) ps[i] = false; 
                        setPersoneSelezionate(ps);
                    }
                },
                {
                    "etichetta":"Annulla",
                    "tipo":"primary",
                    callback:() => {props.callbackAppartenentiAggiungiRimuovi([],"")
                    }
                }
            ]}
        />
    )
}

export default AppartenentiAggiungiRimuovi;